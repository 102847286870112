import axios from "axios"
import Http from "@modules/Http"
import { setUser } from "./users"
import { getCourses } from "./userCourses"
import { LOG_IN_SUCCESS, LOGOUT } from "@constants"
import { getCart } from "./cart"

const API_URL = process.env.GATSBY_API_URL

const OneSignal =
  typeof window !== "undefined" && window.OneSignal ? window.OneSignal : []

export const login = () => ({
  type: LOG_IN_SUCCESS,
})

export function logInUser({ email, password }) {
  return async function(dispatch) {
    try {
      const { data: loginResponse } = await axios.post(`${API_URL}/login`, {
        email,
        password,
      })
      localStorage.setItem("jwt", loginResponse.data.access_token)
      const { data: authResponse } = await Http.get("/me")
      dispatch(currentUser())
      dispatch({
        type: LOG_IN_SUCCESS,
      })
      return authResponse
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
export function currentUser() {
  return async function(dispatch) {
    const { data: authResponse } = await Http.get("/me")

    // Init onesignal
    OneSignal.push(function() {
      OneSignal.init({
        appId: "80daf2e5-0ff0-44c8-9b2a-d7dd3630ad44",
      })
      // Subscribe user
      OneSignal.sendTags({
        id: authResponse.id,
        user_type_id: authResponse.user_type_id,
      })
      OneSignal.getUserId(function(userId) {
        console.log(
          "this user id is blank, and so they cannot subscribe",
          userId
        )
      })
    })

    dispatch(getCourses(authResponse.id))
    dispatch(setUser(authResponse))
    dispatch(getCart())
    return authResponse
  }
}
export function logOutUser() {
  return function(dispatch) {
    dispatch({
      type: LOGOUT,
    })
    localStorage.removeItem("jwt")
  }
}
