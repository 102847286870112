import React, { useState } from "react"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import { RECOMMENDED_COURSES } from "@queries"
import { useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import Loading from "@share/Loading"
import PayCard from "../../images/img-cards.png"
import CourseItem from "./CourseItem"
import sum from "lodash/sum"
import Paymentez from "@modules/Paymentez"
import Http from "@modules/Http"
import { BeatLoader } from "react-spinners"
import Transfer from "./Transfer"
import BuyFailure from "./BuyFailure"

const PaymentMethod = props => {
  const user = useSelector(state => state.user)
  const cart = useSelector(state => state.cart)
  const showCheckoutModal = useSelector(state => state.checkoutModal)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const subtotal = sum(
    cart.map(course => {
      const price = course.price
      return parseFloat(price)
    })
  )

  const discount = sum(
    cart.map(course => {
      return course.discount > 0 ? parseFloat(course.discount) : 0
    })
  )

  const tax = ((subtotal - discount) * 12) / 100
  const total = subtotal - discount + tax
  const [data, isFetching] = useAsyncGraphQL(
    RECOMMENDED_COURSES(user.id),
    user.id
  )

  const handleClick = async () => {
    try {
      // Create order
      setLoading(true)
      const { data: order } = await Http.post("/order", {
        type: "credit_card",
      }).finally(() => setLoading(false))
      // Open modal
      Paymentez.open({
        user_id: user.id.toString(),
        user_email: user.email,
        user_phone: order.phone || "",
        order_description: "Compra de cursos.",
        order_amount: order.total,
        order_vat: 0,
        order_reference: order.id.toString(),
      })
    } catch (error) {
      console.error(error)
    }
  }

  const setModal = boolean => {
    setShowModal(boolean)
  }

  if (isFetching) {
    return <Loading />
  }

  const discountPercent = (discount / subtotal) * 100

  return (
    <section className="h-screen">
      <div className="container px-4 xs:px-0 md:px-12 flex flex-col md:flex-row justify-between mx-auto pt-6 md:mt-6">
        <div className="flex flex-col md:w-3/5">
          <span className="text-2xl md:text-4xl font-semibold">
            ¡Ya casi estamos listos!
          </span>
          <div className="flex flex-row mt-4 bg-gray-400 border rounded-xl overflow-hidden border shadow-mini">
            <div className="flex flex-row items-center w-3/5 md:w-4/5 py-4 bg-white">
              <span className="md:text-2xl pl-2 md:pl-6 font-medium">
                Total a pagar:
              </span>
              <span className="text-primary text-xl md:text-4xl ml-2 font-bold">
                ${total.toFixed(2)}
              </span>
            </div>
            <div className="flex flex-row bg-gray-35 items-center w-2/5 justify-center text-xs md:text-xl font-medium px-3">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="mr-2 text-green-600"
              />
              <span className="text-chocolate">
                ¡{discountPercent.toFixed(2)}% Descontado!
              </span>
            </div>
          </div>
          <div className="mt-6">
            <span className="text-2xl md:text-4xl font-semibold mb-8">
              Elige tu método de pago
            </span>
            <div className="flex flex-row w-full justify-between mt-6">
              <div className="bg-yellow-10 p-4 rounded-lg border-dashed border-2 border-primary w-2/4 flex items-center justify-center mt-2">
                <img src={PayCard} alt="" className="w-64" />
              </div>
              <div
                className="bg-gray-35 rounded-lg w-2/4 ml-4 md:ml-8 flex items-center justify-center mt-2 text-center cursor-pointer"
                onClick={() => setModal(true)}
              >
                <span className="text-chocolate opacity-50 md:text-3xl font-bold">
                  Transferencia <br /> bancaria
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              {/* <input
              type="text"
              className="rounded-xl h-12 w-full px-4 border outline-none rounded-xl w-full shadow-mini"
              placeholder="Número de la tarjeta"
            />
            <div className="flex flex-row my-4 md:my-6">
              <div className="flex flex-row">
                <input
                  type="text"
                  placeholder="MM"
                  className="h-12 px-4 border outline-none rounded-l-xl w-full shadow-mini"
                />
                <input
                  type="text"
                  placeholder="YY"
                  className="h-12 px-4 border outline-none rounded-r-xl w-full shadow-mini"
                />
              </div>
              <input
                type="text"
                placeholder="codigo de seguridad"
                className="ml-4 h-12 px-4 border outline-none rounded-xl w-full shadow-mini"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Nombre asociado a la tarjeta"
                className="rounded-xl h-12 w-full px-4 border outline-none rounded-xl w-full shadow-mini"
              />
            </div> */}
              <div className="w-full flex flex-col justify-center my-10 items-center">
                <div className="w-64">
                  {loading ? (
                    <button className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none">
                      <BeatLoader color="white" />
                    </button>
                  ) : (
                    <button
                      onClick={handleClick}
                      className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase"
                    >
                      ¡Comprar ahora!
                    </button>
                  )}
                </div>
                {/* <div className="flex flex-row items-center mt-6">
                <input
                  type="checkbox"
                  name="check"
                  id="check"
                  className="mr-2"
                />
                <label htmlFor="">Guardar tarjeta</label>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:w-2/5 md:pl-6 md:border-l md:ml-6">
          <span className="text-xl font-semibold mb-4 md:mb-8">
            {cart.length} cursos en el carrito
          </span>
          <div>
            {!isEmpty(cart) &&
              cart.map(course => (
                <CourseItem key={course.id} course={course} />
              ))}
          </div>
        </div>
      </div>
      {showModal && (
        <Transfer
          setShowModal={setShowModal}
          cart={cart}
          total={total.toFixed(2)}
        />
      )}
      {showCheckoutModal && <BuyFailure />}
    </section>
  )
}
export default PaymentMethod
