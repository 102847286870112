import { SET_USER_COURSES } from "../constants"
import initialState from "./initialState"

function userCourses(state = initialState.userCourses, action) {
  switch (action.type) {
    case SET_USER_COURSES:
      return [...action.payload]
    default:
      return state
  }
}

export default userCourses
