import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Link } from "@reach/router"
const Header = ({ total, subtotal, discount }) => {
  const discountPercent = (discount / subtotal) * 100
  return (
    <div className="flex flex-col text-xl text-center mb-4">
      <span className="text-sm md:text-lg">
        Precio Original: <strike>${subtotal}</strike>
      </span>
      <div className="text-base md:text-xl">
        <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
        <span className="font-semibold text-chocolate">
          ¡{discountPercent.toFixed(2)}% Descuento! Ahorras: ${discount}
        </span>
      </div>
    </div>
  )
}
const PayCard = ({ total, subtotal, discount, tax }) => {
  return (
    <>
      {discount > 0 && (
        <div>
          <Header discount={discount} subtotal={subtotal} total={total} />
        </div>
      )}
      <div className="lg:mt-0 bg-white rounded-xl shadow-second max-w-md overflow-hidden flex flex-col">
        <div className="pt-4 md:py-6 px-6 md:px-10">
          <div className="flex flex-row justify-between mb-2 text-base md:text-lg font-normal">
            <span className="block text-chocolate">Subtotal</span>
            <span className="text-primary block">${subtotal}</span>
          </div>
          <div className="flex flex-row justify-between mb-2 text-base md:text-lg font-normal">
            <span className="block text-chocolate">Descuento</span>
            <span className="text-primary block">- ${discount}</span>
          </div>
          <div className="flex flex-row justify-between mb-2 text-base md:text-lg font-normal">
            <span className="block text-chocolate">IVA (12%)</span>
            <span className="text-primary block">${tax}</span>
          </div>
          <hr className="h-px border-0 w-full bg-gray-200 my-3 mx-auto lg:block mx-auto" />
          <div className="flex flex-row justify-between my-4">
            <span className="text-4xl font-bold block text-chocolate">
              Total
            </span>
            <span className="text-4xl font-bold text-primary block">
              ${total}
            </span>
          </div>
          <div>
            <Link to="/carrito-de-compras/checkout">
              <button className="btn btn-primary mb-4 md:mb-0">
                ¡Proceder a pagar!
              </button>
            </Link>
          </div>
        </div>
        <div className="bg-chocolate text-white text-center text-base md:text-xl py-4 flex-1">
          <span className="block leading-tight">
            ¡Aprovecha! La oferta termina en:
          </span>
          <span className="font-bold leading-tight">00 : 05 : 25 : 55</span>
        </div>
      </div>
    </>
  )
}
export default PayCard
