import { SET_COURSE } from "../constants"
import initialState from "./initialState"

function course(state = initialState.course, action) {
  switch (action.type) {
    case SET_COURSE:
      return { ...action.payload }
    default:
      return state
  }
}

export default course
