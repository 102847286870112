import React from "react"
import { useSelector } from "react-redux"

import Breadcrumbs from "../Breadcrumbs"
import Tabs from "./Tabs"
import CourseVideo from "./CourseVideo"

const CourseLayout = props => {
  const course = useSelector(state => state.course)

  return (
    <div>
      <Breadcrumbs course={course} color={"black"} />
      <CourseVideo />
      <Tabs course={course} />
      <div className="bg-white p-6 md:py-10 md:p-16 rounded-b-lg mb-12">
        {props.children}
      </div>
    </div>
  )
}

export default CourseLayout
