import React from "react"

const Steps = ({ current }) => (
  <div className="hidden md:flex mb-6 flex-col w-4/5 mx-auto">
    <div className="flex flex-row items-center justify-center w-250 mx-auto">
      <div
        className={
          current
            ? "bg-transparent border-primary border-2 rounded-full w-8 h-8 flex items-center justify-center mr-2"
            : "bg-transparent border-gray-900 border-2 rounded-full w-8 h-8 flex items-center justify-center ml-2"
        }
      >
        <div
          className={
            current
              ? "bg-primary flex items-center justify-center rounded-full w-6 h-6 text-white font-semibold"
              : "bg-gray-900 flex items-center justify-center rounded-full w-6 h-6 text-white font-semibold"
          }
        >
          <span>1</span>
        </div>
      </div>
      <div className="border-dashed border-gray-500 border w-3/4" />
      <div
        className={
          !current
            ? "bg-transparent border-primary border-2 rounded-full w-8 h-8 flex items-center justify-center mr-2"
            : "bg-transparent border-gray-500 border-2 rounded-full w-8 h-8 flex items-center justify-center ml-2"
        }
      >
        <div
          className={
            !current
              ? "bg-primary flex items-center justify-center rounded-full w-6 h-6 text-white font-semibold"
              : "bg-gray-900 flex items-center justify-center rounded-full w-6 h-6 text-white font-semibold"
          }
        >
          <span>2</span>
        </div>
      </div>
    </div>
    <div className="flex flex-row w-full justify-around text-lg mx-auto font-semibold mt-4 px-4">
      <span className="ml-8">Crea tu cuenta</span>
      <span className="text-gray-900">Escoge tus intereses</span>
    </div>
  </div>
)

export default Steps
