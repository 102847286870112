import { useState, useEffect } from "react"
import Validator from "validatorjs"
import isObject from "lodash/isObject"

export default function(data, rules, messages = {}) {
  if (!isObject(data)) {
    throw new Error("First argument of useValidateObject must be an object.")
  }

  const [isValid, setIsValid] = useState(false)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    let validation = new Validator(data, rules, messages)

    setIsValid(validation.passes())
    setErrors(validation.errors.errors)
  }, [data])

  return [isValid, errors]
}
