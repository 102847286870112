import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
const InterestCard = ({ interest, handleClick, selectedInterests }) => {
  // primero obtener los intereses
  const selected = selectedInterests.some(({ id }) => id === interest.id)
  return (
    <div
      className="m-4 relative shadow-second bg-white flex flex-col items-center justify-center rounded-semi py-4 px-8 md:px-10 border cursor-pointer"
      onClick={() => handleClick(interest)}
    >
      {selected && (
        <div className="mt-3 right-0 top-0 absolute mr-4">
          <FontAwesomeIcon icon={faCheckCircle} className="text-green-400" />
        </div>
      )}
      <img src={interest.image} alt="" className="w-16 h-16" />
      <span className="text-base md:text-xl font-semibold mt-2">
        {interest.name}
      </span>
    </div>
  )
}
export default InterestCard
