import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import Diploma from "../../images//icon-diploma-gradient.svg"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import { GET_REFERENCELINK } from "@queries"
import { useSelector } from "react-redux"
import Loading from "./../share/Loading"
import Clipboard from "react-clipboard.js"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share"
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"

const ReferenceLink = () => {
  const { id } = useSelector(state => state.user)
  const [data, isFetching] = useAsyncGraphQL(GET_REFERENCELINK(id), id)
  const [buttonText, setButtonText] = useState("COPIAR")
  function onSuccess() {
    setButtonText("COPIADO")
    console.info("successfully copied")
  }

  function getText() {
    return data.user.profile.referal_link
  }
  if (isFetching) {
    return <Loading />
  }
  return (
    <div className="w-full mx-auto">
      <span className="text-3xl md:text-4xl font-semibold block mb-4">
        Enlace de Referidos
      </span>
      <div className="bg-orange-200 rounded-xl w-full flex flex-col items-center justify-center p-8">
        <img src={Diploma} alt="" className="mb-4" />
        <p className="md:text-xl text-center mb-4">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium
          pretium tempor. Ut eget imperdiet neque.
        </p>
        <div className="flex flex-col lg:flex-row w-full items-center justify-center lg:mt-4">
          <input
            type="text"
            value={data.user.profile.referal_link}
            className="rounded-xl text-sm md:text-base h-10 px-4 border outline-none rounded-xl shadow-mini w-full md:max-w-sm mb-2 md:mb-0 md:mr-4"
          />
          <Clipboard
            option-text={getText}
            onSuccess={onSuccess}
            className="btn btn-primary flex-1 md:w-24 md:flex-none uppercase shadow-mini"
          >
            {buttonText}
          </Clipboard>
        </div>
      </div>
      <div className="flex flex-col mt-6 text-xl w-full">
        <span>¡Compártelo en tus redes sociales!</span>
        <div className="flex flex-row items-center justify-between mt-6 lg:w-2/5">
          <EmailShareButton url={data.user.profile.referal_link}>
            <div className="flex">
              <div className="bg-gray-800 w-14 h-12 rounded-lg flex items-center justify-center">
                <FontAwesomeIcon icon={faEnvelope} className="text-white" />
              </div>
            </div>
          </EmailShareButton>
          <FacebookShareButton url={data.user.profile.referal_link}>
            <div className="flex">
              <div className="bg-blue-800 w-14 h-12 rounded-lg flex items-center justify-center">
                <FontAwesomeIcon icon={faFacebookF} className="text-white" />
              </div>
            </div>
          </FacebookShareButton>
          <TwitterShareButton url={data.user.profile.referal_link}>
            <div className="flex">
              <div className="bg-blue-400 w-14 h-12 rounded-lg flex items-center justify-center">
                <FontAwesomeIcon icon={faTwitter} className="text-white" />
              </div>
            </div>
          </TwitterShareButton>
          <LinkedinShareButton url={data.user.profile.referal_link}>
            <div className="flex">
              <div className="bg-blue-500 w-14 h-12 rounded-lg flex items-center justify-center">
                <FontAwesomeIcon icon={faLinkedinIn} className="text-white" />
              </div>
            </div>
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  )
}

export default ReferenceLink
