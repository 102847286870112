import React from "react"
import { Router, Redirect, Location } from "@reach/router"
import { Provider } from "react-redux"
import store from "./../store"

import Layout from "../components/layout"
import Home from "../components/Home/Index"
import Register from "../components/Register/Register"
import Students from "../components/Home/Students"
import Forgot from "../components/Recovery/Forgot"
import Recovery from "../components/Recovery/Recovery"
import Search from "../components/share/Search"
import ShoppingCart from "../components/Checkout/ShoppingCart"
import Tutor from "../components/Home/Tutor"
import Guide from "../components/Home/Guide"
import Profile from "../components/Profile/Profile"
import Course from "../components/Course/CoursePending"
import CourseAbout from "../components/Course/CourseAbout"
import CourseContent from "../components/Course/CourseContent"
import CourseQuestions from "../components/Course/CourseQuestions"
import Questions from "../components/Course/Questions"
import QuestionOpen from "../components/Course/QuestionOpen"
import Politics from "../components/politics/Politics"
import Terms from "../components/StaticPages/Terms"
import PaymentMethod from "../components/Checkout/PaymentMethod"
import CourseStudents from "../components/Home/CourseStudent"
import Login from "../components/LoginView"
import Billing from "../components/Profile/Billing"

const AuthRoute = ({ component: Component, ...rest }) => {
  if (!!localStorage.jwt) {
    return <Component {...rest} />
  }
  // Remember intended route
  const { location } = rest
  const { pathname } = location
  sessionStorage.setItem("intendedUrl", pathname)
  return <Redirect to="/login" noThrow />
}

const OnlyGuestRoute = ({ component: Component, ...rest }) =>
  !!localStorage.jwt ? <Redirect to="/" noThrow /> : <Component {...rest} />

const IndexPage = () => {
  return (
    <Provider store={store}>
      <Location>
        {({ location }) => (
          <Layout location={location}>
            <Router primary={false}>
              <Home path="/" />
              <OnlyGuestRoute path="/login" component={Login} />
              <OnlyGuestRoute path="/registro" component={Register} />
              <Forgot path="/recuperar-clave" />
              <Recovery path="recuperar-clave/:token" />
              <AuthRoute component={ShoppingCart} path="/carrito-de-compras" />
              <Search path="/buscar" />
              <Politics path="/politicas" />
              <Terms path="/terminos-y-condiciones" />
              <AuthRoute component={Students} path="/estudiante" />
              <AuthRoute component={CourseStudents} path="/estudiante/course" />
              <AuthRoute component={Tutor} path="/tutores" />
              <AuthRoute component={Guide} path="/guia" />
              <AuthRoute component={Profile} path="/tutores/mi-perfil" />
              <AuthRoute component={Profile} path="/estudiante/mi-perfil" />
              <AuthRoute component={Billing} path="/mi-perfil/factura/:id" />
              <AuthRoute
                component={PaymentMethod}
                path="/carrito-de-compras/checkout"
              />

              <Course path="/cursos/:id">
                <CourseAbout path="/" />
                <CourseContent path="/contenido" />
                <CourseQuestions path="/preguntas">
                  <Questions path="/" />
                  <QuestionOpen path=":id" />
                </CourseQuestions>
              </Course>
            </Router>
          </Layout>
        )}
      </Location>
    </Provider>
  )
}

export default IndexPage
