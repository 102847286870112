import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import first from "lodash/first"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

const ErrorLabel = ({ className, errors = {}, name }) => {
  const error = get(errors, name)

  if (isEmpty(error)) {
    return <></>
  }

  return (
    <div className={className}>
      <div className="flex items-center text-red-600 text-sm">
        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
        <span>{first(error)}</span>
      </div>
    </div>
  )
}

export default ErrorLabel
