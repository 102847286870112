import React, { useState, useEffect } from "react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { GET_USER_PROFILE } from "@queries"
import Http from "@modules/Http"
import pick from "lodash/pick"
import has from "lodash/has"
import { updateUser } from "@actions/users"
import { BeatLoader } from "react-spinners"
import Loading from "./../share/Loading"
import ErrorLabel from "@share/ErrorLabel"
import ChangeImage from "./ChangeImage"
import Country from "./Country"
import { useToasts } from "react-toast-notifications"

const Edit = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const user = useSelector(state => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [isFetching, setIsFetching] = useState(true)
  const [errors, setErrors] = useState({})
  const [profileData, setProfileData] = useState({})
  const [userData, setUserData] = useState({})

  const options = [
    { value: "legal_id", label: "Cédula" },
    { value: "passport", label: "Pasaporte" },
  ]
  useEffect(() => {
    fetchUserProfile()
  }, [user])
  async function fetchUserProfile() {
    try {
      if (user) {
        const { data } = await Http.graphql(GET_USER_PROFILE(user.id))
        const { user: userObject } = data
        const { profile } = userObject
        setUserData(pick(userObject, ["names", "surnames"]))
        setProfileData(profile)
      }
      setIsFetching(false)
    } catch (error) {
      console.error(error)
    }
  }
  async function updateProfile() {
    try {
      setIsLoading(true)
      await Http.patch("/user", {
        ...userData,
        email: user.email,
      })
      await Http.patch("/profile", profileData)
      setErrors({})
      setIsLoading(false)
      dispatch(
        updateUser({
          ...userData,
          profile: {
            ...profileData,
          },
        })
      )
      addToast("Saved Successfully", {
        appearance: "success",
        autoDismiss: true,
      })
    } catch (errors) {
      setIsLoading(false)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }
  function handleUserInput(event) {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    })
  }
  function handleProfileInput(event) {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    })
  }
  function handleProfileInputDocument(event) {
    setProfileData({
      ...profileData,
      document_type: event.value,
    })
  }
  function handleProfileInputCountry(event) {
    setProfileData({
      ...profileData,
      country: event.value,
    })
  }

  if (isFetching) {
    return <Loading />
  }
  console.log("profileData.document_type>>>>", profileData.country)

  return (
    <div className="w-full mx-auto mb-6">
      <ChangeImage user={user} profile={user?.profile} />
      <div className="flex flex-col mt-10">
        <div className="grid grid-columns-2 grid-gap-4 mb-4 md:grid-gap-8">
          <div className="flex flex-col">
            <span className="text-sm lg:text-xl mb-2">Nombre</span>
            <input
              type="text"
              placeholder="Alexander"
              value={userData.names}
              onChange={handleUserInput}
              name="names"
              className="rounded-xl h-12 px-4 border outline-none rounded-xl w-full shadow-mini"
            />
            <ErrorLabel className="mt-2" errors={errors} name="names" />
          </div>
          <div className="flex flex-col ">
            <span className="text-sm lg:text-xl mb-2">Apellido/s</span>
            <input
              type="text"
              placeholder="Perez"
              value={userData.surnames}
              onChange={handleUserInput}
              name="surnames"
              className="rounded-xl h-12 px-4 border outline-none rounded-xl w-full shadow-mini"
            />
            <ErrorLabel className="mt-2" errors={errors} name="surnames" />
          </div>
        </div>
        <div className="grid grid-columns-2 grid-gap-4 mb-4 md:grid-gap-8">
          <div className="flex flex-col">
            <span className="text-sm lg:text-xl mb-2">País de residencia</span>
            {/* <input
              type="text"
              placeholder="Ecuador"
              value={profileData.country}
              onChange={handleProfileInput}
              name="country"
              className="rounded-xl h-12 px-4 border outline-none rounded-xl w-full shadow-mini"
            /> */}
            <Country
              profileData={profileData}
              handleProfileInputCountry={handleProfileInputCountry}
            />
            <ErrorLabel className="mt-2" errors={errors} name="country" />
          </div>
          <div className="flex flex-col ">
            <span className="text-sm lg:text-xl mb-2">Número telefónico</span>
            <input
              type="text"
              placeholder="+59366745897"
              value={profileData.phone}
              onChange={handleProfileInput}
              name="phone"
              className="rounded-xl h-12 px-4 border outline-none rounded-xl w-full shadow-mini"
            />
            <ErrorLabel className="mt-2" errors={errors} name="phone" />
          </div>
        </div>
        <span className="text-sm lg:text-xl mb-2">Documento de Identidad</span>
        <div className="grid grid-columns-2 grid-gap-4 mb-4 md:grid-gap-8 mb-4">
          <div className="flex flex-col">
            <Select
              name="document_type"
              options={options}
              className="doc-selector"
              value={
                profileData.document_type
                  ? profileData.document_type === "passport"
                    ? options[1]
                    : options[0]
                  : options[0]
              }
              onChange={handleProfileInputDocument}
            />
            <ErrorLabel className="mt-2" errors={errors} name="document_type" />
          </div>
          <div className="flex flex-col justify-end">
            <input
              type="text"
              value={profileData.document_id}
              placeholder="Número de identificación"
              onChange={handleProfileInput}
              name="document_id"
              className="rounded-xl h-12 px-4 border outline-none rounded-xl w-full shadow-mini"
            />
            <ErrorLabel className="mt-2" errors={errors} name="document_id" />
          </div>
        </div>
        <div className="flex flex-col mb-4">
          <span className="text-sm md:text-xl mb-2">Título profesional</span>
          <input
            type="text"
            placeholder="Ejemplo: Diseñadora de Experiencia de Usuario"
            value={profileData.degree}
            onChange={handleProfileInput}
            name="degree"
            className="rounded-xl h-12 w-full px-4 border outline-none rounded-xl w-full shadow-mini"
          />
          <ErrorLabel className="mt-2" errors={errors} name="degree" />
        </div>
        <div className="flex flex-col mb-4">
          <span className="text-sm md:text-xl mb-2">Descripción</span>
          <textarea
            type="text"
            placeholder="Cuéntanos un poco de ti. Esto le permitirá a otras personas conocerte."
            value={profileData.description}
            onChange={handleProfileInput}
            name="description"
            className="rounded-xl w-full h-24 p-4 border outline-none rounded-xl w-full shadow-mini"
          />
          <ErrorLabel className="mt-2" errors={errors} name="description" />
        </div>
        <div className="flex">
          {isLoading ? (
            <button className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase">
              <BeatLoader color="white" />
            </button>
          ) : (
            <button
              onClick={updateProfile}
              className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase"
            >
              Guardar cambios
            </button>
          )}
          {/* <button className="md:text-xl text-primary ml-8">Cancelar</button> */}
        </div>
      </div>
    </div>
  )
}
export default Edit
