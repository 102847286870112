import { SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE } from "@constants"

export function setErrorMessage(message) {
  return function(dispatch) {
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: message,
    })
  }
}

export function clearErrorMessage() {
  return function(dispatch) {
    dispatch({
      type: CLEAR_ERROR_MESSAGE,
    })
  }
}
