import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

const Breadcrumbs = ({ course, color }) => (
  <div
    className="flex flex-row items-center h-10 text-base md:text-lg"
    style={{ color: color }}
  >
    <Link to="/">
      <FontAwesomeIcon icon={faHome} className="mr-2 md:mr-4" />
    </Link>
    {course.interests.length > 0 && (
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faAngleDoubleRight}
          className="mr-2 md:mr-4 hidden md:block"
        />
        <span className="font-medium -mt-1 mr-2 md:mr-4 hidden md:block">
          Categorías
        </span>
        <FontAwesomeIcon icon={faAngleDoubleRight} className="mr-2 md:mr-4" />
        <span className="font-medium -mt-1 mr-2 md:mr-4">
          {course.interests[0].name}
        </span>
      </div>
    )}
    <FontAwesomeIcon icon={faAngleDoubleRight} className="mr-2 md:mr-4" />
    <span className="font-medium -mt-1 mr-2 md:mr-4 truncate">
      {course.name}
    </span>
  </div>
)

export default Breadcrumbs
