import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle, faTimes } from "@fortawesome/free-solid-svg-icons"
import { GET_HOME_STUDENT } from "@queries"
import { useSelector } from "react-redux"
import { BeatLoader } from "react-spinners"
import Http from "@modules/Http"
import chunk from "lodash/chunk"
import ChunkSlider from "./ChunkSlider"
import Slider from "./../Slider"
import Loading from "@share/Loading"
import ModalInterest from "../share/ModalInterest"
import Button from "../share/button"
import CourseCard from "./CourseCard"
import Cardprogress from "../cardProgress"
const Students = () => {
  const [showInterest, setShowInterest] = useState(false)
  const [isVisiblePush, setIsVisiblePush] = useState(true)
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false)
  const [userInterests, setUserInterests] = useState([])
  const { id } = useSelector(state => state.user)
  const [recomendCourse, setRecomendCourse] = useState([])
  const [importantCourse, setImportantCourse] = useState([])
  const [courses, setCourses] = useState([])
  const [user, setUser] = useState({})
  const [isFetching, setIsFetching] = useState(true)
  useEffect(() => {
    if (id) {
      fetchData()
    }
  }, [id])
  useEffect(() => {
    setUserInterests(user.interests)
  }, [user])
  async function reSendEmail() {
    setIsLoadingSendEmail(true)
    await Http.post("/email/resend")
    setIsLoadingSendEmail(true)
    setIsVisiblePush(false)
  }
  async function fetchData() {
    const { data = {} } = await Http.graphql(GET_HOME_STUDENT(id))
    const { courses, important, interestsCourses, user } = data
    setCourses(courses)
    setImportantCourse(important)
    setRecomendCourse(interestsCourses)
    setUser(user)
    setIsFetching(false)
  }
  const chunkRecomendCourse = chunk(recomendCourse, 3)
  const chunkImportantCourse = chunk(importantCourse, 3)
  if (isFetching) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading />
      </div>
    )
  }
  return (
    <div>
      {courses.length > 0 && (
        <div className="mx-auto py-6 container px-4 xs:px-0 md:px-6 mx-auto xl:px-12">
          {isVisiblePush && (
            <div className="w-full">
              <div className="rounded-xl bg-pale flex flex-row justify-between mx-auto p-6 text-xl">
                {isLoadingSendEmail ? (
                  <div className="flex justify-center items-center w-full">
                    <BeatLoader color="#ef7625" />
                  </div>
                ) : (
                  <div className="flex flex-row justify-between w-full">
                    <div className="text-base md:text-lg">
                      <span>
                        {user.full_name}, te hemos enviado un correo de
                        verificación a {user.email}. ¿No te ha llegado?{" "}
                      </span>{" "}
                      <button
                        onClick={reSendEmail}
                        className="text-primary cursor-pointer"
                      >
                        {" "}
                        Volver a enviar
                      </button>
                    </div>
                    <span className="text-gray-500 cursor-pointer">
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          <section className="mx-auto mt-6 md:mt-12">
            <div className="flex justify-between mx-auto items-center xl:px-8">
              <span className="text-xl md:text-2xl lg:text-4xl font-semibold md:px-0">
                {user.full_name}, estas son las clases que estas cursando
              </span>
              <div className="w-40 hidden md:block">
                <Button title="Ver historial" click="activeModal" />
              </div>
            </div>
            <div className="mx-auto md:px-10 lg:px-6 progress-slider">
              <Slider>
                {courses.map(course => (
                  <div key={course.id}>
                    <Cardprogress course={course} />
                  </div>
                ))}
              </Slider>
            </div>
          </section>
        </div>
      )}
      <section className="bg-yellow-10">
        <div className="mx-auto py-12 mx-auto">
          <div className="flex justify-between mx-auto items-center px-4 xs:px-0 container xl:px-12">
            <span className="text-xl md:text-2xl lg:text-4xl font-semibold md:px-0">
              Cursos recomendados para ti
            </span>
            <div className="hidden md:block w-28 md:w-32">
              <Button title="Ver todos" click="activeModal" />
            </div>
          </div>
          <div className="md:hidden px-6">
            <ChunkSlider chunkRecomendCourse={chunkRecomendCourse} />
          </div>
          <div className="hidden md:block container mx-auto lg:px-12">
            <Slider
              // slidesPerView={4}
              params={{
                slidesPerView: 4,
                breakpoints: {
                  1240: {
                    slidesPerView: 3,
                  },
                  900: {
                    slidesPerView: 2,
                  },
                  700: {
                    slidesPerView: 1,
                  },
                },
                pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
                },
              }}
            >
              {recomendCourse.map(recomend => (
                <div key={recomend.id}>
                  <CourseCard course={recomend} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="bg-white">
        <div className="mx-auto py-12">
          <div className="flex justify-between mx-auto items-center px-4 xs:px-0 container xl:px-12">
            <span className="text-xl md:text-2xl lg:text-4xl font-semibold md:px-0">
              Cursos destacados en Comuni
            </span>
            <div className="w-28 md:w-32 hidden md:block">
              <Button title="Ver todos" click="activeModal" />
            </div>
          </div>
          <div className="md:hidden px-6">
            <ChunkSlider chunkRecomendCourse={chunkImportantCourse} />
          </div>
          <div className="hidden md:block container mx-auto lg:px-12">
            <Slider
              params={{
                slidesPerView: 4,
                breakpoints: {
                  1240: {
                    slidesPerView: 3,
                  },
                  1000: {
                    slidesPerView: 2,
                  },
                  700: {
                    slidesPerView: 1,
                  },
                },
                pagination: {
                  el: ".swiper-pagination",
                  clickable: true,
                },
              }}
            >
              {importantCourse.map(courseImportant => (
                <div key={courseImportant.id}>
                  <CourseCard course={courseImportant} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
      <section className="bg-yellow-10">
        <div className="mx-auto py-12 container px-0 mx-auto xl:px-12">
          <div className="flex justify-between mx-auto items-center mb-8 w-full px-4 xs:px-0">
            <span className="text-xl md:text-2xl lg:text-4xl font-semibold md:px-0">
              Tus áreas de interés
            </span>
            <div className="w-28 md:w-32">
              <button
                className="text-white w-full bg-primary rounded-xl text-sm lg:text-xl font-semibold py-2 lg:px-4"
                onClick={() => setShowInterest(true)}
              >
                Editar
              </button>
            </div>
          </div>
          <div className="flex flex-row flex-wrap w-full justify-center md:justify-start overflow-x-auto w-full ">
            {userInterests.map(interest => {
              return (
                <div
                  className="shadow-second bg-white w-42 h-40 flex flex-col items-center justify-center rounded-semi p-4 mb-6 md:mb-0 md:mr-8"
                  key={interest.id}
                >
                  <img
                    src={interest.image}
                    alt=""
                    className="w-16 h-16 md:w-24 md:h-24"
                  />
                  <span className="text-lg lg:text-xl font-semibold mt-2">
                    {interest.name}
                  </span>
                </div>
              )
            })}
            <div
              className="shadow-second p-4 border-primary border-dashed text-center border-4 h-40 w-42 flex flex-col items-center justify-center rounded-semi lg:mt-0 cursor-pointer"
              onClick={() => setShowInterest(true)}
            >
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-primary mr-2 text-4xl"
              />
              <span className="text-xl font-semibold mt-2 leading-snug">
                Agregar otra categoría
              </span>
            </div>
          </div>
        </div>
      </section>
      {showInterest && (
        <ModalInterest
          setShowInterest={() => setShowInterest(false)}
          selectedInterests={userInterests}
          setUserInterests={setUserInterests}
        />
      )}
    </div>
  )
}
export default Students
