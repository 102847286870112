import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHeart,
  faTimes,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons"
import ErrorLabel from "@share/ErrorLabel"
const Transfer = ({ setShowModal, cart, total }) => {
  return (
    <div className="fixed pin w-screen h-screen flex items-center justify-center z-100 top-0">
      <div className="min-w-modal rounded z-100 h-full md:h-auto md:rounded-semi flex flex-row overflow-hidden">
        <div className="flex flex-col justify-center p-10 bg-white relative max-w-2xl">
          <div className="flex flex-col items-center">
            <span
              className="absolute top-0 right-0 font-semibold text-gray-500 inline-block text-right text-xl cursor-pointer mx-4 mt-1"
              onClick={() => setShowModal(false)}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="text-gray-400 text-base"
              />
            </span>
          </div>
          <div className="flex flex-col">
            <span className="text-3xl font-medium mb-4">
              Datos para realizar la transferencia
            </span>
            <span className="text-xl mb-2">
              Los siguientes cursos se activarán cuando se acredite el pago:
            </span>
            <div className="flex flex-col text-xl font-medium">
              {cart.map(item => {
                return (
                  <div className="flex flex-row items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-400 text-base mr-2"
                    />
                    <span>{item.name}</span>
                  </div>
                )
              })}

              <span className="my-2 font-normal">
                Monto a pagar: <span className="font-bold"> ${total}</span>{" "}
              </span>
            </div>
            <div className="bg-pink-10 -mx-10 px-10 py-6 flex flex-col">
              <span className="text-xl font-medium mb-2">
                Para realizar la transferencia necesitarás los siguientes datos:
              </span>
              <div className="text-lg font-normal flex flex-col">
                <span className="text-lg ">
                  Nombre del propietario de la cuenta:
                  <span className="font-bold"> Mariam Salloum</span>
                </span>
                <span>
                  Número de cuenta:
                  <span className="font-bold"> 501878 2000 7174 2938</span>
                </span>
                <span>
                  Banco: <span className="font-bold"> Banco Mercantil</span>
                </span>
                <span>
                  Ciudad: <span className="font-bold"> Bolívar</span>
                </span>
                <span>
                  Siftwcode: <span className="font-bold"> JA0029</span>
                </span>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="text-xl font-medium mb-2">
                Su número de comprobante es:
                <span className="font-bold"> 801029382</span>
              </span>
              <span className="text-lg">
                Por favor, incluya el número de comprobante en las instrucciones
                de su banco.
              </span>
            </div>
            <div className="flex flex-row w-72 items-center mt-6">
              <button className="btn btn-primary h-12">Imprimir</button>
              <span className="text-primary text-lg ml-6">Cancelar</span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute pin w-full h-full bg-chocolate opacity-25 hidden md:block"
        onClick={() => setShowModal(false)}
      />
    </div>
  )
}
export default Transfer
