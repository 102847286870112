import initialState from "./initialState"
import { LOG_IN_SUCCESS, LOGOUT } from "./../constants"

function session(state = initialState.session, action) {
  switch (action.type) {
    case LOG_IN_SUCCESS:
      return !!localStorage.jwt
    case LOGOUT:
      return false
    default:
      return state
  }
}

export default session
