import React, { useState, useEffect } from "react"
import { GET_COURSE_PER_USER } from "@queries"
import imgCourses from "../../images/icon-empty-miscursos.svg"
import { Link, navigate } from "@reach/router"
import useGraphQL from "@hooks/useGraphQL"
import { useSelector } from "react-redux"
import Loading from "./../share/Loading"
import CreateMarkup from "./../../helpers/CreateMarkup"

import Http from "@modules/Http"
const Courses = ({ isOpenMenuCurso }) => {
  const { id } = useSelector(state => state.user)
  const course = useSelector(state => state.course)
  const [minCourses, setMinCourses] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    fecthCourse()
  }, [id, course, isOpenMenuCurso])

  async function fecthCourse() {
    setIsFetching(true)
    try {
      const response = await Http.graphql(GET_COURSE_PER_USER(id)).finally(() =>
        setIsFetching(false)
      )
      setMinCourses(response?.data?.user?.courses.reverse() || [])

      console.log("minCourses>>>", minCourses)
    } catch (error) {
      console.error(error)
    }
  }
  if (isFetching) {
    return (
      <div className="max-h-64 w-64 flex justify-center items-center ">
        <Loading />
      </div>
    )
  }

  return (
    <div
      className={minCourses.length > 0 ? "bg-white w-auto" : "bg-white w-64"}
    >
      {minCourses.length > 0 ? (
        <div className="px-2 pt-3 pb-2" style={{ width: "400px" }}>
          <div className="overflow-y-auto max-h-64">
            {minCourses.map(course => {
              return (
                <div
                  className="flex flex-row mb-2 cursor-pointer"
                  key={course.id}
                  onClick={() => navigate(`/cursos/${course.id}`)}
                >
                  <img
                    src={course.image}
                    alt=""
                    className="w-16 h-20 rounded-l-xl object-cover"
                  />
                  <div className="flex flex-col text-chocolate justify-start w-full border rounded-r-xl items-start px-2 pt-2">
                    <span className="text-lg">{course.name}</span>
                    <span className="">
                      {course.teacher.names} {course.teacher.surnames}
                    </span>
                    {/* aqui */}
                    <div className="flex justify-between items-center w-full">
                      <div className="h-3 w-full rounded-full overflow-hidden bg-gray-35 mr-6 -mb-1">
                        <div
                          className="h-full bg-primary rounded-r-full"
                          style={{
                            width: `${Math.round(course.completed_percent)}%`,
                          }}
                        />
                      </div>
                      <span className="text-sm">
                        {Math.round(course.completed_percent)}%
                      </span>
                    </div>
                    {/* <div className="w-full h-2 bg-gray-200 rounded-lg my-1">
                    <div className="h-full w-2/5 bg-primary rounded-lg" />
                  </div> */}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="w-full flex justify-center items-center">
            <Link
              className="text-primary text-xl font-semibold cursor-pointer"
              to="/estudiante/course"
            >
              Ver todos
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-between h-full w-64 p-4 h-42">
          <span className="text-chocolate text-base">
            Aún no has adquirido un curso :(
          </span>
          <img src={imgCourses} alt="" className="my-4" />
          <Link to="/estudiante" className="btn btn-primary w-full">
            Explorar cursos
          </Link>
        </div>
      )}
    </div>
  )
}
export default Courses
