import React from "react"
const GigaButton = ({ title, click }) => (
  <a
    className="py-4 px-10 text-white bg-white text-primary rounded-xl text-xl lg:text-2xl font-bold inline-block"
    href="/registro"
  >
    {title}
  </a>
)
export default GigaButton
