import React from "react"

const Interest = props => {
  const interest = props.interest
  return (
    <div className="shadow-second w-full bg-white flex flex-col items-center md:h-50 h-40 md:h-64 justify-center rounded-semi">
      <img src={interest.image} alt="" className="h-16 lg:h-32" />
      <span className="md:text-xl lg:text-2xl mt-2 font-semibold uppercase">
        {interest.name}
      </span>
      <span className="md:text-xl lg:text-2xl font-medium leading-none">
        {interest.total_courses} cursos
      </span>
    </div>
  )
}
export default Interest
