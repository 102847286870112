import React from "react"
import CreateMarkup from "./../../helpers/CreateMarkup"

import Avatar from "./Avatar"

const Answer = ({ answer }) => (
  <div className="md:ml-10 mb-6">
    <div className="flex flex-row mb-2">
      <Avatar image={answer.user.profile.image} />
      <div>
        <p className="text-base mb-1">
          <span className="font-medium">{answer.user.full_name}</span>
          &nbsp;-&nbsp;{answer.human_created_at}
        </p>
        <span className="hidden md:flex text-xs md:text-base">
          {answer.user.profile.degree}
        </span>
      </div>
    </div>
    <p dangerouslySetInnerHTML={CreateMarkup(answer.text)}></p>
  </div>
)

export default Answer
