import React from "react"
import take from "lodash/take"
import Slider from "./../Slider"
import CourseCard from "./CourseCard"
const LatestCourses = props => {
  return (
    <section className="mt-8 md:mt-24 mb-8 md:mb-24">
      <div className="flex flex-col items-center px-6 md:px-0 xl:mb-6">
        <span className="text-2xl md:text-4.1xl font-bold text-center text-chocolate">
          Elige lo que te apasiona y comienza a aprender
        </span>
      </div>
      <div className="max-w-6xl mx-auto px-3 md:px-10 xl:px-0">
        {props.courses.length > 0 && (
          <Slider>
            {take(props.courses, 7).map(course => (
              <div key={course.id}>
                <CourseCard course={course} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  )
}
export default LatestCourses
