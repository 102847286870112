import React from "react"
import { useSelector } from "react-redux"
import ContentSection from "./ContentSection"

const CourseContent = props => {
  const course = useSelector(state => state.course)

  return (
    <div>
      {course.contents.map((content, index) => (
        <ContentSection key={index} content={content} />
      ))}
    </div>
  )
}

export default CourseContent
