import React from "react"
import includes from "lodash/includes"
import compact from "lodash/compact"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@reach/router"
import {
  faPhone,
  faEnvelope,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons"
import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons"
import youtube from "../../images/icon-youtube.svg"
const NOPATH = ["registro", "recuperar-clave", "login"]

const Footer = props => {
  const pathName = compact(props.location.pathname.split("/"))[0]

  const hasFooter = includes(NOPATH, pathName)
  if (hasFooter) {
    return <div />
  }

  return (
    <footer className="bg-white pb-2 pt-6 md:py-2">
      <div className="flex flex-col w-full mx-auto lg:py-3 justify-center text-lg px-4 xs:px-0 xl:px-12 container">
        <div className="flex lg:flex-row flex-col-reverse justify-between items-center">
          <div className="flex flex-row md:px-3 py-1 rounded-lg my-3 lg:my-0">
            <div className="flex flex-col xs:flex-row items-center text-base mr-4 md:mr-6">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-black mr-2 mb-2 xs:mb-0"
              />
              <span className="text-sm md:text-base">info@comuni.com</span>
            </div>
            <div className="flex flex-col xs:flex-row items-center text-base">
              <FontAwesomeIcon
                icon={faPhone}
                className="text-black mr-2 mb-2 xs:mb-0"
              />
              <span className="text-sm md:text-base">+593 96-953-5555</span>
            </div>
          </div>
          <div className="flex flex-row items-center w-40 justify-between mb-0 md:my-3 lg:my-0">
            <FontAwesomeIcon
              icon={faFacebookSquare}
              className="text-black text-xl md:text-3xl"
            />
            <FontAwesomeIcon
              icon={faInstagram}
              className="text-black text-xl md:text-3xl"
            />
            <FontAwesomeIcon
              icon={faTwitter}
              className="text-black text-xl md:text-3xl"
            />
            <img src={youtube} alt="" className="w-8 h-8" />
          </div>
        </div>
        <hr className="h-px border-0 w-full bg-gray-200 my-3 mx-auto lg:block mx-auto" />
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center text-base">
          <div className="mt-2 lg:mt-0 md:my-3 lg:mb-0 lg:pl-1 mx-2 text-center">
            <FontAwesomeIcon icon={faCopyright} className="text-black mr-2" />
            <span>2019 Comuni. Todos los derechos reservados</span>
          </div>
          <div className="md:flex items-center text-xs md:text-base">
            <Link to="/politicas" className="mr-2">
              Términos de Uso
            </Link>
            <span>| </span>
            <Link to="/politicas" className="mx-2">
              Políticas de Privicidad
            </Link>
            <span>| </span>
            <Link to="/politicas" className="mx-2">
              Políticas de Cookies
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
