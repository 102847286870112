import React from "react"
import { Link, navigate } from "@reach/router"

const tabClasses =
  "outline-none focus:outline-none hover:text-primary block md:text-lg"
const tabActiveClasses =
  "outline-none focus:outline-none hover:text-primary block text-primary md:text-lg"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: tabActiveClasses } : { className: tabClasses }
}
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: tabActiveClasses }
    : { className: tabClasses }
}
const Tabs = ({ course }) => {
  return (
    <div>
      <div className="bg-pink-10 h-20 flex justify-around md:grid md:grid-columns-3 text-center items-center px-2 md:px-6 text-base md:text-xl font-medium">
        <Link
          to={`/cursos/${course.id}`}
          state={{
            updateScroll: false,
          }}
          getProps={isActive}
          className={tabClasses}
        >
          Información
        </Link>
        <Link
          to={`/cursos/${course.id}/contenido`}
          state={{
            updateScroll: false,
          }}
          getProps={isActive}
          className={tabClasses}
        >
          Contenido
        </Link>
        <Link
          to={`/cursos/${course.id}/preguntas`}
          state={{
            updateScroll: false,
          }}
          getProps={isPartiallyActive}
          className={tabClasses}
        >
          <span className="hidden md:inline-block">Preguntas y respuestas</span>
          <span className="md:hidden">P & R</span>
        </Link>
      </div>
    </div>
  )
}
export default Tabs
