import React, { useState } from "react"
import Edit from "./Edit"
import Account from "./Account"
import Certifications from "./Certifications"
import History from "./History"
import Method from "./Method"
import TabLinks from "./TabLinks"
import InformationBank from "./InformationBank"
import Payments from "./Payments"
import ReferenceLink from "./ReferenceLink"
import { useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import Loading from "./../share/Loading"
import { ToastProvider } from "react-toast-notifications"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

const MyCustomToast = ({ appearance, children }) => (
  <div className="mt-28 -mr-4">
    <div className="bg-pink-100 p-4 pl-6 rounded-lg">
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="text-lg text-green-600 mr-4"
      />
      {/* {children} */}
      Se ha actualizado exitosamente
    </div>
  </div>
)
const Profile = () => {
  const [currentTab, setCurrentTab] = useState(0)
  const user = useSelector(state => state.user)
  const { type } = user
  return (
    <section className="mt-4 lg:mt-16 md:mt-24 max-w-5xl px-6 sm:px-0 md:px-0 mx-auto">
      {isEmpty(user) ? (
        <Loading />
      ) : (
        <div className=" flex flex-col lg:flex-row justify-between">
          <TabLinks
            handleClick={setCurrentTab}
            currentTab={currentTab}
            type={type ? type : 1}
          />
          <div className="flex flex-col w-full mb-32 relative">
            {/* profile */}
            {currentTab === 0 && (
              <ToastProvider components={{ Toast: MyCustomToast }}>
                <Edit />
              </ToastProvider>
            )}
            {/* account */}
            {currentTab === 1 && (
              <ToastProvider components={{ Toast: MyCustomToast }}>
                <Account />
              </ToastProvider>
            )}
            {/* certificados vacio */} {/* certificados llenos */}{" "}
            {/* abrir certificado */}
            {currentTab === 2 && <Certifications />}
            {/* hitorial de pago */}
            {currentTab === 3 && <History />}
            {/* metodo de pago */}
            {currentTab === 4 && <Method />}
            {/* mis pagos - tutor */}
            {currentTab === 5 && <Payments />}
            {/* información bancaria */}
            {currentTab === 6 && <InformationBank />}
            {/* enlaces de referidos */}
            {currentTab === 7 && <ReferenceLink />}
          </div>
        </div>
      )}
    </section>
  )
}
export default Profile
