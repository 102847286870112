import React from "react"
import Tippy from "@tippy.js/react"

const ToolTipDescription = ({ descriptions }) => (
  <div className="bg-white w-48 rounded-lg text-chocolate p-2">
    {descriptions}
  </div>
)
const TopBar = props => {
  const data = [
    {
      id: 1,
      image: "#pc",
      label: "100% Online",
      width: "210px",
      description:
        "Cursos en línea donde puedes avanzar a través de lecciones a tu propio ritmo y desde cualquier lugar.",
    },
    {
      id: 2,
      image: "#hand",
      label: "Pago único",
      width: "194",
      description:
        "Paga una sola vez por el curso y accede cuando quieras. No tiene fecha límite de visualización.",
    },
    {
      id: 3,
      image: "#lock",
      label: "Acceso permanente",
      width: "210px",
      description:
        "Una vez que compras el curso tienes acceso 24/7 desde cualquier dispositivo móvil con conexión a internet.",
    },
    {
      id: 4,
      image: "#conversation",
      label: "Sección de Q&A",
      width: "210px",
      description:
        "Arma o participa en foros de consulta y recibe apoyo de tutores en el proceso de aprendizaje.",
    },
    {
      id: 5,
      image: "#diploma",
      label: "Certificación al finalizar",
      width: "210px",
      description:
        "Descarga el certificado firmado por el tutor y avalado por Comuni al completar el curso.",
    },
  ]

  return (
    <section className="hidden md:block bg-yellow-10">
      <div className="py-4 flex justify-around container mx-auto">
        {data.map(item => (
          <div
            className="flex items-center"
            key={item.id}
            style={{ width: item.width }}
          >
            <Tippy
              content={<ToolTipDescription descriptions={item.description} />}
              arrow={false}
              animation="scale"
              duration={0}
              delay={[0, 0]}
              theme={"light"}
            >
              <div className="bg-white rounded-full shadow-second w-20 h-20 mr-3 text-primary hover:text-white hover:bg-gradient flex justify-center items-center ">
                <svg
                  className={
                    item.image === "#hand"
                      ? "w-full h-full fill-current"
                      : "w-12 h-12 fill-current"
                  }
                >
                  <use xlinkHref={item.image} />
                </svg>
              </div>
            </Tippy>
            <span className="font-medium text-xl w-1/2 leading-tight">
              {item.label}
            </span>
          </div>
        ))}
      </div>
    </section>
  )
}
export default TopBar
