import React, { useState } from "react"
import Avatar from "react-avatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import Http from "@modules/Http"
import has from "lodash/has"
import { BeatLoader } from "react-spinners"
import { updateUser } from "@actions/users"
import { useDispatch } from "react-redux"

import ErrorLabel from "@share/ErrorLabel"

const AXIOS_OPTIONS = {
  headers: {
    "content-type": "multipart/form-data",
  },
}

const ChangeImage = ({ profile, user }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  async function updateImage(event) {
    try {
      setIsLoading(true)
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append("image", file, file.name)
      const { data: profileData } = await Http.post(
        "/profile/avatar",
        formData,
        AXIOS_OPTIONS
      ).finally(() => setIsLoading(false))
      setErrors({})
      dispatch(
        updateUser({
          profile: {
            image: profileData.image,
          },
        })
      )
    } catch (error) {
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-start">
        <BeatLoader color="#ef7625" />
      </div>
    )
  }

  return (
    <div className="bg-yellow-10 rounded-xl py-4 md:py-0 px-2 md:h-32 lg:h-40 w-full flex flex-row items-center justify-center md:px-6">
      <div className="rounded-full bg-gray-500 w-16 h-16 md:w-28 md:h-28 flex items-center justify-center border-2 border-dashed border-black">
        {profile.image ? (
          <img
            src={profile.image}
            alt=""
            className="object-cover w-full h-full rounded-full"
          />
        ) : (
          <div>
            <div className="hidden lg:flex justify-center items-center">
              <Avatar name={user.names} round={true} size={"100px"} />
            </div>
            <div className="flex lg:hidden justify-center items-center">
              <Avatar name={user.names} round={true} size={"50px"} />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col flex-1 ml-4 lg:ml-8">
        <label
          htmlFor="image"
          className="flex flex-row items-center text-base lg:text-xl mb-2 text-primary cursor-pointer hover:text-primary-10"
        >
          <FontAwesomeIcon icon={faUpload} />
          <span className="ml-2 text-lg inline-block">
            Cambiar foto de pérfil
          </span>
        </label>
        <input
          type="file"
          id="image"
          name="image"
          onChange={updateImage}
          accept="image/x-png,image/jpeg"
          hidden
        />
        <span className="text-xs md:text-lg">(Máximo 2MB permitidos)</span>
      </div>
    </div>
  )
}

export default ChangeImage
