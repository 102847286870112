import React from "react"
import StarRatings from "react-star-ratings"
import { navigate } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons"
const CardTutor = props => {
  const course = props.course || []
  return (
    <div className="flex flex-col lg:flex-row bg-white shadow-second rounded-xl md:w-1/2 w-full lg:w-full border mb-6 md:mx-auto md:h-175 overflow-hidden">
      <div className="w-full md:w-40 h-32 md:h-full">
        <img
          src={course.image}
          alt=""
          className="rounded-t-lg lg:rounded-l-xl lg:rounded-t-none lg:h-full w-full lg:w-auto object-cover h-full"
        />
      </div>
      <div className="flex flex-col md:flex-row w-full px-4">
        <div className="flex flex-col md:w-1/3 lg:w-2/5 pt-2">
          <span className="text-lg font-semibold md:text-xl leading-tight">
            {course.name}
          </span>
          <div className="flex flex-row items-center">
            {course && (
              <StarRatings
                rating={course.average_score}
                starSpacing="15px"
                starRatedColor="rgb(239, 118, 37)"
                starDimension="20px"
                starSpacing="0px"
              />
            )}
            <span className="ml-2 -mb-1 text-base md:text-lg">
              {course.average_score} valoraciones
            </span>
          </div>
          <span className="text-lg md:text-3xl font-semibold">
            {course.price}
          </span>
        </div>
        <div className="border-gray-200 flex flex-col md:w-1/4 border-t-2 lg:border-l-2 lg:border-t-0 -mx-4 px-4 py-2 lg:pl-4 md:mt-0 mt-2">
          <div className="flex flex-row md:flex-col items-center lg:items-start justify-start">
            <span className="text-base md:text-lg mr-2 md:mr-0">
              Ganancias de este mes <span className="md:hidden">:</span>
            </span>
            <span className="text-lg md:text-3xl font-semibold">
              {course.month_revenue}
            </span>
          </div>
          <div className="flex flex-row md:flex-col items-center lg:items-start justify-start">
            <span className="text-base md:text-lg mr-2 md:mr-0">
              Ganancias totales <span className="md:hidden">:</span>
            </span>
            <span className="text-lg md:text-3xl font-semibold">
              {course.total_revenue}
            </span>
          </div>
        </div>
        <div className="border-gray-200 flex flex-col md:w-1/4 border-t-2 lg:border-l-2 lg:border-t-0 -mx-4 px-4 py-2 lg:pl-4">
          <div className="flex flex-row md:flex-col items-center lg:items-start justify-start">
            <span className="text-base md:text-lg mr-2 md:mr-0">
              Estudiantes totales <span className="md:hidden">:</span>
            </span>
            <span className="text-lg md:text-3xl font-semibold">
              {course.total_students}
            </span>
          </div>
          <span className="text-primary">
            +{course.new_students} nuevos este mes
          </span>
        </div>
        <div className="border-gray-200 flex flex-col md:w-1/4 border-t-2 lg:border-l-2 lg:border-t-0 -mx-4 px-4 py-2 lg:pl-4">
          <div className="flex flex-row-reverse md:flex-row text-center items-center justify-between md:justify-end">
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              className="text-primary text-xl md:hidden self-end"
              onClick={() => navigate(`cursos/${course.id}/preguntas`)}
            />
            <div className="flex flex-row-reverse md:flex-col">
              <span className="md:text-4xl text-lg text-primary font-bold ml-2 md:ml-0">
                {course.unanswered_questions}
              </span>
              <button
                onClick={() => navigate(`cursos/${course.id}/preguntas`)}
                className="md:text-center md:text-lg md:ml-4 lg:text-left md:hidden"
              >
                Preguntas sin responder <span className="md:hidden">:</span>
              </button>
            </div>
            <span className="md:text-center md:text-lg md:ml-4 lg:text-left hidden md:block">
              Preguntas sin responder <span className="md:hidden">:</span>
            </span>
          </div>
          <div className="hidden lg:flex mt-8 text-center w-4/5 mx-auto">
            <button
              onClick={() => navigate(`cursos/${course.id}/preguntas`)}
              className="btn btn-primary btn--rounded text-base"
            >
              Responder
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CardTutor
