import React, { useState } from "react"
import Http from "@modules/Http"
import { logInUser } from "../../actions/session"
import { useDispatch } from "react-redux"
import { navigate } from "@reach/router"
import ErrorLabel from "@share/ErrorLabel"
import has from "lodash/has"
import { BeatLoader } from "react-spinners"

const Recovery = props => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({
    password: "",
    password_confirmation: "",
    email: "",
  })

  async function recovery() {
    setIsLoading(true)
    const dataRecovery = {
      token: props.token,
      email: data.email,
      password_confirmation: data.password_confirmation,
      password: data.password,
    }
    const dataLogin = {
      email: data.email,
      password: data.password,
    }
    try {
      await Http.post("/reset/password", dataRecovery)
      const authResponse = await dispatch(logInUser(dataLogin))
      setIsLoading(false)

      if (authResponse.user_type_id === 2) {
        return navigate("/tutor")
      }
      return navigate("/estudiante")
    } catch (errors) {
      setIsLoading(false)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }

  function handleInput(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <div className="flex items-center justify-center w-full h-screen bg-white">
      <div className="w-full p-6 md:p-0 flex flex-col items-center">
        <span className="text-3xl md:text-4xl font-bold mb-4">
          Restablecer contraseña
        </span>
        <span className="text-base md:text-xl w-4/5 text-center mb-6">
          Ingrese su nueva contraseña para poder finalizar el proceso
        </span>
        <div className="max-w-sm mx-auto w-full">
          <div className="mb-4">
            <input
              type="text"
              className="input h-12 w-full"
              placeholder="Confirmar email"
              name="email"
              onChange={handleInput}
            />
            <ErrorLabel className="mt-1" errors={errors} name="email" />
          </div>
          <div className="mb-4">
            <input
              type="password"
              className="input h-12 w-full"
              placeholder="Contraseña"
              name="password"
              onChange={handleInput}
            />
            <ErrorLabel className="mt-1" errors={errors} name="password" />
          </div>
          <input
            type="password"
            className="input h-12 w-full mb-4"
            placeholder="Confirmar contraseña"
            onChange={handleInput}
            name="password_confirmation"
          />
          <div className="w-32 mx-auto">
            {isLoading ? (
              <button className="btn btn-primary h-12">
                <BeatLoader color="white" />
              </button>
            ) : (
              <button className="btn btn-primary h-12" onClick={recovery}>
                Enviar
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default Recovery
