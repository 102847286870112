import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "@reach/router"
import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"
import Steps from "./Steps"
const Register = () => {
  const [current, setcurrent] = useState(true)
  return (
    <section className="flex items-center justify-center w-full min-h-screen md:py-16 md:bg-yellow-10">
      <div className="h-screen md:h-auto md:flex flex-col">
        <Steps current={current} />
        <div className="md:bg-white rounded-semi md:shadow-xl py-8 md:px-12 max-w-2xl">
          <div className="flex flex-col">
            <div className="md:hidden flex justify-between text-base text-gray-900 mb-4 px-6 md:px-0 text-sm">
              <span>Paso {current ? "1" : "2"} de 2</span>
              <button onClick={() => navigate("/")}>
                <FontAwesomeIcon icon={faTimes} className="opacity-25" />
              </button>
            </div>
            {current ? <FirstStep setcurrent={setcurrent} /> : <SecondStep />}
          </div>
        </div>
        {!current && (
          <div className="md:mt-6 w-3/4 mx-auto text-center text-sm">
            <p>
              Esta información la utilizaremos para recomendarte cursos y
              noticias relacionadas a las áreas de tu interés.
            </p>
          </div>
        )}
      </div>
    </section>
  )
}
export default Register
