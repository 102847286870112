export const LOGOUT = "LOGOUT"
export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS"
export const STORE_JWT_TOKEN = "STORE_JWT_TOKEN"
export const REMOVE_JWT_TOKEN = "REMOVE_JWT_TOKEN"

// USER >>>
export const SET_USER = "SET_USER"
export const UPDATE_USER = "UPDATE_USER"

// CHECKOUT MODAL >>>
export const SHOW_CHECKOUT_MODAL = "SHOW_CHECKOUT_MODAL"
export const HIDE_CHECKOUT_MODAL = "HIDE_CHECKOUT_MODAL"

// ERROR MESSAGE >>>
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE"
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE"

// COURSE >>>
export const SET_COURSE = "SET_COURSE"

// QUESTIONS >>>
export const SET_QUESTIONS = "SET_QUESTIONS"

// QUESTION >>>
export const SET_QUESTION = "SET_QUESTION"
export const ADD_QUESTION = "ADD_QUESTION"
export const UPDATE_QUESTION = "UPDATE_QUESTION"

// ANSWERS >>>
export const SET_ANSWERS = "SET_ANSWERS"
export const SET_ANSWER = "SET_ANSWER"

// CURRENT VIDEO >>>
export const SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO"

// CURSOS POR USUARIOS
export const SET_USER_COURSES = "SET_USER_COURSES"

// CART
export const SET_CART = "SET_CART"
export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
