import React from "react"
import Slider from "./../Slider"
import MiniCourseCard from "./MiniCourseCard"

const ChunkSlider = ({ chunkRecomendCourse }) => {
  if (chunkRecomendCourse && chunkRecomendCourse.length > 0) {
    return (
      <Slider slidesPerView={4} arrows={false}>
        {chunkRecomendCourse.map((chunk, index) => {
          return (
            <div key={index}>
              {chunk.map(course => (
                <div key={course.id} className="mb-2">
                  <MiniCourseCard course={course} />
                </div>
              ))}
            </div>
          )
        })}
      </Slider>
    )
  }
  return <div />
}
export default ChunkSlider
