import { SET_QUESTIONS, ADD_QUESTION, UPDATE_QUESTION } from "../constants"
import initialState from "./initialState"

function updateById(state, data) {
  return state.map(item => {
    if (item.id === data.id) {
      return {
        ...item,
        ...data,
      }
    }
    return {
      ...item,
    }
  })
}

function questions(state = initialState.questions, action) {
  switch (action.type) {
    case SET_QUESTIONS:
      return [...action.payload]
    case ADD_QUESTION:
      return [...state, action.payload]
    case UPDATE_QUESTION:
      return updateById(state, action.payload)
    default:
      return state
  }
}

export default questions
