import { mutation } from "gql-query-builder"

/**
 * Build a mutation base on data
 * @param {Object} data
 */
function buildMutation(operation, data, fields = null) {
  return mutation({
    operation,
    variables: { ...data },
    fields: fields || Object.keys(data),
  })
}

export const UPDATE_PROFILE_MUTATION = (data = {}) => {
  return buildMutation("updateProfile", data)
}

export const CREATE_ANSWER = (data = {}, fields) => {
  return buildMutation("addAnswer", data, fields)
}

export const CREATE_QUESTION = (data = {}, fields) => {
  return buildMutation("addQuestion", data, fields)
}
