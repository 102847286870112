import { SET_CURRENT_VIDEO } from "../constants"
import initialState from "./initialState"

function currentVideo(state = initialState.currentVideo, action) {
  switch (action.type) {
    case SET_CURRENT_VIDEO:
      return { ...action.payload }
    default:
      return state
  }
}

export default currentVideo
