import React from "react"
const tabClasses =
  "outline-none focus:outline-none hover:text-primary block py-2"
const tabActiveClasses =
  "outline-none focus:outline-none hover:text-primary block text-primary py-2"
const tabMovilClasses =
  "outline-none focus:outline-none block px-4 h-full bg-white text-chocolate shadow-lg px-3 truncate mr-6 rounded-lg font-semibold text-base"
const tabMovilActiveClasses =
  "outline-none focus:outline-none block px-4 h-full bg-primary text-white shadow-lg px-3 truncate mr-6 rounded-lg font-semibold text-base"
const TabLinks = ({ handleClick, currentTab, type }) => (
  <div className="md:mr-16 overflow-hidden md:overflow-visible pr-6">
    <div className="hidden lg:flex flex-col rounded-lg shadow-mini border bg-white p-8 text-xl w-64 items-start">
      <button
        className={currentTab === 0 ? tabActiveClasses : tabClasses}
        onClick={() => handleClick(0)}
      >
        Editar Perfil
      </button>
      {type.type === "user" ? (
        <div>
          <button
            className={currentTab === 1 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(1)}
          >
            Cuenta
          </button>
          <button
            className={currentTab === 2 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(2)}
          >
            Mis Certificaciones
          </button>
          <button
            className={currentTab === 3 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(3)}
          >
            Historial de Pagos
          </button>
          {/* <button
            className={currentTab === 4 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(4)}
          >
            Métodos de Pagos
          </button> */}
        </div>
      ) : (
        <div>
          <button
            className={currentTab === 5 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(5)}
          >
            Mis Pagos
          </button>
          <button
            className={currentTab === 6 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(6)}
          >
            Información Bancaria
          </button>
          <button
            className={currentTab === 7 ? tabActiveClasses : tabClasses}
            onClick={() => handleClick(7)}
          >
            Enlace de Referidos
          </button>
        </div>
      )}
    </div>
    <div className="lg:hidden flex flex-row overflow-x-auto h-24 items-center -mr-6 mb-4">
      <div className="flex flex-row h-12">
        <button
          className={currentTab === 0 ? tabMovilActiveClasses : tabMovilClasses}
          onClick={() => handleClick(0)}
        >
          Editar Perfil
        </button>
      </div>
      {type.type === "user" ? (
        <div className="flex flex-row h-12">
          <button
            className={
              currentTab === 1 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(1)}
          >
            Cuenta
          </button>
          <button
            className={
              currentTab === 2 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(2)}
          >
            Mis Certificaciones
          </button>
          <button
            className={
              currentTab === 3 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(3)}
          >
            Historial de Pagos
          </button>
          {/* <button
            className={
              currentTab === 4 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(4)}
          >
            Métodos de Pagos
          </button> */}
        </div>
      ) : (
        <div className="flex flex-row h-12">
          <button
            className={
              currentTab === 5 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(5)}
          >
            Mis Pagos
          </button>
          <button
            className={
              currentTab === 6 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(6)}
          >
            Información Bancaria
          </button>
          <button
            className={
              currentTab === 7 ? tabMovilActiveClasses : tabMovilClasses
            }
            onClick={() => handleClick(7)}
          >
            Enlace de Referidos
          </button>
        </div>
      )}
    </div>
  </div>
)
export default TabLinks
