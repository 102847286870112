import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { navigate } from "gatsby"

const SearchBox = ({
  currentRefinement,
  isSearchStalled,
  refine,
  setQuery,
  query,
  hasResults,
  setIsOpenMenuSearch,
}) => {
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault()
      const q = query
      refine("")
      setQuery("")

      if (setIsOpenMenuSearch) {
        setIsOpenMenuSearch(false)
      }

      return navigate(`/buscar?q=${q}`)
    }
  }

  return (
    <form noValidate action="" role="search" className="relative w-full">
      <input
        type="text"
        className={`bg-white ${
          !hasResults ? "shadow-primary rounded-xl" : "rounded-t-xl"
        } h-10 px-4 w-full text-lg`}
        value={currentRefinement}
        placeholder="Buscar cursos"
        // onBlur={() => {
        //   setQuery("")
        //   refine("")
        // }}
        onKeyDown={handleKeyDown}
        onChange={event => {
          setQuery(event.currentTarget.value)
          refine(event.currentTarget.value)
        }}
      />
      <div className="absolute right-0 top-0 h-full flex items-center w-10 justify-center z-10">
        <FontAwesomeIcon icon={faSearch} className="text-primary" />
      </div>
    </form>
  )
}

export default connectSearchBox(SearchBox)
