import React, { useState } from "react"
import { GET_MY_COURSE } from "@queries"
import { useSelector } from "react-redux"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import Loading from "@share/Loading"
import CourseCardRating from "./CourseCardRating"
import Rating from "../share/ModalRating"

const CourseStudents = () => {
  const [rating, setRating] = useState(false)
  const [selectCourseId, setSelectCourseId] = useState("")
  const { id } = useSelector(state => state.user)
  const [data, isFetching] = useAsyncGraphQL(GET_MY_COURSE(id), id)

  if (isFetching) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading />
      </div>
    )
  }
  return (
    <div className="pt-8">
      <section className="">
        <div className="mx-auto py-8 mx-auto">
          <div className="flex justify-between mx-auto items-center container px-6 xl:px-14">
            <span className="text-xl md:text-2xl lg:text-4xl font-semibold md:px-0">
              Mis cursos
            </span>
          </div>
          <div className="mt-10 max-w-6xl mx-auto grid grid-columns-1 md:grid-columns-3 grid-gap-4">
            {data.courses?.map(recomend => (
              <div key={recomend.id}>
                <CourseCardRating
                  course={recomend}
                  score={true}
                  setRating={setRating}
                  setSelectCourseId={setSelectCourseId}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      {rating && (
        <Rating setRating={setRating} selectCourseId={selectCourseId} />
      )}
    </div>
  )
}
export default CourseStudents
