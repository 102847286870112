import React from "react"

const Avatar = props => (
  <img
    alt="comuni"
    src={props.image}
    className="hidden w-14 h-14 overflow-hidden object-cover md:block rounded-full mr-4"
  />
)

export default Avatar
