import { mutation } from "gql-query-builder"

/**
 * Build a mutation base on data
 * @param {Object} data
 */
function buildMutation(operation, data, fields = null) {
  return mutation({
    operation,
    variables: { ...data },
    fields: fields || Object.keys(data),
  })
}

export const GET_COURSES = () => {
  return `{
    courses {
      id
      name
      available
      image
      price
      discount
      total_scores
      average_score
      created_at
    }
    interests {
      id
      name
      total_courses
    }
  }
  `
}

export const GET_COURSE_PER_USER = id => {
  return `
  {
    user(id:${id}){
      courses{
        id
        name,
        image,
        completed_percent,
        teacher{
          names,
          surnames
        }
      }
    }
  }
  `
}

export const GET_HOME_TUTOR = id => {
  return `
    {
      user(id: ${id}) {
        names
        surnames
        full_name
        month_revenue
        total_revenue
        total_students
        average_score
        available_courses
        unanswered_questions
        
      }
    }
  `
}
export const GET_OWN_COURSE = (id, sortBy, query) => {
  console.log("id>>", id)
  console.log("sortby", sortBy)
  console.log("query", query)

  return `{
  courses(teacherId: ${id}, sortBy:${sortBy}, query: "${query}") {
      id
      name
      image
      average_score
      price
      discount
      total_students
      month_revenue
      total_revenue
      unanswered_questions
      new_students
    }}
  `
}

export const GET_HOME_CLIENT = () => {
  return `
  {
    testimonials(limit:6) {
      id
      image
      name
      description
    }
    tutores:interests {
      name
      popular_teacher {
        names
        surnames
        full_name
        profile{
          image
          degree
          description
        }
        courses {
          id
          name
          duration
          contents{
            id
          }
          interests {
            name
          }
        }
      }
    }
    courses(limit:10) {
      id
      name
      image
      total_scores
      average_score
      price
      discount
      duration
      teacher {
        names
        surnames
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
      total_students
    }
    interests {
      name
      image
      total_courses
    }
    populares: courses(limit: 5, sortBy: popular) {
      name
      image
      total_scores
      average_score
      price
      discount
      duration
      teacher {
        names
        surnames
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
      total_students
    }
  }
  
  `
}

export const GET_HOME_STUDENT = id => {
  return `
  {
    courses(studentId: ${id}) {
      id
      name
      image
      remaining_hours
      total_contents
      completed_contents
      completed_percent
    }
    user(id:${id}){
      full_name
      email
      interests{
        id
        name
        image
      }
    }
    interests {
      name
      id
      image
    }
    important: courses(sortBy: popular, limit:10){
      id
      name
      available
      image
      price
      discount
      total_scores
      average_score
      created_at
      teacher {
        names
        surnames
        full_name
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
      total_students
    }
    
      interestsCourses: courses(recommendedToUser: ${id}, limit: 10) {
        id
        name
        available
        image
        price
        discount
        total_scores
        average_score
        created_at
        teacher {
          names
          surnames
          full_name
        }
        students {
          names
          surnames
          profile {
            image
          }
        }
        total_students
      }
    }
  `
}
export const GET_COURSE_CURRENT = id => {
  return `
  {
    course(id:${id}){
      id
      name
      about
      price
      discount
      duration
      total_classes
      average_score
      image
      purchased
      scores {
        id
        title
        comment
        score
        human_created_at
        user {
          full_name
          profile {
            image
          }
        }
      }
      teacher {
        names
        surnames
        full_name
        total_students
        average_score
        profile {
          description
          image
        }
      }
      contents {
        id
        name
        description
        duration
        completed_percent
        videos {
          id
          title
          url
          placeholder
          duration
          previous_video
          next_video
          completed
          preview
          free
          final
        }
        documents {
          id
          title
          url
        }
      }
      questions {
        id
        text
        title
        created_at
        human_created_at
        total_answers
        answers {
          id
          text
          created_at
          human_created_at
          question_id
          user {
            id
            names
            surnames
            full_name
            profile {
              image
              degree
            }
          }
        }
        user {
          id
          full_name
          profile {
            image
          }
        }
      }
      interests{
        id,
        name
      }
    }
  }
  
  `
}
export const GET_CERTIFICATE = id => {
  return `
  {
    user(id:${id}){
      id
      names
      surnames
      certifications{
        id
        approved_date
        course{
          id
          name
        }
        
      }
    }
  }
  `
}
export const GET_USER_PROFILE = id => {
  return `
  {
    user(id: ${id}) {
      id
      names
      surnames
      email
      type{
        id
        type
      }
      profile{
        id
        image
        country
        phone
        document_id
        document_type
        degree
        referal_link
        description
      }
    }
  }
  `
}
export const RECOMMENDED_COURSES = id => {
  return `
  {
    courses(recommendedToUser: ${id}, limit: 7) {
      id
      name
      available
      image
      price
      discount
      total_scores
      average_score
      total_students
      created_at
      teacher {
        names
        surnames
        full_name
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
    }
  }`
}
export const GET_CATEGORY = () => {
  return `{
    categorias : interests{
      name
      id
      courses{
        id
        name
        image
        price
        total_scores
        teacher{
          full_name
        }
      }
    }
  }`
}
export const GET_BILLING = id => {
  return `
  {
    user(id:${id}){
      orders{
        id
        names
        invoice_id
        status
        total
        created_at
        details{
          id
          description
          total
          course{
            image
          }
        }
      }
      payments{
        id
        total
        total_courses
        description
        invoice_id
        status
        created_at
        details{
          id
        }
      }
    }
  }  
  `
}
export const GET_ORDER = id => {
  return `
  {
    order(id: ${id}) {
      id
      names
      invoice_id
      status
      total
      created_at
      details {
        id
        created_at
        description
        total
        course {
          image
        }
      }
    }
  }
  
  `
}
export const GET_PAYMENT = id => {
  return `
  {
    payment(id: ${id}) {
      id
      invoice_id
      status
      total
      created_at,
      referral,
      organic,
      details {
        id
        created_at
        description
        total,
        type
        
      }
    }
  }
  
  
  `
}
export const GET_INFO_BANK = id => {
  return `
  {
    user(id:${id}){
      bank_accounts{
        id
        account_number
        name
        email
        bank_name
        created_at
        document_type
        dni
      }
    }
  }  
  `
}
export const UPDATE_PROFILE_MUTATION = (data = {}) => {
  return buildMutation("updateProfile", data)
}
export const CREATE_ANSWER = (data = {}, fields) => {
  return buildMutation("addAnswer", data, fields)
}
export const GET_REFERENCELINK = id => {
  return `
  {
    user(id:${id}){
      profile{
        referal_link
      }
    }
  }
  `
}
export const GET_MY_COURSE = id => {
  return `
  {
    courses(studentId: ${id}) {
      id
      name
      available
      image
      price
      discount
      total_scores
      average_score
      created_at
      teacher {
        names
        surnames
        full_name
      }
      user_score{
        id
        score
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
      total_students
    }
  }
  `
}
export const GET_RATING = id => {
  return `
  {
    course(id: ${id}) {
      id
      name
      user_score{
        id
        score
        comment
      }
    }
  }
  `
}

export const SEARCH_COURSES = (query, sortBy, interest = null) => {
  if (interest && interest === 0) {
    return `
  {
    courses(query:"${query}",sortBy:${sortBy}) {
      id
      name
      image
      total_scores
      average_score
      price
      discount
      duration
      teacher {
        names
        surnames
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
      total_students
    }
    interests {
      name
      id
    }
  }
  `
  }
  return `
  {
    courses(query:"${query}",sortBy:${sortBy},interests:[${interest}]) {
      id
      name
      image
      total_scores
      average_score
      price
      discount
      duration
      teacher {
        names
        surnames
      }
      students {
        names
        surnames
        profile {
          image
        }
      }
      total_students
    }
    interests {
      name
      id
    }
  }
  `
}
