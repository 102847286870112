import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import take from "lodash/take"
import StarRatings from "react-star-ratings"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { addToCart } from "@actions/cart"
import numeral from "numeral"

String.prototype.trimEllip = function(length) {
  return this.length > length ? this.substring(0, length) + "..." : this
}

const MiniCourseCard = ({ course }) => {
  const dispatch = useDispatch()

  function getGoToCourse() {
    navigate(`/cursos/${course.id}`)
  }

  function addCourseToCart() {
    dispatch(addToCart(course))
  }

  return (
    <div
      className="rounded-xl shadow-mini w-full mx-auto relative flex overflow-hidden bg-white h-28"
      onClick={getGoToCourse}
    >
      <div className="bg-black relative h-full w-28">
        <img
          src={course.image}
          alt="algo"
          className="h-full absolute top-0 left-0 w-full object-cover"
        />
      </div>
      {course.discount && (
        <div className="absolute rounded-r-full bg-primaryPink w-auto px-2 text-center top-12">
          <span className="text-primaryOrange text-sm mr-1">%</span>
          <span className="text-white text-sm">OFERTA</span>
        </div>
      )}
      <div className="flex flex-col text-lg flex-1 px-4 py-2 justify-between">
        <div className="flex flex-col justify-around">
          <h2 className="font-sans text-base font-semibold leading-tight block truncate w-32 xs:w-auto xs:break-normal">
            {course.name.trimEllip(20)}
          </h2>
          <span className="text-sm leading-tight">
            {course.teacher.full_name}
          </span>
          <StarRatings
            rating={course.average_score}
            starSpacing="13px"
            starRatedColor="rgb(245, 202, 35)"
            starDimension="16px"
            starSpacing="0px"
          />
        </div>
        <div className="flex flex-row justify-between items-end">
          <div className="w-24 border-box">
            <button
              className="btn btn-primary btn--rounded btn--tiny text-sm"
              onClick={e => {
                e.stopPropagation()
                addCourseToCart()
              }}
            >
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="text-white mr-2"
              />
              Añadir
            </button>
          </div>
          {course.discount ? (
            <div className="flex-col text-right absolute bottom-0 right-0 px-4 py-1 hidden xs:flex">
              <span className="text-base line-through -mb-2 h-6">
                {numeral(course.price).format("$0,0.00")}
              </span>
              <span className="text-2xl md:text-4xl font-bold">
                {numeral(course.discount).format("$0,0.00")}
              </span>
            </div>
          ) : (
            <div className="flex-col text-right absolute bottom-0 right-0 px-4 py-1 hidden xs:flex">
              <span className="text-2xl md:text-4xl font-bold">
                ${course.price}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default MiniCourseCard
