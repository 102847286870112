import React, { useEffect, useState } from "react"
import imgNotify from "../../images/icon-empty-notif.svg"
import Http from "@modules/Http"
import isEmpty from "lodash/isEmpty"
import { useSelector } from "react-redux"
import keyBy from "lodash/keyBy"
import merge from "lodash/merge"
import values from "lodash/values"
import compact from "lodash/compact"
import sumBy from "lodash/sumBy"
import isObject from "lodash/isObject"
import { navigate } from "@reach/router"
import dayjs from "dayjs"
import "dayjs/locale/es"
import relativeTime from "dayjs/plugin/relativeTime"
import CreateMarkup from "./../../helpers/CreateMarkup"

dayjs.locale("es")
dayjs.extend(relativeTime)

const Notifications = props => {
  const user = useSelector(state => state.user)
  const [notifications, setNotifications] = useState([])
  const [notification, setNotification] = useState([])
  const [Firebase, setFirebase] = useState(null)

  useEffect(() => {
    const app = import("firebase/app")
    const database = import("firebase/database")

    Promise.all([app, database]).then(values => {
      const firebase = values[0]
      setFirebase(firebase)
      firebase.initializeApp({
        apiKey: "AIzaSyBwtGp_13100nzNBG3dHlXAUoysSVB2zIw",
        authDomain: "comuni-12347.firebaseio.com",
        databaseURL: "https://comuni-12347.firebaseio.com",
        projectId: "comuni-12347",
      })
      const ref = firebase
        .database()
        .ref("notifications")
        .orderByChild("created_at")
        .startAt(Date.now())
      ref.on("child_added", addNotification)
    })
  }, [])

  useEffect(() => {
    getNotifications()
  }, [])

  useEffect(() => {
    if (props.unread === 0) {
      const readedNotifications = notifications.map(notification => {
        if (isEmpty(notification)) {
          return notification
        }
        return {
          ...notification,
          readed: 1,
        }
      })
      setNotifications(readedNotifications)
    }
  }, [props.unread])

  useEffect(() => {
    const newNotifications = addOrReplace(notifications, notification)
    getUnreads(newNotifications)
    setNotifications(newNotifications.filter(item => !isEmpty(item)))
  }, [notification])

  function getNotifications() {
    Http.get("/notifications").then(({ data }) => {
      if (!isEmpty(data)) {
        const newNotifications = mergeById(notifications, data)
        getUnreads(newNotifications)
        setNotifications(newNotifications.filter(item => !isEmpty(item)))
      }
      // listenForNotifications()
    })
  }

  function getUnreads(data) {
    const unreads = sumBy(data.filter(item => !isEmpty(item)), notification => {
      return !isEmpty(notification) && notification.readed === 1 ? 0 : 1
    })
    props.setUnread(unreads)
  }

  // function listenForNotifications() {
  //   const ref = firebase
  //     .database()
  //     .ref("notifications")
  //     .orderByChild("created_at")
  //     .startAt(Date.now())
  //   ref.on("child_added", addNotification)
  // }

  function addNotification(snapshot) {
    const notification = snapshot.val()
    if (notification.user_id === user.id) {
      setNotification(notification)
    }
  }

  function addOrReplace(array, item) {
    const i = array.findIndex(_item => _item.id === item.id)
    if (i > -1) {
      if (isObject(item)) {
        array[i] = {
          ...array[i],
          ...item,
        }
      }
    } else {
      if (isObject(item)) {
        array.push(item)
      }
    }
    return array
  }

  function mergeById(arr1, arr2) {
    return values(merge(keyBy(arr1, "id"), keyBy(arr2, "id")))
  }

  if (isEmpty(notifications)) {
    return (
      <div className="flex flex-col items-center justify-between h-full p-4">
        <span className="text-chocolate text-base">
          No tienes ninguna notificación
        </span>
        <img src={imgNotify} alt="" />
      </div>
    )
  }

  const sortedNotifications = notifications
    .filter(notification => !isEmpty(notification))
    .sort((prev, curr) => {
      return new Date(prev.created_at) - new Date(curr.created_at)
    })
    .reverse()

  return (
    <div className="bg-white w-full p-4 rounded-lg text-chocolate">
      <div>
        <ul className="max-h-250 overflow-x-scroll">
          {sortedNotifications.map(notification => (
            <li className="pt-1" key={notification.id}>
              <button
                onClick={() => navigate(notification.url)}
                className="flex items-start w-64 text-left border-b pb-1 cursor-pointer"
              >
                <div className="flex-1">
                  <p
                    dangerouslySetInnerHTML={CreateMarkup(notification.message)}
                  ></p>
                  <small className="text-chocolate">
                    {dayjs(notification.created_at).from(dayjs(), true)}
                  </small>
                </div>
                <div className="pt-1 w-6 flex justify-end">
                  {notification.readed === 1 ? (
                    <small className="w-2 h-2 block rounded-full bg-gray-35 mt-1"></small>
                  ) : (
                    <small className="w-2 h-2 block rounded-full bg-primary mt-1"></small>
                  )}
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Notifications
