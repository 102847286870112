import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const DownloadButton = ({ className }) => (
  <div className={className}>
    <button className="flex-row flex items-center w-full text-primary font-semibold">
      <FontAwesomeIcon icon={faDownload} className="mr-2" />
      <span>Descargar</span>
    </button>
  </div>
)

export default DownloadButton
