import { combineReducers } from "redux"
import session from "./session"
import user from "./user"
import course from "./course"
import currentVideo from "./currentVideo"
import questions from "./questions"
import question from "./question"
import answers from "./answers"
import userCourses from "./userCourses"
import cart from "./cart"
import checkoutModal from "./checkoutModal"
import errorMessage from "./errorMessage"

const rootReducer = combineReducers({
  session,
  user,
  course,
  cart,
  currentVideo,
  questions,
  question,
  answers,
  userCourses,
  checkoutModal,
  errorMessage,
})

export default rootReducer
