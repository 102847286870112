import { SET_ANSWERS, SET_ANSWER } from "../constants"
import initialState from "./initialState"

function answers(state = initialState.answers, action) {
  switch (action.type) {
    case SET_ANSWERS:
      return [...action.payload]
    case SET_ANSWER:
      return [...state, action.payload]
    default:
      return state
  }
}

export default answers
