import React from "react"
import { Link } from "@reach/router"
const Terms = () => {
  return (
    <div className="w-full mx-auto mb-6 flex flex-col">
      <span className="text-2xl font-bold mb-4">Términos y condiciones</span>
      <span className="text-xl font-bold my-4">
        Aceptación de las condiciones
      </span>
      <p>
        Al utilizar el servicio Web COMUNI®, Ud. (de ahora en más denominado “EL
        USUARIO”) aceptará automáticamente estos términos. Asegúrese de leerlos
        atentamente antes de utilizar el servicio Web. Le ofrecemos este
        servicio para su uso personal con los costos que en él se detallan, pero
        debe saber que no está autorizado a copiar, modificar o comercializar el
        aplicativo web, así como ninguna parte de esta, ni nuestras marcas
        comerciales, ni videos o materiales a los que tenga acceso de ningún
        modo. No deberá intentar extraer el código fuente de la website,
        traducir la página a otros idiomas ni crear versiones derivadas. El
        servicio Web COMUNI® y todas las marcas comerciales, los derechos de
        autor, los derechos sobre bases de datos y demás derechos de propiedad
        intelectual relacionados continuarán siendo propiedad de COMUNIEDU
        C.LTDA.
      </p>
      <p className="mt-2">
        El usuario declara expresamente, al aceptar estos términos y
        condiciones, que no tendrá motivo alguno para iniciar acciones legales
        civiles, penales, laborales, administrativas o de cualquier otra índole
        en contra de COMUNIEDU C.LTDA. por el uso del servicio Web, o por
        cualquier otro acto relacionado a las partes aquí mencionadas.{" "}
      </p>
      <p className="mt-2">
        COMUNI® se reserva el derecho de modificar, sin previo aviso, las
        Condiciones para lo cual el Usuario otorga su consentimiento previo y
        expreso.
      </p>
      <span className="text-xl font-bold my-4">Descripción del Sitio</span>
      <p>
        COMUNI es un sitio web donde formamos una comunidad educativa que está
        dispuesta a aprender de diferentes categorías y a mejorar su estilo de
        vida gracias al conocimiento compartido de los profesionales invitados.
        Las personas pueden registrarse, gozar de la compra de nuestros
        servicios que incluyen videos, documentos de lectura descargables y
        cualquier contenido o servicio disponible en COMUNI, además de poder
        aprovechar de los descuentos y promociones. Los usuarios también pueden
        interactuar con la comunidad generando foros de discusión y compartiendo
        recomendaciones, dudas e ideas.
      </p>
      <span className="text-xl font-bold my-4">
        Acceso y utilización del Sitio Web
      </span>
      <p className="mt-2">
        Para registrarse a la web y gozar de los servicios ofrecidos por
        COMUNI®, el usuario deberá colocar su correo electrónico y una
        contraseña. La información proporcionada por el usuario deberá ser
        certera y no deberá atentar contra las buenas costumbres y vulnerar los
        des derechos de terceros, ni atentar contra la reputación de COMUNI®. No
        hacerlo representa una violación de las Condiciones y podría significar
        la cancelación de la cuenta.
      </p>
      <p className="mt-2">
        La cuenta del usuario es personal, por lo cual es prohibido entregarla a
        otra persona. Dicho esto, el usuario deberá cuidar sus datos de registro
        y ante cualquier anomalía o violación de seguridad, debe notificar
        inmediatamente a COMUNI®. COMUNI® no se hace responsable de los daños y
        perjuicios que puedan suceder por actos de seguridad. En uso de la
        plataforma es exclusivo para mayores de 13 años.
      </p>
      <p className="mt-2">
        Tampoco se hará responsable COMUNI® por los servicios que presta a
        través del Internet, ni por el contenido de la información, opinión,
        enlaces de terceros con materiales, productos o servicios que se
        ingresen en el sitio web por parte de los usuarios. Asimismo, COMUNI®
        tendrá la facultad de eliminar las respuestas, opiniones y declaración
        que considere no concuerdan con la línea del negocio. Además, COMUNI® no
        se hace responsable por los daños ocasionados a partir de la divulgación
        de contenido ofensivo hacia el usuario.
      </p>
      <p className="mt-2">
        Algunas funciones de la página requerirán que los usuarios dispongan de
        una conexión a Internet activa. La conexión puede ser mediante Wi-Fi o a
        través del proveedor de servicios de red móvil, pero COMUNI® no se hará
        responsable del mal funcionamiento de la aplicación web en caso de que
        no disponga de conexión Wi-Fi o haya superado su límite de uso de datos.
      </p>
      <p className="mt-2">
        Recuerde que, si utiliza la aplicación web fuera de una zona con
        conexión Wi-Fi, se aplicarán los términos del contrato con su proveedor
        de servicios de red móvil. Por consiguiente, el proveedor de servicios
        móviles podrá aplicar cargos por los datos consumidos durante la
        conexión al acceder a la aplicación, así como otros cargos de terceros.
        Si utiliza el servicio web, acepta la responsabilidad en relación con
        dichos cargos, incluidos los cargos por los datos de roaming si utiliza
        la aplicación fuera del territorio de origen (su región o país) sin
        desactivar el roaming de datos. En caso de que no sea usted la persona
        encargada de pagar los recibos del dispositivo en el que utiliza la
        aplicación, nosotros asumiremos que ha recibido su permiso para utilizar
        la aplicación.
      </p>
      <p className="mt-2">
        De la misma forma, COMUNI® no siempre puede hacerse responsable de la
        forma en que usted utiliza la página web. A pesar de que, evidentemente,
        el personal tomará todas las precauciones necesarias para asegurarse de
        que no le ocurra nada a sus dispositivos, no nos hacemos responsables de
        los posibles daños que pueda sufrir, a menos que sean producto de
        cualquier tipo de negligencia o mala conducta intencionada por parte de
        nuestros programadores. Además, deberá asegurarse de que sus
        dispositivos estén cargados. En caso de que se agote la batería y no sea
        posible usar el Servicio Web de forma adecuada, COMUNI® no se hará
        responsable por este motivo.
      </p>
      <p className="mt-2">
        También debe recordar que el objetivo de la aplicación es facilitarle
        las cosas, pero no sustituye la documentación necesaria tributaria y
        legal del Ecuador; será siempre el Usuario el responsable por la
        declaración propia de impuestos por los servicios que preste a raíz del
        uso del servicio Web; y se obliga siempre al uso legal de la misma en
        forma física ante las entidades públicas, privadas y por sobre todo
        tributarias del Ecuador. Por último, con respecto a la responsabilidad
        de COMUNI® en relación con su uso del servicio Web es importante tener
        en cuenta que, a pesar de que nos esforzamos a fin de garantizar que se
        encuentra actualizada y que funciona correctamente en todo momento,
        dependemos de los servicios de terceros utilizados para obtener
        información con el objeto de facilitársela a usted. Por consiguiente, no
        COMUNI® no aceptará ninguna responsabilidad por las pérdidas, ya sean
        directas o indirectas, que sufra al confiar únicamente en esta función
        de la aplicación web.
      </p>
      <span className="text-xl font-bold my-4">Tarifas</span>
      <p>
        COMUNI® se reserva el derecho de efectuar cambios en el servicio Web o
        de aplicar cargos por los servicios ofrecidos (o de comenzar a cobrar
        por cualquier servicio gratuito), en cualquier momento y por cualquier
        motivo. En ningún caso se aplicarán cargos por la aplicación o sus
        servicios sin notificar con claridad el motivo por el que debe realizar
        el abono y tomando en cuenta que las tarifas pagadas antes de dichos
        cambios no resultarán afectadas.
      </p>
      <span className="text-xl font-bold my-4">Devoluciones</span>
      <p className="mt-2">
        La Política de Reembolso al alumno está a disposición de los alumnos que
        paguen un curso a través de la Plataforma COMUNI®:
      </p>
      <p className="mt-2">-No realizamos devoluciones monetarias.</p>
      <p className="mt-2">
        -Será reembolsado con crédito de igual valor al curso comprado siempre y
        cuando la devolución sea solicitada dentro de las 72h posteriores a la
        compra del curso y no se haya visualizado más de 20% del curso.
      </p>
      <span className="text-xl font-bold my-4">Uso correcto del Sitio Web</span>
      <p className="mt-2">
        El Usuario se compromete a utilizar el Sitio Web de conformidad con lo
        establecido en el ordenamiento jurídico, el orden público y las buenas
        costumbres.  En ese sentido, a título meramente indicativo y no
        exhaustivo, el Usuario se abstendrá de:
      </p>
      <ul className="ml-4">
        <li>
          a. Abusar, acosar, amenazar, suplantar o intimidar a cualquier
          persona.
        </li>
        <li>
          b. Publicar o transmitir cualquier tipo de contenido difamatorio,
          obsceno, pornográfico, abusivo, ofensivo, profano o que infrinja
          derecho de autor o derechos en general de cualquier persona.
        </li>
        <li>
          c. Intentar acceder o realizar búsquedas en motores de búsquedas o
          softwares (spiders, robots, crawlers, herramientas de minería de datos
          o similares) distintos de aquellos provistos por COMUNI o aquellos
          motores de búsquedas genéricos provistos por terceros y de acceso
          público la Internet. Generar o enviar correos no deseados a cualquier
          persona o URL.
        </li>
        <li>
          d. Crear cuentas múltiples con el objetivo de votar a favor o en
          contra de los proyectos de otros usuarios.
        </li>
        <li>
          e. Publicar contenidos de los cuales no posea los derechos de autor.
        </li>
        <li>
          f. Aumentar o alterar el número de votos o comentarios de forma
          artificial, o bien de otro servicio, con el fin de dar o recibir
          dinero o cualquier tipo de compensación por dichos votos.
        </li>
        <li>g. Vender o transferir su perfil</li>
        <li>
          h. Utilizar el Sitio Web para fines comerciales o para el beneficio de
          terceros de manera que infrinja lo establecido en el presente
          documento.
        </li>
        <li>
          i. Manipular cualquier paquete TCP/IP, encabezado (header) o cualquier
          parte de la información de encabezado en cualquier correo electrónico,
          newsgroup, newsletter o utilizar de cualquier manera el Sitio Web para
          enviar información falsa, alterada o que no permita identificar la
          fuente original desde donde se envió.
        </li>
        <li>
          j. Intentar descifrar, descompilar o aplicar ingeniería en reversa a
          cualquier programa de software proveedor del Sitio Web.
        </li>
      </ul>
      <p className="mt-4">
        Al compartir cualquier tipo de contenido, el Usuario declara y garantiza
        que:
      </p>
      <ul className="ml-4 mt-2">
        <li>
          a. El contenido compartido no violará los derechos de propiedad. 
        </li>
        <li>
          b. Si su empleador posee los derechos de cualquier propiedad
          intelectual que usted genere, ha recibido permiso de su empleador para
          publicar el contenido o ha conseguido una extensión de derechos de
          parte de su empleador. 
        </li>
        <li>
          c. El contenido compartido no acarrea ni contagiará de virus o
          cualquier software dañino (malware) o destructivo.
        </li>
      </ul>
      <p>
        COMUNI se reserva el derecho de retirar, terminar o suspender, sin
        previo aviso y en cualquier momento la prestación de los Servicios y el
        acceso a Contenidos en el Sitio Web a aquellos Usuarios que infrinjan
        los presentes Términos y Condiciones de Uso. Por favor, le pedimos que
        no use COMUNI para ningún fin negativo. Queremos siempre velar por la
        creatividad y educación.
      </p>
      <span className="text-xl font-bold my-4">
        Contenido compartido en el Sitio Web
      </span>
      <p>
        Al compartir información o cualquier material, usted conservará los
        derechos de propiedad intelectual. Sin embargo, le concede a COMUNI una
        licencia, no exclusiva para comunicar, distribuir, alojar, modificar o
        derivar trabajos, presentar, publicar, reproducir, almacenar o utilizar
        el contenido.
      </p>
      <p className="mt-4">
        COMUNI no se hará responsable por ningún tipo de divulgación ilegal de
        dicho contenido o copia por parte de otros usuarios, por lo cual usted
        autoriza que terceros usuarios puedan hacer copias de su contenido en
        baja resolución y compartirlas en plataformas de redes sociales.
      </p>
      <span className="text-xl font-bold my-4">
        Cambios en las Condiciones y la web
      </span>
      <p>
        Es posible que en algún momento debamos modificar o reemplazar las
        condiciones. Es deber del usuario revisarlas periódicamente y su
        permanencia en el sitio supondrá la aceptación de los mismos. También es
        posible que debamos actualizar la página Web. Existe la posibilidad de
        que se modifiquen los requisitos necesarios para los sistemas operativos
        de ordenador (y para cualquier otro sistema adicional para el que se
        ofrezca la aplicación en el futuro), por lo que deberá descargar las
        actualizaciones si desea continuar utilizando el servicio COMUNI®. No
        obstante, usted garantiza que aceptará todas las actualizaciones de la
        aplicación web cuando se le ofrezcan. También es posible que deje de
        ofrecerse el servicio y se interrumpa su uso en cualquier momento sin
        previo aviso. A menos que se indique lo contrario, tras la terminación:
        (a) cesarán los derechos y las licencias concedidos mediante los
        presentes términos; (b) deberá suspender el uso de la aplicación web y,
        si fuera necesario, eliminarla de su dispositivo.
      </p>
      <span className="text-xl font-bold my-4">
        Jurisdicción y legislación aplicable
      </span>
      <p>
        LEGISLACIÓN APLICABLE, CONTROVERSIAS Y DOMICILIO.- El Usuario, Tutor,
        Mentor o Profesional y Andrea Osorio Gallego renuncian a fuero y
        domicilio; y, declaran que en caso de surgir alguna controversia
        relacionada con el uso de la aplicación se someterán exclusivamente a lo
        dispuesto en la Ley de Arbitraje y Mediación y su Reglamento, para lo
        cual se obligan en primer término a buscar un acuerdo voluntario con la
        asistencia de un mediador designado por el Centro de Mediación de la
        Prefectura del Guayas.
      </p>
      <p className="mt-4">
        En caso de imposibilidad de acuerdo dictaminada por el mediador,
        cualquiera de las partes podrá someter la controversia al Arbitraje
        Administrado y en Derecho por el Centro de Mediación de la Cámara de
        Comercio de Guayaquil, en cuyo caso se sujetarán de forma incondicional
        y obligatoria a lo dispuesto en la Ley de Arbitraje y Mediación y el
        Reglamento de la de la Cámara de Comercio de Guayaquil.{" "}
      </p>
      <p className="mt-4">
        Los árbitros serán designados siguiendo el procedimiento previsto en el
        artículo 16 de la Ley de Arbitraje y Mediación. Los gastos y honorarios
        que impliquen la actuación de los árbitros serán cubiertos por las
        partes de conformidad con lo dispuesto en el Reglamento del Centro de
        Mediación de la Cámara de Comercio de Guayaquil.
      </p>
      <p className="mt-4">
        El Tribunal estará integrado por tres árbitros y estará facultado para
        que en la ejecución de medidas cautelares solicite el auxilio de los
        funcionarios públicos, judiciales, policiales y administrativos sin que
        sea necesario recurrir a juez ordinario alguno.
      </p>
      <p className="mt-4">
        Si por algún motivo un árbitro no pudiere desempeñar sus funciones, se
        designará un sustituto de la misma manera en que fue designado el
        árbitro principal.{" "}
      </p>
      <p className="mt-4">
        Tanto el acuerdo expresado en el Acta de Mediación como el Laudo
        Arbitral tendrán el efecto de sentencia ejecutoriada y cosa juzgada y no
        admitirán recurso alguno.{" "}
      </p>
      <p className="mt-4">
        Radicada la jurisdicción y competencia, en esta cláusula arbitral, el
        efecto jurídico es el siguiente.
      </p>
      <p className="mt-4">
        No se puede someter la solución de un conflicto a un juez o arbitro
        diferente.
      </p>
      <p className="mt-4">
        Por existir obligatoriedad radicada la jurisdicción y competencia,
        cualquier autoridad que resuelva un conflicto carece de competencia, por
        lo tanto, sus fallos resultarían de nulidad absoluta según lo dispone el
        Código Orgánico General de Procesos.
      </p>
      <p className="mt-4">
        En caso de falta de comparecencia se puede sentar una razón de falta de
        acuerdo y ser juzgado en rebeldía.{" "}
      </p>
      <p className="mt-4">
        Por ser absolutamente conveniente a sus intereses, el Usuario, Mentor,
        Tutor o Profesional, al aceptar el uso de los servicios web de la
        plataforma COMUNI®, acepta todos los términos y condiciones de uso aquí
        estipulados y se somete a ellos, declarando expresamente su
        consentimiento a los mismos.
      </p>
    </div>
  )
}
export default Terms
