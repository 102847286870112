import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import last from "lodash/last"
import flatten from "lodash/flatten"
import isEmpty from "lodash/isEmpty"
import "videojs-contrib-dash"
import { setCurrentVideo } from "@actions/currentVideo"
import { completeVideo } from "@actions/course"
import Loader from "./../../helpers/Loader"
import Loading from "@share/Loading"
import Rating from "../share/ModalRating"

const CourseVideo = ({ fluid = false }) => {
  const dispatch = useDispatch()
  const currentVideo = useSelector(state => state.currentVideo)
  const course = useSelector(state => state.course)
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState(null)
  const [ended, setEnded] = useState(false)
  const [isRating, setRating] = useState(false)

  const getId = url => {
    return url ? last(url.split("/")) : ""
  }

  const handleNextVideo = () => {
    // Get all video
    const videos = flatten(course.contents.map(content => content.videos))
    const nextVideo = videos.find(v => v.id === currentVideo.next_video)
    if (!isEmpty(nextVideo)) {
      dispatch(setCurrentVideo(nextVideo))
    }
  }

  useEffect(() => {
    setId(getId(currentVideo.url))
    setEnded(false)
  }, [currentVideo])

  useEffect(() => {
    if (id) {
      setLoading(true)
      const loader = new Loader()
      const scriptSrc = `https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=${id}`
      loader.require([scriptSrc], () => {
        // Callback
        setLoading(false)
        // Add event listener
        const streamElement = document.getElementsByTagName("stream")[0]
        streamElement.addEventListener("ended", handleEnd)
      })
    }
  }, [id])

  const handlePlay = () => {
    setEnded(false)
  }

  const handleEnd = () => {
    if (currentVideo.final) {
      setEnded(false)
      dispatch(completeVideo())
      setRating(true)
    } else {
      setEnded(true)
      // Mark video as completed
      if (!currentVideo.preview) {
        dispatch(completeVideo())
      }
    }
  }

  function renderStreamTag(id, poster) {
    return {
      __html: `<stream src="${id}" poster="${poster}" controls></stream>`,
    }
  }

  const hasNextVideo = !!currentVideo?.next_video
  console.log("currentVideo>>>", currentVideo)

  return (
    <div
      id="video"
      className={`bg-chocolate w-full h-175 sm:h-auto ${
        !fluid ? "md:h-650" : "md:h-full"
      } overflow-hidden rounded-t-xl relative`}
    >
      {loading && (
        <div className="flex items-center justify-center w-full h-full">
          <Loading></Loading>
        </div>
      )}
      {/* <ContainerDimensions>
        {({ width, height }) => {
          return (
            
            ></div>
          )
        }}
      </ContainerDimensions> */}
      <div
        id="video_wrapper"
        dangerouslySetInnerHTML={renderStreamTag(id, currentVideo.placeholder)}
      />
      {ended && hasNextVideo && !currentVideo.preview && (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="bg-chocolate inset-0 w-full h-full z-10 absolute" />
          <div className="w-48 relative z-10">
            <button className="btn btn-primary mb-4" onClick={handlePlay}>
              Volver a reproducir
            </button>
            <button className="btn btn-primary" onClick={handleNextVideo}>
              Siguiente video
            </button>
          </div>
        </div>
      )}
      {isRating && (
        <Rating setRating={setRating} selectCourseId={currentVideo.id} />
      )}
    </div>
  )
}

export default CourseVideo
