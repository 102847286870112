import React, { useState, useEffect } from "react"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import Loading from "./../share/Loading"
import { GET_BILLING } from "@queries"
import { useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import dayjs from "dayjs"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"
import { Link } from "gatsby"

const History = () => {
  const [history, setHistory] = useState({})
  const { type } = useSelector(state => state.user.type)
  const { id } = useSelector(state => state.user)

  const [data, isFetching] = useAsyncGraphQL(GET_BILLING(id), id)

  useEffect(() => {
    if (!isEmpty(data)) {
      console.log("type>>>", type)
      setHistory(type === "user" ? data.user.orders : data.user.payments)
    }
  }, [data])

  console.log("data???", data)
  console.log("historyhistory>>>>", history)

  if (isFetching) {
    return <Loading />
  }

  return (
    <div className="w-full mx-auto mb-8">
      <span className="text-3xl md:text-4xl leading-tight font-semibold">
        Historial de pago
      </span>
      <div className="mt-6">
        <div className="flex flex-row justify-between lg:justify-start pl-2 pr-6 text-xl font-semibold">
          <span className="lg:w-1/2 inline-block">Historial</span>
          <span className="lg:w-1/5 inline-block">Fecha</span>
          <span className="lg:w-1/5 inline-block">Precio</span>
          <span className="hidden lg:flex w-1/5 lg:inline-block">facturas</span>
        </div>
        {/* <div className="flex flex-col bg-orange-200 py-6 px-4 font-semibold text-xl mt-6">
          <div className="flex flex-row text-base lg:text-xl justify-between lg:justify-start">
            <div className="flex flex-row lg:w-3/5 items-center">
              <span>3 cursos</span>
              <FontAwesomeIcon icon={faAngleUp} className="text-2xl ml-2" />
            </div>
            <div className="lg:w-1/5">
              <span>22/03/19</span>
            </div>
            <div className="lg:w-1/5">
              <span>$47.97</span>
            </div>
            <div className="hidden lg:flex w-1/5">
              <button className="bg-primary text-white px-4 rounded-lg text-xl font-semibold">
                Factura
              </button>
            </div>
          </div>
          <div className="flex flex-col mt-6">
            <div className="flex flex-row bg-white justify-between items-center pr-6 rounded-xl h-16 overflow-hidden">
              <img
                src={Fake}
                alt=""
                className="w-12 h-full md:w-24 object-cover"
              />
              <div className="flex flex-col lg:flex-row w-full justify-between text-sm lg:text-xl ml-4">
                <span className="font-normal leading-tight">
                  Repostería para principiantes
                </span>
                <span className="font-bold block ml-auto mr-0">$15.99</span>
              </div>
            </div>
          </div>
        </div> */}
        <Accordion allowMultipleExpanded={false} className="">
          <div className="">
            {history.map((order, index) => (
              <AccordionItem key={index} className="">
                <div className="flex flex-col mb-4">
                  <AccordionItemHeading className="">
                    <AccordionItemButton className="flex flex-row-reverse justify-between items-center text-lg py-2 font-medium rounded-xl text-black px-4 accordion__button">
                      <div
                        className="flex flex-row h-16 items-center w-full border-b font-semibold text-xl justify-between mr-4"
                        key={index}
                      >
                        <div className="flex flex-row lg:w-3/5 items-center">
                          <span className="text-base leading-none">
                            {order.details.length} cursos
                          </span>
                          {/* <FontAwesomeIcon
                            icon={faAngleDown}
                            className="text-xl ml-2"
                          /> */}
                        </div>
                        <div className="lg:w-1/5">
                          <span className="text-base">
                            {dayjs(order.created_at).format("DD/MM/YY")}
                          </span>
                        </div>
                        <div className="lg:w-1/5">
                          <span className="text-base">${order.total}</span>
                        </div>
                        <div
                          className="hidden lg:flex w-1/5 mr-4"
                          onClick={e => {
                            e.stopPropagation()
                          }}
                        >
                          <Link to={`mi-perfil/factura/${order.id}`}>
                            <button className="lg:flex rounded-lg border-primary border-2 bg-white px-4 text-primary text-xl font-semibold  mt-4 lg:mt-0">
                              Factura
                            </button>
                          </Link>
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel key={index}>
                    <div className="flex flex-col mt-6">
                      {order.details.map((detail, index) => (
                        <div
                          className="flex flex-row bg-white justify-between items-center pr-6 rounded-xl h-20 overflow-hidden mb-4 w-10/12 mx-auto shadow-card"
                          key={index}
                        >
                          <img
                            src={detail.course?.image}
                            alt=""
                            className="w-12 h-full md:w-24 object-cover"
                          />
                          <div className="flex flex-col lg:flex-row w-full justify-between text-sm lg:text-xl ml-4">
                            <span className="font-normal leading-tight">
                              {detail.description}
                            </span>
                            <span className="font-bold block ml-auto mr-0">
                              ${detail.total}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionItemPanel>
                </div>
              </AccordionItem>
            ))}
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default History
