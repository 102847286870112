import { SET_ANSWERS, SET_ANSWER } from "@constants"

export function setAnswers(answers) {
  return function(dispatch) {
    dispatch({
      type: SET_ANSWERS,
      payload: answers,
    })
  }
}

export function setAnswer(answer) {
  return function(dispatch) {
    dispatch({
      type: SET_ANSWER,
      payload: answer,
    })
  }
}
