import React, { useState, useEffect } from "react"
import Http from "@modules/Http"
import { useSelector } from "react-redux"
import has from "lodash/has"
import { BeatLoader } from "react-spinners"
import Select from "react-select"
import isEmpty from "lodash/isEmpty"
import ErrorLabel from "@share/ErrorLabel"
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "1px solid #ef7625" : "1px solid #9ae6b4",
    height: "48px",
    borderRadius: "16px",
    boxShadow: "0 0 2px 0 rgba(50, 20, 0, 0.4)",
  }),
}
const InformationBank = () => {
  const user = useSelector(state => state.user)
  const [data, setData] = useState({
    document_type: "legal_id",
  })
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const options = [
    { value: "legal_id", label: "Cédula" },
    { value: "passport", label: "Pasaporte" },
    { value: "ruc", label: "RUC" },
  ]
  useEffect(() => {
    if (!isEmpty(user.bank_accounts)) {
      setData({
        ...user.bank_accounts,
      })
    }
  }, [user])
  async function updateBankAccount() {
    try {
      setIsLoading(true)
      await Http.post("/bank_account", data)
      setIsLoading(false)
    } catch (errors) {
      setIsLoading(false)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }
  function handleChange(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }
  function handleProfileInputDocument(event) {
    setData({
      ...data,
      document_type: event.value,
    })
  }
  // if (isFetching) {
  // return <Loading />
  // }
  return (
    <div className="w-full mx-auto mb-8">
      <span className="text-3xl md:text-4xl font-semibold leading-tight">
        Información Bancaria
      </span>
      <div className="bg-orange-200 rounded-lg p-8 mt-4 mb-6 text-xl">
        <span>
          Esta información la utilizará Comuni para relizar las transferencias
          relacionadas a los pagos de los cursos
        </span>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col">
          <div className="rounded-xl w-full flex flex-col justify-center">
            <div className="flex flex-col w-full mb-4 lg:mb-6">
              <div className="grid grid-columns-1 grid-gap-4">
                <div className="flex flex-col">
                  <span className="text-sm lg:text-xl mb-2">
                    Nombre del banco
                  </span>
                  <input
                    type="text"
                    name="bank_name"
                    value={data.bank_name}
                    onChange={handleChange}
                    // placeholder="Banco del Pacífico"
                    className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
                  />
                  <ErrorLabel
                    className="mt-2"
                    errors={errors}
                    name="bank_name"
                  />
                </div>
                <div className="flex flex-col ">
                  <span className="text-sm lg:text-xl mb-2">
                    Número de cuenta bancaria
                  </span>
                  <input
                    type="text"
                    value={data.account_number}
                    name="account_number"
                    onChange={handleChange}
                    // placeholder="120231212"
                    className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
                  />
                  <ErrorLabel
                    className="mt-2"
                    errors={errors}
                    name="account_number"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full mb-4 lg:mb-6">
              <div className="grid grid-columns-2 grid-gap-4">
                <div className="flex flex-col">
                  <span className="text-sm lg:text-xl mb-2">
                    Nombre relacionado
                  </span>
                  <input
                    type="text"
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                    // placeholder="Mariam"
                    className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
                  />
                  <ErrorLabel className="mt-2" errors={errors} name="name" />
                </div>
                <div className="flex flex-col ">
                  <span className="text-sm lg:text-xl mb-2">
                    Correo Electrónico
                  </span>
                  <input
                    type="text"
                    value={data.email}
                    name="email"
                    onChange={handleChange}
                    // placeholder="mariam@comuni.ec"
                    className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
                  />
                  <ErrorLabel className="mt-2" errors={errors} name="email" />
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <span className="text-sm lg:text-xl block mb-2">
                Documento de Identidad
              </span>
              <div className="grid grid-columns-2 grid-gap-4">
                <div className="flex flex-col">
                  <Select
                    name="document_type"
                    options={options}
                    className="doc-selector"
                    style={customStyles}
                    value={options.filter(
                      option => option.value === data.document_type
                    )}
                    onChange={handleProfileInputDocument}
                  />
                  <ErrorLabel
                    className="mt-2"
                    errors={errors}
                    name="document_type"
                  />
                </div>
                <div className="flex flex-col justify-end">
                  <input
                    type="text"
                    value={data.dni}
                    name="dni"
                    onChange={handleChange}
                    // placeholder="Cédula"
                    className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
                  />
                  <ErrorLabel className="mt-2" errors={errors} name="dni" />
                </div>
              </div>
            </div>
            <div className="flex mt-4">
              {isLoading ? (
                <button className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase">
                  <BeatLoader color="white" />
                </button>
              ) : (
                <button
                  onClick={updateBankAccount}
                  className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase"
                >
                  Guardar cambios
                </button>
              )}
              {/* <button className="md:text-xl text-primary ml-8">Cancelar</button> */}
            </div>
          </div>
        </div>
        {/* {data &&
 data.user.bank_accounts.map((account, index) => (
 <AccordionItem key={index} className="">
 <div
 className="flex flex-col py-4 text-xl border-t"
 key={index}
 >
 <AccordionItemHeading className="">
 <AccordionItemButton className="flex flex-row-reverse justify-between items-center text-lg py-2 font-medium rounded-xl text-black px-4 accordion__button">
 <div className="flex flex-row justify-between items-center">
 <span className="font-semibold">
 {account.bank_name}
 </span>
 <FontAwesomeIcon icon={faAngleUp} className="mr-2" />
 </div>
 </AccordionItemButton>
 </AccordionItemHeading>
 <div>
 <AccordionItemPanel key={index}>
 <div className="flex flex-col mt-8">
 <div className="rounded-xl w-full flex flex-col items-center justify-center lg:p-8">
 <div className="flex flex-col w-full mb-4 lg:mb-6">
 <div className="flex flex-row justify-between">
 <div className="flex flex-col w-full lg:w-1/2 lg:pr-4">
 <span className="text-sm lg:text-xl mb-2">
 Número de cuenta bancaria
 </span>
 <input
 type="text"
 placeholder="mariam"
 value={account.account_number}
 className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini"
 />
 </div>
 </div>
 </div>
 <div className="flex flex-col w-full mb-4 lg:mb-6">
 <div className="grid grid-columns-2 grid-gap-8">
 <div className="flex flex-col">
 <span className="text-sm lg:text-xl mb-2">
 Nombre relacionado
 </span>
 <input
 type="text"
 placeholder="mariam"
 value={account.name}
 className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
 />
 </div>
 <div className="flex flex-col ">
 <span className="text-sm lg:text-xl mb-2">
 Correo Electrónico
 </span>
 <input
 type="text"
 value={account.email}
 placeholder="mariam"
 className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
 />
 </div>
 </div>
 </div>
 <div className="flex flex-col w-full">
 <span className="text-sm lg:text-xl block mb-2">
 Documento de Identidad
 </span>
 <div className="grid grid-columns-2 grid-gap-8">
 <div className="flex flex-col">
 <input
 type="text"
 placeholder="mariam"
 value={account.document_type}
 className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
 />
 </div>
 <div className="flex flex-col justify-end">
 <input
 type="text"
 value={account.dni}
 placeholder="mariam"
 className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini w-full"
 />
 </div>
 </div>
 </div>
 </div>
 </div>
 </AccordionItemPanel>
 </div>
 </div>
 </AccordionItem>
 ))} */}
      </div>
    </div>
  )
}
export default InformationBank
