import React, { useState } from "react"
import Http from "@modules/Http"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { logInUser } from "@actions/session"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"
import has from "lodash/has"
import { Link } from "@reach/router"
import ErrorLabel from "@share/ErrorLabel"
import { syncCart } from "./../../actions/cart"

const FirstStep = ({ setcurrent }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({
    names: "",
    surnames: "",
    email: "",
    password: "",
    terms_and_conditions: false,
  })
  async function register() {
    try {
      setIsLoading(true)
      await Http.post("/client/register", data)
      await dispatch(logInUser(data))
      dispatch(syncCart())
      setIsLoading(false)
      setcurrent(false)
    } catch (error) {
      setIsLoading(false)
      if (has(error, "response.data.errors")) {
        setErrors(error.response.data.errors)
      }
      console.error("error", error)
    }
  }
  function handleInput(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }
  function handleCheckbox() {
    setData({
      ...data,
      terms_and_conditions: !data.terms_and_conditions,
    })
  }
  return (
    <div className="flex flex-col ">
      <div className="flex flex-col items-center mb-6">
        <span className="font-bold text-xl md:text-2xl">
          Únete a nuestra comunidad
        </span>
        <span className="font-semibold text-lg md:text-xl w-3/4 text-center md:w-auto">
          Aprende a tú ritmo lo que te apasiona
          <FontAwesomeIcon
            icon={faHeart}
            className="text-primary text-base ml-2"
          />
        </span>
      </div>
      <form
        autocomplete="off"
        method="post"
        action=""
        className="flex flex-col mt-4 px-6 md:px-0"
      >
        <div className="flex flex-col md:flex-row justify-between">
          <input
            autocomplete="false"
            name="hidden"
            type="text"
            style={{ display: "none" }}
          />
          <div className="flex flex-col w-full md:w-1/2 mb-4 md:mb-0">
            <input
              type="text"
              name="names"
              placeholder="Nombre"
              onChange={handleInput}
              className="input h-12"
            />
            <ErrorLabel className="mt-2" errors={errors} name="names" />
          </div>
          <div className="w-8" />
          <div className="flex flex-col w-full md:w-1/2">
            <input
              type="text"
              name="surnames"
              onChange={handleInput}
              placeholder="Apellidos"
              className="input h-12"
            />
            <ErrorLabel className="mt-2" errors={errors} name="surnames" />
          </div>
        </div>
        <input
          type="text"
          name="email"
          onChange={handleInput}
          placeholder="Correo electrónico"
          className="input h-12 mt-4"
        />
        <ErrorLabel className="mt-2" errors={errors} name="email" />
        <input
          type="password"
          name="password"
          onChange={handleInput}
          className="input h-12 mt-4"
          placeholder="Contraseña"
        />
        <ErrorLabel className="mt-2" errors={errors} name="password" />
        <div className="text-center w-3/5 flex justify-center text-sm mt-4 text-gray-600 flex-col mx-auto">
          <label class="md:flex justify-center">
            <input
              id="terms"
              name="terms_and_conditions"
              onChange={handleCheckbox}
              type="checkbox"
              class="form-checkbox appearance-none md:mt-1 mr-2 md:mr-0"
            />
            <span>
              Declaro que he leído y acepto la nueva &nbsp;
              <Link to="/politicas" className="text-primary">
                Política de Privacidad
              </Link>
              &nbsp;y&nbsp;
              <Link to="/politicas" className="text-primary">
                los Términos y Condiciones de Comuni.
              </Link>
            </span>
          </label>
          <div className="flex items-center justify-center">
            <ErrorLabel
              className="mt-2"
              errors={errors}
              name="terms_and_conditions"
            />
          </div>
        </div>
        <div className="w-full mx-auto mt-4 text-center">
          {isLoading ? (
            <button
              className="bg-primary text-white h-12 w-64 px-8 rounded-xl text-xl"
              type="button"
            >
              <BeatLoader color="white" />
            </button>
          ) : (
            <button
              className="bg-primary text-white h-12 w-64 px-8 rounded-xl text-xl"
              onClick={register}
              type="button"
            >
              Continuar
            </button>
          )}
          <div className="w-full">
            <span className="inline-block text-center mt-4 w-full">
              ¿Ya tienes una cuenta?&nbsp;
              <Link
                to="/"
                state={{ openModal: true }}
                className="text-primary no-underline font-semibold"
              >
                Inicia sesión
              </Link>
            </span>
          </div>
        </div>
      </form>
    </div>
  )
}
export default FirstStep
