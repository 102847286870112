import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAsterisk, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import Http from "@modules/Http"
import { CREATE_QUESTION } from "@mutations"
import { addQuestion } from "@actions/questions"
import useValidateObject from "@hooks/useValidateObject"

import ErrorLabel from "@share/ErrorLabel"

const initialData = {
  text: "",
  title: "",
}

const QUESTION_DATA = [
  "id",
  "text",
  "title",
  "created_at",
  "human_created_at",
  "total_answers",
  {
    answers: [
      "id",
      "text",
      "created_at",
      "human_created_at",
      "question_id",
      {
        user: [
          "id",
          "names",
          "surnames",
          "full_name",
          {
            profile: ["image", "degree"],
          },
        ],
      },
    ],
  },
  {
    user: [
      "id",
      "full_name",
      {
        profile: ["image"],
      },
    ],
  },
]

const ModalQuestion = props => {
  const dispatch = useDispatch()
  const [data, setData] = useState(initialData)
  const [errors, setErrors] = useState({})
  const [isValid, validationErrors] = useValidateObject(data, {
    text: "required|max:500",
    title: "required|max:150",
  })

  function handleChange({ target }) {
    // setErrors(validationErrors)
    const { name, value } = target
    setData({
      ...data,
      [name]: value,
    })
  }

  async function handleSubmit() {
    if (isValid) {
      const args = {
        ...data,
        course_id: props.course.id,
      }
      try {
        const response = await Http.mutation(
          CREATE_QUESTION(args, QUESTION_DATA)
        )
        dispatch(addQuestion(response.data.addQuestion))
        setData(initialData)
        props.handleClose()
      } catch (error) {
        console.error(error)
      }
    } else {
      setErrors(validationErrors)
    }
  }

  return (
    <div className="min-w-modal z-40 bg-white h-full md:h-auto md:rounded-xl flex flex-row overflow-hidden w-full md:w-2/6 lg:w-5/12">
      <div className="flex flex-col">
        <div className="relative">
          <span
            className="absolute top-0 right-0 mr-4"
            onClick={props.handleClose}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="text-xl m-4 text-gray-10"
            />
          </span>
        </div>
        <div className="flex flex-col flex-1 justify-center p-8">
          <span className="text-2xl text-chocolate font-semibold mb-3">
            Realiza una pregunta
          </span>
          <input
            type="text"
            name="title"
            id="title"
            value={data.title}
            onChange={handleChange}
            placeholder="Titulo de la pregunta"
            className="border rounded-lg h-10 px-4"
          />
          <ErrorLabel className="mt-2" errors={errors} name="title" />
          <textarea
            name="text"
            id="text"
            value={data.text}
            onChange={handleChange}
            placeholder="Describe tu pregunta"
            className="border rounded-lg h-32 mt-4 p-2"
          />
          <ErrorLabel className="mt-2" errors={errors} name="text" />
          <div className="flex flex-row mt-4 justify-start">
            <div className="w-40 mr-4">
              <button
                className="btn btn-primary btn--small"
                onClick={handleSubmit}
              >
                Enviar pregunta
              </button>
            </div>
            <button
              className="bg-white text-primary p-2"
              onClick={props.handleClose}
            >
              Cancelar
            </button>
          </div>
        </div>
        <div className="bg-pink-10 w-full px-8 py-6">
          <span className="block mb-2 color-chocolate">Recuerda que…</span>
          <div className="flex flex-row items-start mb-2">
            <FontAwesomeIcon icon={faAsterisk} className="mr-4 mt-2 text-sm" />
            <p className="text-sm leading-normal">
              Puede haber otros estudiantes que ya hayan hecho la misma
              pregunta. Por ello, realiza una búsqueda rápida para asegurarse de
              que su pregunta sea única.
            </p>
          </div>
          <div className="flex flex-row items-start">
            <FontAwesomeIcon icon={faAsterisk} className="mr-4 mt-2 text-sm" />
            <p className="text-sm leading-normal">
              La mejor manera de obtener las respuestas que está buscando es
              aprender a hacer buenas preguntas.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalQuestion
