import React, { useState, useEffect } from "react"
import Swiper from "react-id-swiper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import defaults from "lodash/defaults"
const Slider = ({
  slidesPerView,
  spaceBetween,
  pagination,
  params,
  children,
  arrows = true,
}) => {
  // Swiper instance
  const [swiper, updateSwiper] = useState(null)
  // Slides current index
  const [currentIndex, updateCurrentIndex] = useState(0)
  // Params definition
  const defaultParams = {
    slidesPerView: slidesPerView || 3,
    spaceBetween: spaceBetween || 0,
    shouldSwiperUpdate: true,
    noSwiping: false,
    pagination: pagination || {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
      620: {
        slidesPerView: 1,
      },
    },
    getSwiper: updateSwiper,
  }
  const config = defaults(params, defaultParams)
  // Manipulate swiper from outside
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev()
    }
  }
  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (swiper !== null) {
      swiper.on("slideChange", () => {
        updateCurrentIndex(swiper.realIndex)
      })
    }
    return () => {
      if (swiper !== null) {
        swiper.off("slideChange", () => updateCurrentIndex(swiper.realIndex))
      }
    }
  }, [swiper])
  const isBeginning = swiper ? swiper.isBeginning : false
  const isEnd = swiper ? swiper.isEnd : false

  return (
    <div className="relative h-full">
      <Swiper {...config}>{children}</Swiper>
      {arrows && (
        <button
          className={`slider-button slider-button--left`}
          disabled={isBeginning}
          onClick={goPrev}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="button-prev" />
        </button>
      )}
      {arrows && (
        <button
          className="slider-button slider-button--right"
          disabled={isEnd}
          onClick={goNext}
        >
          <FontAwesomeIcon icon={faAngleRight} className="button-next" />
        </button>
      )}
      <div className="swiper-pagination" />
    </div>
  )
}
export default Slider
