import React from "react"
const tabClasses =
  "outline-none focus:outline-none hover:text-primary block py-2"
const tabActiveClasses =
  "outline-none focus:outline-none hover:text-primary block text-primary py-2"
const tabMovilClasses =
  "outline-none focus:outline-none block px-4 h-full bg-white text-chocolate shadow-lg px-3 truncate mr-6 rounded-lg font-semibold text-base"
const tabMovilActiveClasses =
  "outline-none focus:outline-none block px-4 h-full bg-primary text-white shadow-lg px-3 truncate mr-6 rounded-lg font-semibold text-base"
const TabLinks = ({ handleClick, currentTab, type }) => (
  <div className="md:mr-16 overflow-hidden md:overflow-visible pr-6">
    <div className="hidden lg:flex flex-col rounded-lg shadow-mini border bg-white p-4 text-xl w-64 items-start">
      <button
        className={currentTab === 0 ? tabActiveClasses : tabClasses}
        onClick={() => handleClick(0)}
      >
        Términos y condiciones
      </button>
      <div>
        <button
          className={currentTab === 1 ? tabActiveClasses : tabClasses}
          onClick={() => handleClick(1)}
        >
          Políticas de Privacidad
        </button>
      </div>
    </div>
  </div>
)
export default TabLinks
