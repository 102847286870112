import React, { useState, useEffect } from "react"
import Http from "@modules/Http"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import Loading from "./Loading"
import InterestCard from "../Register/InterestCard"
const ModalInterest = props => {
  const [isLoading, setIsLoading] = useState(true)
  const [interests, setInterests] = useState([])
  const [selectedInterests, setSelectedInterests] = useState(
    props.selectedInterests
  )
  useEffect(() => {
    getInterest()
  }, [])
  async function getInterest() {
    try {
      const { data: authResponse } = await Http.get("/interest")
      setInterests(authResponse.data)
      setIsLoading(false)
    } catch (error) {
      console.log("error", error)
    }
  }
  function handleClick(interest) {
    const isInArray = selectedInterests.some(({ id }) => id === interest.id)
    if (isInArray) {
      const selectedFiltered = selectedInterests.filter(
        ({ id }) => id !== interest.id
      )
      return setSelectedInterests(selectedFiltered)
    }
    setSelectedInterests([...selectedInterests, interest])
  }
  async function sendInterest() {
    setIsLoading(true)
    const data = selectedInterests.map(interest => {
      return interest.id
    })
    await Http.post("/interest/client", { interests: data })
    props.setUserInterests(selectedInterests)
    setIsLoading(false)
    props.setShowInterest()
  }
  return (
    <div className="fixed inset-0 w-screen h-screen flex items-center justify-center z-20">
      <div className="min-w-modal max-w-3xl w-full z-100 bg-white h-full md:h-auto md:rounded-xl flex flex-row overflow-hidden relative">
        {!isLoading ? (
          <div className="flex flex-col justify-center w-full md:p-8">
            <div className="md:relative">
              <span className="text-chocolate font-semibold text-3xl inline-block w-full text-center">
                Tus áreas de interés
              </span>
              <button
                className="absolute p-4 md:p-0 md:-mt-4 text-xl text-gray-500 top-0 right-0"
                onClick={props.setShowInterest}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            {interests && (
              <div className="flex flex-row flex-wrap w-full mt-8 justify-around grid grid-columns-2 md:grid-columns-3 ">
                {interests.map((interest, index) => (
                  <InterestCard
                    interest={interest}
                    key={index}
                    handleClick={handleClick}
                    selectedInterests={selectedInterests}
                  />
                ))}
              </div>
            )}
            <div className="w-48 mx-auto flex justify-center items-center mt-8">
              <button
                className="btn btn-primary"
                onClick={sendInterest}
                disabled={selectedInterests.length < 1}
              >
                Guardar cambios
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <Loading />
          </div>
        )}
      </div>
      <div
        className="absolute inset-0 w-full h-full z-30 bg-chocolate opacity-25"
        onClick={props.setShowInterest}
      />
    </div>
  )
}
export default ModalInterest
