import { SET_QUESTION, UPDATE_QUESTION } from "@constants"

export function setQuestion(question) {
  return function(dispatch) {
    dispatch({
      type: SET_QUESTION,
      payload: question,
    })
  }
}

export function updateQuestion(question) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_QUESTION,
      payload: question,
    })
  }
}
