import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { setQuestion, updateQuestion } from "@actions/question"
import { setAnswer } from "@actions/answers"
import ReactAvatar from "react-avatar"
import useValidateObject from "@hooks/useValidateObject"
import Http from "@modules/Http"
import { CREATE_ANSWER } from "@queries"
import ErrorLabel from "@share/ErrorLabel"
import { BeatLoader } from "react-spinners"
import has from "lodash/has"
import CreateMarkup from "./../../helpers/CreateMarkup"

import Loading from "./../share/Loading"
import Avatar from "./Avatar"
import Answer from "./Answer"

const ANSWER_DATA = [
  "id",
  "text",
  "created_at",
  "human_created_at",
  "question_id",
  {
    user: [
      "id",
      "names",
      "surnames",
      "full_name",
      {
        profile: ["image", "degree"],
      },
    ],
  },
]

const QuestionOpen = ({ id }) => {
  const dispatch = useDispatch()
  const session = useSelector(state => state.session)
  const user = useSelector(state => state.user)
  const question = useSelector(state => state.question)
  const questions = useSelector(state => state.questions)
  const answers = useSelector(state => state.answers)
  const [isLoading, setIsLoading] = useState(false)

  // State
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({
    text: "",
  })
  const [isValid, validationErrors] = useValidateObject(data, {
    text: "required|max:500",
  })

  useEffect(() => {
    if (isEmpty(question)) {
      const q = questions.find(item => item.id === parseInt(id))
      dispatch(setQuestion(q))
    }
  }, [])

  if (isEmpty(question)) {
    return <Loading />
  }

  const questionAnswers = answers.filter(
    answer => answer.question_id === question.id
  )
  const send = async () => {
    setIsLoading(true)
    const args = {
      ...data,
      question_id: question.id,
    }
    try {
      const response = await Http.mutation(
        CREATE_ANSWER(args, ANSWER_DATA)
      ).finally(() => setIsLoading(false))
      dispatch(setAnswer(response.data.addAnswer))
      dispatch(
        updateQuestion({
          ...question,
          total_answers: question.total_answers + 1,
        })
      )
      setData({
        text: "",
      })
    } catch (error) {
      console.error(error)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }

  const handleKeyPress = async ({ key }) => {
    if (key === "Enter") {
      if (isValid) {
        send()
      } else {
        setErrors(validationErrors)
      }
    }
  }

  const handleChange = ({ target }) => {
    setData({
      text: target.value,
    })
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row mb-4">
        <Avatar image={question.user.profile.image} />
        <div>
          <h5 className="text-base md:text-xl font-medium leading-tight">
            {question.title}
          </h5>
          <p className="hidden md:flex text-xs md:text-base font-medium">
            {question.user.full_name} - {question.human_created_at}
          </p>
        </div>
      </div>
      <div
        className="mb-6"
        dangerouslySetInnerHTML={CreateMarkup(question.text)}
      ></div>
      {questionAnswers.map(answer => (
        <Answer answer={answer} key={answer.id} />
      ))}
      {session && (
        <div className="flex my-8">
          <ReactAvatar
            name={user.full_name}
            round={true}
            size={"3rem"}
            className="mr-3 md:mr-6"
          />
          <div className="flex-1">
            <input
              type="text"
              value={data.text}
              onChange={handleChange}
              // onKeyPress={handleKeyPress}
              placeholder="Responder"
              className="md:flex-1 block md:mr-8 rounded-xl px-4 md:px-6 font-medium w-full h-12 border bg-input-light border-gray-10"
            />

            <ErrorLabel className="mt-2" errors={errors} name="text" />
          </div>
          <div className="md:w-32 md:mt-0 ml-6">
            {isLoading ? (
              <button className="btn btn-primary h-12">
                <BeatLoader color="white" />
              </button>
            ) : (
              <button className="btn btn-primary h-12" onClick={send}>
                Enviar
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default QuestionOpen
