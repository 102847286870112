import React from "react"
import StarRatings from "react-star-ratings"
import Score from "./Score"
import take from "lodash/take"

const ScoreAndComments = ({ course }) => {
  function parseScore() {
    let score = []
    for (let index = 1; index <= 5; index++) {
      const currentScores = course.scores.filter(item => item.score === index)
      const percent = (100 * currentScores.length) / course.scores.length
      score.push(Math.round(percent))
    }
    return score
  }
  const scoreRanking = parseScore()

  let num = 6

  return (
    <div>
      <span className="section-title block md:mb-4">
        Valoraciones & comentarios
      </span>
      <div className="flex flex-col md:flex-row mb-10 items-center">
        <div className="flex items-center md:items-center md:flex-col w-full md:w-40 md:justify-center">
          <span className="text-5xl md:text-6xl font-semibold mr-6 md:mr-0">
            {course.average_score}
          </span>
          <StarRatings
            rating={course.average_score}
            starSpacing="15px"
            starRatedColor="rgb(245, 202, 35)"
            starDimension="24px"
            starSpacing="0px"
          />
        </div>
        <div className="flex flex-col w-full flex-1">
          {scoreRanking.reverse().map(score => {
            num = num - 1
            return (
              <div className="flex flex-row w-full items-center">
                <div className="mr-12">
                  <StarRatings
                    rating={num}
                    starSpacing="12px"
                    starRatedColor="rgb(245, 202, 35)"
                    starDimension="16px"
                    starSpacing="0px"
                  />
                </div>

                <span className="w-12 block text-center">{score} %</span>
                <div className="rounded-lg bg-gray-35 h-2 md:h-4 flex-1">
                  <div
                    className="bg-primary h-full rounded-lg"
                    style={{ width: `${score}%` }}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div>
        {take(course.scores, 3).map(score => (
          <Score score={score} key={score.id} />
        ))}
      </div>
    </div>
  )
}

export default ScoreAndComments
