import React, { useState } from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch } from "react-instantsearch-dom"
import SearchBox from "./Search/SearchBox"
import Hits from "./Search/Hits"
import isEmpty from "lodash/isEmpty"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SECRET
)

const SearchAutocomplete = ({ setIsOpenMenuSearch }) => {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])

  // Has results
  const hasResults = !isEmpty(query) && results.length > 0

  return (
    <div
      className={`w-full relative z-50 ${hasResults &&
        "shadow-primary rounded-t-xl"}`}
    >
      <InstantSearch indexName="courses_index" searchClient={searchClient}>
        <SearchBox
          setQuery={setQuery}
          setIsOpenMenuSearch={setIsOpenMenuSearch}
          query={query}
          hasResults={hasResults}
        />
        {!isEmpty(query) && (
          <div className="absolute w-full mt-0 left-0 shadow-primary overflow-hidden rounded-b-xl">
            <Hits
              setResults={setResults}
              setIsOpenMenuSearch={setIsOpenMenuSearch}
            />
          </div>
        )}
      </InstantSearch>
    </div>
  )
}

export default SearchAutocomplete
