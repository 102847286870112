import { SET_CURRENT_VIDEO } from "@constants"

export function setCurrentVideo(video) {
  return function(dispatch) {
    dispatch({
      type: SET_CURRENT_VIDEO,
      payload: video,
    })
  }
}
