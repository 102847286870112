import React, { useState, useEffect } from "react"
import { GET_ORDER, GET_PAYMENT } from "@queries"
import Loading from "./../share/Loading"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import Http from "@modules/Http"

const Billing = props => {
  const type = useSelector(state => state.user.type)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    if (type && type.type) {
      getData(type.type)
    }
  }, [type])

  async function getData(type) {
    try {
      const response = await Http.graphql(
        type === "tutor" ? GET_PAYMENT(props.id) : GET_ORDER(props.id)
      )
      setData(type === "tutor" ? response.data.payment : response.data.order)
      setIsFetching(false)
    } catch (error) {
      console.error(error)
    }
  }

  function getType(type) {
    switch (type) {
      case "organic":
        return "Orgánico"
      default:
        return "Referido"
    }
  }

  if (isFetching) {
    return <Loading />
  }

  return (
    <section className="py-4 md:pb-12 container px-4 xs:px-0 lg:px-12 mx-auto justify-between">
      <span className="text-4xl font-semibold md:mb-6 inline-block">
        Facturación
      </span>
      <div className="flex flex-col md:flex-row border-b pb-4">
        <div className="flex flex-col md:w-1/2">
          <span>Comuni</span>
          <span>Aqui va la direccion de comuni</span>
          <span>Guayaquil, Ecuador</span>
          <span className="text-primary">www.comuni.com</span>
        </div>
        <div className="flex flex-col md:w-1/2 md:items-end md:justify-end">
          <span>Fecha: {dayjs(data.created_at).format("DD  MMMM YYYY")} </span>
          <span>Numero de orden: {data.id}</span>
        </div>
      </div>
      {data.names && (
        <div className="h-16 border-b flex items-center">
          <span>Cursos vendidos a {data.names}</span>
        </div>
      )}
      <div className="">
        <div className="flex flex-row text-xl py-2 md:pt-3">
          <span className="w-3/5 inline-block">Item</span>
          <span className="w-1/5 inline-block text-right hidden md:inline-block">
            Fecha de compra
          </span>
          <span className="w-1/5 inline-block text-right">
            {type.type === "tutor" ? "Tipo" : "Código"}
          </span>
          <span className="w-1/5 inline-block text-right">Precio</span>
        </div>
        {data?.details.map(item => (
          <div className="w-full flex flex-row h-10 items-center">
            <span className="w-3/5 inline-block">{item.description}</span>
            <span className="w-1/5 inline-block text-right hidden md:inline-block">
              {dayjs(item.created_at).format("DD  MMMM YYYY")}
            </span>
            <span className="w-1/5 inline-block text-right">
              {type.type === "tutor" ? getType(item.type) : "Código"}
            </span>
            <span className="w-1/5 inline-block text-right">${item.total}</span>
          </div>
        ))}

        <div className="flex flex-row justify-end font-bold mt-4 md:text-xl">
          <span className="mr-4">Total</span>
          <span>${data.total}</span>
        </div>
      </div>
      <div>
        {/* <button className="rounded-semi bg-primary px-8 text-white text-lg font-semibold">
          Generar pdf
        </button> */}
      </div>
    </section>
  )
}

export default Billing
