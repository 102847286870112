import { SET_USER, UPDATE_USER } from "@constants"
import Http from "@modules/Http"
import { GET_COURSES } from "@queries"

export function setUser(user) {
  return function(dispatch) {
    dispatch({
      type: SET_USER,
      payload: user,
    })
    return
  }
}

export function updateUser(data) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_USER,
      payload: data,
    })
    return
  }
}

/**
 * This is an example for graphql query on redux
 */
export function getUsers() {
  return async function(dispatch) {
    const { data } = await Http.graphql(GET_COURSES())
    console.log({ data })
    //dispatch()
    return
  }
}
