import React, { useEffect } from "react"
import { connectHits } from "react-instantsearch-dom"
import { navigate } from "@reach/router"

const Hits = ({ hits, setResults, setIsOpenMenuSearch }) => {
  useEffect(() => {
    setResults(hits)
  }, [hits])

  return (
    <ol className="w-full max-h-250 h-auto overflow-x-scroll rounded-b-xl list-borders">
      {hits.map(hit => (
        <li
          role="option"
          className="bg-white px-4 border-b py-2 cursor-pointer w-full hover:bg-pink-10"
          key={hit.objectID}
          onClick={() => {
            if(setIsOpenMenuSearch) {
              setIsOpenMenuSearch(false)
            }
            navigate(`/cursos/${hit.id}`)
          }}
        >
          <div>
            <span class="block leading-none font-semibold">{hit.name}</span>
            <small class="leading-none">{hit.teacher}</small>
          </div>
        </li>
      ))}
    </ol>
  )
}

export default connectHits(Hits)
