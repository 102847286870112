import React, { useState, useEffect } from "react"
import StarRatings from "react-star-ratings"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import { GET_RATING } from "@queries"
import Http from "@modules/Http"
import { BeatLoader } from "react-spinners"
import Loading from "./../share/Loading"
import has from "lodash/has"
import ErrorLabel from "@share/ErrorLabel"

const ModalRating = ({ setRating, selectCourseId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [newComment, setNewComment] = useState("")
  const [newRating, setIsNewRating] = useState(0)
  const [errors, setErrors] = useState({})
  const [data, isFetching] = useAsyncGraphQL(
    GET_RATING(selectCourseId),
    selectCourseId
  )
  useEffect(() => {
    setIsNewRating(data?.course?.user_score?.score)
  }, [data])

  async function createRating() {
    try {
      setIsLoading(true)
      await Http.post("/score", {
        score: newRating,
        comment: newComment,
        course_id: selectCourseId,
      })
      setErrors({})
      setIsLoading(false)
      setRating(false)
    } catch (errors) {
      setIsLoading(false)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }
  async function updateRatings() {
    try {
      setIsLoading(true)
      await Http.patch(`/score/${data.course.user_score.id}`, {
        score: newRating,
        comment: newComment,
      })
      setErrors({})
      setIsLoading(false)
      setRating(false)
    } catch (errors) {
      setIsLoading(false)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }
  function changeRating(newRating, name) {
    console.log("newRating>>>", newRating)

    setIsNewRating(newRating)
  }
  function changeComment(event) {
    console.log("event>>>", event.target.value)

    setNewComment(event.target.value)
  }
  if (isFetching) {
    return <Loading />
  }
  console.log("newRating>>>>", data)
  return (
    <div className="fixed pin w-screen h-screen flex items-center justify-center z-100 top-0">
      <div className="min-w-modal rounded z-40 bg-white rounded-lg flex flex-row overflow-hidden shadow-second p-8 w-650">
        <div className="w-full flex flex-col items-center">
          <span className="text-xl font-semibold">
            ¿Qué valoración le darías a este curso?
          </span>
          <span className="text-base mt-2">¡Muy bueno!</span>
          <div className="flex items-center justify-center my-6 w-3/6 mx-auto">
            <StarRatings
              starDimension="40px"
              rating={newRating}
              starRatedColor="rgba(239, 118, 37, 1)"
              changeRating={changeRating}
              numberOfStars={5}
              name="rating"
            />
          </div>
          <textarea
            name=""
            id=""
            placeholder="¿Te gustó el curso? Cuéntanos un poco de cómo te pareció."
            cols="30"
            rows="10"
            onChange={changeComment}
            className="rounded-lg h-24 w-full border outline-none p-4 "
          />
          <ErrorLabel className="mt-2" errors={errors} name="comment" />
          <div className="flex flex-row w-1/2 items-center mt-4">
            {isLoading ? (
              <button className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase">
                <BeatLoader color="white" />
              </button>
            ) : (
              <button
                onClick={
                  data?.course?.user_score?.score ? updateRatings : createRating
                }
                className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase"
              >
                Guardar
              </button>
            )}

            <button
              className="p-4 ml-4 text-xl text-primary"
              onClick={() => setRating(false)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
      <div
        className="absolute pin w-full h-full bg-chocolate opacity-25 hidden md:block"
        onClick={() => setRating(false)}
      />
    </div>
  )
}
export default ModalRating
