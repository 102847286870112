import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart, faTimes } from "@fortawesome/free-solid-svg-icons"
import { logInUser } from "@actions/session"
import { navigate, Link } from "@reach/router"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"
import has from "lodash/has"
import ErrorLabel from "@share/ErrorLabel"
import isEmpty from "lodash/isEmpty"
import { syncCart } from "@actions/cart"

const Login = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  async function getUser() {
    try {
      setIsLoading(true)
      const { type } = await dispatch(logInUser(data))
      // Sync shopping cart
      dispatch(syncCart())

      setIsLoading(false)

      // Check if there is any intended url
      const intendedUrl = sessionStorage.getItem("intendedUrl")
      // Redirect to users previous url
      if (!isEmpty(intendedUrl)) {
        sessionStorage.removeItem(intendedUrl)
        return navigate(intendedUrl)
      }

      if (type.type === "user") {
        return navigate("/estudiante")
      }
      return navigate("/tutores")
    } catch (error) {
      setIsLoading(false)
      if (has(error, "response.data.message")) {
        setErrors({
          invalid: [error.response.data.message],
        })
      }
    }
  }

  function goToRegister() {
    navigate("/registro")
  }

  function goToRecovery() {
    navigate("/recuperar-clave")
  }

  function handleInput(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      getUser()
    }
  }

  return (
    <div className="h-screen flex justify-center items-center bg-yellow-10 -mt-16 md:mt-0">
      <div className="min-w-modal rounded z-100 h-full md:h-auto md:rounded-semi flex flex-row overflow-hidden shadow-lg">
        <div className="flex flex-col justify-center p-10 bg-white relative">
          <div className="flex flex-col items-center">
            <Link to="/">
              <span className="absolute top-0 right-0 font-semibold text-gray-500 inline-block text-right text-xl cursor-pointer mx-4 mt-1">
                <FontAwesomeIcon
                  icon={faTimes}
                  className="text-gray-400 text-base"
                />
              </span>
            </Link>
            <span className="text-center text-2xl font-bold">
              Inicia sesión en tu cuenta Comuni
            </span>
            <span className="text-center text-xl font-semibold">
              Sigue aprendiendo lo que te apasiona
              <FontAwesomeIcon
                icon={faHeart}
                className=" ml-2 text-primary text-base"
              />
            </span>
          </div>
          <div className="flex flex-col mt-8 justify-between">
            <input
              type="email"
              name="email"
              className="input h-12"
              placeholder="Correo electrónico"
              onChange={handleInput}
            />
            <ErrorLabel className="mt-1" errors={errors} name="email" />
            <input
              type="password"
              name="password"
              className="input h-12 mt-2 md:mt-4"
              placeholder="Contraseña"
              onChange={handleInput}
              onKeyPress={handleKeyPress}
            />
            <ErrorLabel className="mt-1" errors={errors} name="password" />
            <ErrorLabel className="mt-1" errors={errors} name="invalid" />
            <div className="flex justify-center w-full mt-4 mx-auto">
              {isLoading ? (
                <button className="btn btn-primary h-12 uppercase">
                  <BeatLoader color="white" />
                </button>
              ) : (
                <button
                  className="btn btn-primary h-12"
                  onClick={() => getUser()}
                >
                  Iniciar Sesión
                </button>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center mt-4">
            <div
              onClick={goToRecovery}
              className="cursor-pointer hover:text-primary text-base"
            >
              ¿Hás olvidado tu contraseña?
            </div>
            <div className="mt-8 flex flex-row items-center text-lg">
              <span className="cursor-pointer">¿No tienes una cuenta?</span>
              <div
                className="ml-1 text-primary cursor-pointer font-semibold"
                onClick={goToRegister}
              >
                Regístrate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Login
