import React from "react"
import Guide1 from "../../images/icon-guide1.svg"
import Guide2 from "../../images/icon-guide2.svg"
import Guide3 from "../../images/icon-guide3.svg"
import Guide4 from "../../images/icon-guide4.svg"

const Hack = () => (
  <section className="container px-4 md:px-12 mx-auto my-10 pb-10 text-chocolate">
    <div className="flex flex-col">
      <span className="text-xl md:text-4xl font-semibold">
        ¿Cómo usar Comuni?
      </span>
      <p className="inline-block my-6">
        Ofrece la mejor atención al estudiante para recibir valoraciones
        positivas.
      </p>
      <div className="w-full mx-auto flex justify-center">
        <div className="flex flex-col justify-between grid md:grid-columns-1 lg:grid-columns-2 grid-gap-8">
          <div className="flex flex-row items-center relative">
            <div className="flex justify-center items-center w-24 md:w-28 h-24 md:h-28 bg-pink-10 rounded-full z-1 absolute">
              <img src={Guide1} alt="aaa" className="w-16 md:w-18" />
            </div>
            <div className="flex flex-col shadow-mini p-4 rounded-lg w-full ml-16 justify-end items-end bg-white">
              <div className="w-11/12 lg:w-11/13">
                <span className="text-sm font-semibold md:text-xl">
                  Servicio al Estudiante
                </span>
                <p className="text-xs md:text-base">
                  El respeto y el profesionalismo van de la mano. Si notas un
                  comportamiento inadecuado, notifícalo y nosotros tomaremos
                  acciones.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center relative">
            <div className="flex justify-center items-center w-24 md:w-28 h-24 md:h-28 bg-pink-10 rounded-full z-1 absolute">
              <img src={Guide2} alt="aaa" className="w-16 md:w-18" />
            </div>
            <div className="flex flex-col shadow-mini p-4 rounded-lg w-full ml-16 justify-end items-end bg-white">
              <div className="w-11/12 lg:w-11/13">
                <span className="text-sm font-semibold md:text-xl">
                  Apoyo & Recursos
                </span>
                <p className="text-xs md:text-base">
                  El material de apoyo descargable es esencial para el
                  aprendizaje. Si haz actualizado o cuentas con contenido
                  relevante, notifícanos para incluirlo en el material del
                  curso.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center relative">
            <div className="flex justify-center items-center w-24 md:w-28 h-24 md:h-28 bg-pink-10 rounded-full z-1 absolute">
              <img src={Guide3} alt="aaa" className="w-16 md:w-18" />
            </div>
            <div className="flex flex-col shadow-mini p-4 rounded-lg w-full ml-16 justify-end items-end bg-white">
              <div className="w-11/12 lg:w-11/13">
                <span className="text-sm font-semibold md:text-xl">
                  Responder Inquietudes
                </span>
                <p className="text-xs md:text-base">
                  Responde pronto y utiliza un estilo de comunicación asertivo
                  y/o constructivo. ¡Interactúa con el usuario aportando valor!
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center relative">
            <div className="flex justify-center items-center w-24 md:w-28 h-24 md:h-28 bg-pink-10 rounded-full z-1 absolute">
              <img src={Guide4} alt="aaa" className="w-16 md:w-18" />
            </div>
            <div className="flex flex-col shadow-mini p-4 rounded-lg w-full ml-16 justify-end items-end bg-white">
              <div className="w-11/12 lg:w-11/13">
                <span className="text-sm font-semibold md:text-xl">
                  Impulsar Ideas
                </span>
                <p className="text-xs md:text-base">
                  Motiva a tus alumnos a compartir sus ideas o trabajos.
                  Muéstrales tu interés emitiendo críticas constructivas o
                  compartiendo en tus redes, dándole crédito a su trabajo.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col mt-10 pt-6">
      <span className="text-xl md:text-4xl font-semibold mb-4">
        ¿Qué son las valoraciones?
      </span>
      <p className="w-3/4">
        Es la puntuación que cada estudiante le da al curso, siento 5 estrellas
        la mejor puntuación y 1 la peor. Si tu curso tiene más valoraciones
        positivas, hay mayor probabilidad que más usuarios lo compren.
      </p>
    </div>
    <div className="flex flex-col mt-10 pt-6">
      <span className="text-xl md:text-4xl font-semibold mb-4">
        ¿Cómo obtener mejores valoraciones?
      </span>
      <p className="w-3/4">
        Sigue los puntos descritos anteriormente para cultivar una relación
        positiva con el alumno. Recuerda que nuestro objetivo es conseguir que
        el alumno alcance su meta, aprender.
      </p>
    </div>
  </section>
)

export default Hack
