import { SET_QUESTION } from "../constants"
import initialState from "./initialState"

function question(state = initialState.question, action) {
  switch (action.type) {
    case SET_QUESTION:
      return { ...action.payload }
    default:
      return state
  }
}

export default question
