import React from "react"
import Slider from "./../Slider"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle, faPlay } from "@fortawesome/free-solid-svg-icons"
import take from "lodash/take"
import isEmpty from "lodash/isEmpty"

const MeetToTutor = ({ tutores }) => {
  return (
    <Slider pagination={null} params={{ breakpoints: {} }} slidesPerView={1}>
      {tutores &&
        tutores.map(tutor => (
          <div className="flex flex-col justify-center items-center lg:mt-18">
            <div className="flex flex-col lg:flex-row w-full md:w-3/4 max-w-2xl items-center justify-center mb-8">
              <img
                src={tutor?.popular_teacher?.profile?.image || ""}
                alt=""
                className="h-56 w-56 rounded-full bg-gradient lg:-mr-24 z-1 p-2"
              />
              <div className="bg-white p-4 flex flex-col shadow-lg text-2xl lg:text-3xl p-4 lg:p-10 rounded-xl leading-snug md:self-start lg:pl-32 text-center lg:text-left mt-4 lg:mt-0 flex-1">
                <span className="font-bold">
                  {tutor?.popular_teacher?.full_name}
                </span>
                <span className="leading-tight mb-2 block">
                  {tutor?.popular_teacher?.profile.degree}
                </span>
                <span className="trunc text-xl leading-snug">
                  {tutor?.popular_teacher?.profile.description}
                </span>
              </div>
            </div>
            <div className="md:flex justify-center items-center mt-10 w-full max-w-4xl hidden">
              <div className="flex flex-col w-full text-2xl lg:text-3xl rounded-xl self-center text-white">
                <span className="font-bold text-chocolate mb-6">
                  CURSOS POPULARES
                </span>
                {take(tutor.popular_teacher?.courses, 2).map((course, i) => (
                  <div
                    className="bg-white flex flex-col lg:flex-row justify-between items-center border-b p-4 rounded-lg text-chocolate mb-2"
                    key={i}
                  >
                    <div className="flex flex-row items-center text-lg lg:text-xl">
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        className="text-chocolate mr-2"
                      />
                      <span>{course.name}</span>
                    </div>
                    <div className="flex flex-row items-center text-sm lg:text-lg">
                      <FontAwesomeIcon
                        icon={faPlay}
                        className="text-chocolate mr-2"
                      />
                      <span className="mr-4 ">
                        {course.contents.length} clases
                      </span>
                      <FontAwesomeIcon
                        icon={faClock}
                        className="text-chocolate mr-2"
                      />
                      <span>{course.duration}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
    </Slider>
  )
}
export default MeetToTutor
