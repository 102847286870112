import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import Diploma from "../../images//icon-diploma-gradient.svg"
import { GET_CERTIFICATE } from "@queries"
import Loading from "./../share/Loading"
import { useSelector } from "react-redux"
import { Link } from "@reach/router"
import { useAsyncGraphQL } from "@hooks/useGraphQL"

const Certifications = () => {
  const { id } = useSelector(state => state.user)
  const [data, isFetching] = useAsyncGraphQL(GET_CERTIFICATE(id), id)
  console.log("data???", data)

  const [certifications, setCertifications] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [selectCertification, setSelectCertification] = useState({})

  if (isFetching) {
    return <Loading />
  }
  function showCertification(course) {
    setIsOpen(true)
    setSelectCertification(course)
  }
  return (
    <div>
      {isOpen ? (
        <div className="w-full mx-auto mb-8">
          <div className="flex flex-col">
            <span className="text-4xl font-semibold">Mis Certificaciones</span>
            <span className="text-2xl lg:text-4xl mt-4">
              Curso: {selectCertification.name}
            </span>
            <p className="text-base lg:text-xl mt-2">
              El certificado del curso que se le muestra a continuación verifica
              que {` ${data.user.names} ${data.user.surnames}`} ha completado el
              curso “{selectCertification.name}”
            </p>
          </div>
          <div className="bg-gray-200 h-21 my-8" />
          <button className="btn btn-primary flex-1 text-sm md:w-50 md:flex-none uppercase">
            Descargar
          </button>
        </div>
      ) : data.user.certifications.length <= 0 ? (
        <div className="w-full mx-auto mb-8">
          <span className="text-4xl font-semibold">Mis Certificaciones</span>
          <div className="bg-orange-200 rounded-xl w-full flex flex-col items-center justify-center p-8 mt-4">
            <img src={Diploma} alt="" className="mb-4" />
            <span className="md:text-xl text-center mb-4">
              Aún no tienes ninguna certificación. Las certificaciones se
              obtienen al finalizar un curso. ¡¿Qué esperas?!
            </span>
            <Link
              className="btn btn-primary flex-1 text-sm  md:w-50 md:flex-none uppercase text-center"
              to="/estudiante"
            >
              Explorar cursos
            </Link>
          </div>
        </div>
      ) : (
        <div className="w-full mx-auto">
          <span className="text-4xl font-semibold block mb-4">
            Mis Certificaciones
          </span>
          <div className="grid md:grid-columns-2 grid-gap-8">
            {data.user.certifications.map((certification, index) => (
              <div
                className="border rounded-lg p-2 md:p-4 mb-4 bg-white"
                key={index}
              >
                <div className="bg-orange-200 rounded-lg flex items-center justify-center h-32 mb-2">
                  <img src={Diploma} alt="" className="block" />
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold md:text-xl mb-2 md:w-21">
                    {certification.course.name}
                  </p>
                  <span className="md:text-xl flex items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-green-500 text-base mr-2"
                    />
                    Curso completado
                  </span>
                  <div className="grid grid-columns-2 mt-4 grid-gap-2">
                    <button
                      className="btn btn-primary text-xs uppercase md:text-sm btn--small"
                      onClick={() => showCertification(certification.course)}
                    >
                      Ver certificado
                    </button>
                    <Link
                      className="btn btn-outline-primary text-xs uppercase md:text-sm btn--small text-center"
                      to={`/cursos/${certification.course.id}`}
                    >
                      Ir al curso
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Certifications
