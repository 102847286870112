import React from "react"

const Modal = props => {
  if (!props.show) {
    return <></>
  }

  return (
    <div className="fixed inset-0 w-screen h-screen flex items-center justify-center z-20">
      <div
        className="absolute pin w-full h-full z-30 bg-chocolate opacity-25"
        onClick={props.handleClose}
      />
      {props.children}
    </div>
  )
}

export default Modal
