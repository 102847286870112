import React from "react"

const Terms = () => {
  return (
    <div className="w-full mx-auto mb-6 flex flex-col">
      <span className="text-2xl font-bold mb-4">Políticas de Privacidad</span>
      <span className="text-xl font-bold my-4">Datos personales</span>
      <p>
        El servicio Web de COMUNI® almacena y procesa los datos personales que
        el usuario ha facilitado voluntariamente al registrarse a la plataforma
        WEB. Dicha información del formulario incluye, su dirección de correo
        electrónica, un nombre y apellido y una contraseña. Además, de manera
        voluntaria y opcional podrá proporcionar, una foto de perfil, el país de
        residencia, número telefónico, documento de identidad, título
        profesional y descripción. En paralelo, recopilamos datos de uso de cada
        usuario, los cuales incluyen: contenido compartido dentro de la
        plataforma, datos de los cursos (comportamiento y uso del producto,
        comentarios y proyectos creados), datos de pago de los estudiantes (si
        el usuario realiza compras, recopilamos algunos datos sobre las compras.
      </p>
      <p className="mt-2">
        Es responsabilidad del usuario mantener seguro tanto sus teléfonos
        móviles y ordenadores o tablets, de donde comúnmente tenga el acceso a
        su perfil dentro de COMUNI®. Además, el usuario expresamente autoriza a
        COMUNI® a utilizar la información de las facturas que se ingresan por
        intermedio del servicio web, para fines estadísticos, pudiendo COMUNI®
        elaborar bases de datos con dicha información, y el usuario expresamente
        autoriza a COMUNI® a utilizar en la forma que estime conveniente,
        inclusive comercializarla, toda la información estadística que genere
        producto de la documentación electrónica que sea ingresada a través del
        servicio Web. El usuario no tendrá nada que reclamar a COMUNI® por el
        uso que le dé a la información antes señalada que se ingrese vía el
        servicio web de COMUNI®, sin embargo, se obliga COMUNI® a usar la
        información para fines estadísticos, comerciales y lícitos, a los que se
        encuentra debidamente autorizado por el usuario al aceptar estos
        términos y condiciones que aquí se señalan.
      </p>
      <p className="mt-2">
        Por motivos de seguridad, COMUNI no recopila ni almacena datos
        confidenciales del titular de la tarjeta, como todos los números de la
        tarjeta de crédito o los datos de autenticación de la tarjeta), datos de
        los instructores, datos sobre las cuentas del usuario en otros servicios
        (podremos obtener determinada información a través a través de las redes
        sociales u otras cuentas en línea del usuario si están conectadas a su
        cuenta de COMUNI), sorteos, promociones y encuestas, y por último,
        comunicaciones y asistencia. 
      </p>
      <p className="mt-2">
        Todos estos datos los obtenemos a través de distintos tipos de cookies,
        herramientas de recopilación de datos, análisis de perfiles y publicidad
        en línea.
      </p>
      <span className="text-xl font-bold my-4">Cookies y otra información</span>
      <p>
        Utilizamos la dirección IP del usuario para identificar problemas,
        administrar el Sitio Web e identificar patrones de comportamiento del
        tráfico. También se utilizan cookies o tecnología similar para poder
        ofrecerle contenido acorde a sus intereses y para almacenar su
        contraseña y así el Usuario no tenga que ingresarla nuevamente cada vez
        que regresa al Sitio Web.
      </p>
      <span className="text-xl font-bold my-4">Contacto</span>
      <p>
        Ante cualquier duda, queja o comentario con respecto a las Políticas de
        Privacidad de COMUNI, envíenos un correo a info@comuni.ec.{" "}
      </p>
      <span className="text-xl font-bold my-4">
        Preferencias de comunicaciones y de suscripciones
      </span>
      <p>
        Para dejar de recibir nuestras promociones y noticias, haz click en la
        parte inferior (de cada correo electrónico), o escríbenos
        a info@comuni.ec.
      </p>
    </div>
  )
}
export default Terms
