import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlayCircle,
  faCheckCircle,
  // faPlay,
} from "@fortawesome/free-solid-svg-icons"
import { faFile } from "@fortawesome/free-regular-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { setCurrentVideo } from "@actions/currentVideo"

// import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import DownloadButton from "./../DownloadButton"

const ContentSection = ({ content }) => {
  // const duration = `${content.duration}`
  const totalClasses = content.videos.length
  const dispatch = useDispatch()
  const currentVideo = useSelector(state => state.currentVideo)
  // const percentage = content.completed_percent

  function handleClick(video) {
    dispatch(setCurrentVideo(video))
    const elmnt = document.getElementById("video")
    elmnt.scrollIntoView()
  }

  return (
    <div className="flex mb-12">
      {/* {percentage > 0 ? (
          <div className="hidden w-20 h-20 rounded-full md:flex items-center justify-center bg-gray-10 text-gray-10 mr-8">
          <CircularProgressbar
          className="w-full h-full"
          value={percentage}
          maxValue={100}
          background="#e2e2e2"
          text={`${percentage}%`}
          styles={buildStyles({
            textSize: "22px",
            pathColor: "#ef7625",
            textColor: "#000000",
            trailColor: "#e2e2e2",
            backgroundColor: "white",
          })}
          />
          </div>
          ) : (
            <div className="hidden w-20 h-20 rounded-full md:flex items-center justify-center border-gray-10 text-gray-10 mr-8 border-6">
            <FontAwesomeIcon icon={faPlay} className="text-3xl" />
            </div>
          )} */}
      <div className="md:flex-1 w-full">
        <div className="flex flex-col">
          <span className="text-xl md:text-3xl text-chocolate font-semibold mb-2">
            {content.name}
          </span>
          {/* <p className="md:text-lg">{content.description}</p> */}
        </div>
        <details>
          <summary className="flex flex-row-reverse justify-between items-center text-lg py-2 font-medium text-black outline-none border-b cursor-pointer ">
            {/* <div className="flex justify-between border-b h-16 w-full"> */}
            <div className="flex justify-between items-center text-primary">
              {/* <FontAwesomeIcon icon={faAngleDown} className="mr-2" /> */}
              <span className="text-lg">{totalClasses} clases</span>
            </div>
            {/* <span className="text-chocolate text-lg font-semibold flex items-center justify-center mr-4"> */}
            {/* {duration} */}
            {/* </span> */}
            {/* </div> */}
          </summary>
          {content.videos
            .filter(video => !video.preview)
            .map((video, index) => {
              return (
                <div
                  className="flex flex-row h-12 items-center justify-between cursor-pointer md:mr-14"
                  key={index}
                  onClick={() => handleClick(video)}
                >
                  <div className="flex flex-row items-center">
                    {video.completed ? (
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-4" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faPlayCircle}
                        className="mr-4 opacity-25"
                      />
                    )}
                    <span
                      className={`truncate w-32 md:w-auto md:text-lg md:break-normal ${
                        currentVideo?.id === video.id
                          ? "text-primary font-semibold"
                          : ""
                      }`}
                    >
                      {video.title}
                    </span>
                  </div>
                  <div>
                    <span>{video.duration}</span>
                  </div>
                </div>
              )
            })}
          {content.documents.map(document => {
            return (
              <div
                className="flex flex-row h-12 items-center justify-between cursor-pointer md:mr-14"
                key={document.id}
              >
                <div className="flex flex-row items-center">
                  <FontAwesomeIcon icon={faFile} className="mr-4" />
                  <p className="truncate w-32 md:w-auto md:text-lg md:break-normal">
                    {document.title}
                  </p>
                </div>
                <div className="flex flex-row">
                  <a href={document.url} target="_blanck">
                    <DownloadButton className="hidden lg:flex" />
                  </a>
                </div>
              </div>
            )
          })}
        </details>
      </div>
    </div>
  )
}
export default ContentSection
