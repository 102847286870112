import initialState from "./initialState"
import { SHOW_CHECKOUT_MODAL, HIDE_CHECKOUT_MODAL } from "./../constants"

function checkoutModal(state = initialState.checkoutModal, action) {
  switch (action.type) {
    case SHOW_CHECKOUT_MODAL:
      return true
    case HIDE_CHECKOUT_MODAL:
      return false
    default:
      return state
  }
}

export default checkoutModal
