import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import take from "lodash/take"
import StarRatings from "react-star-ratings"
import { navigate } from "gatsby"
import truncate from "lodash/truncate"
import { removeFromCart } from "@actions/cart"
import { useDispatch } from "react-redux"

String.prototype.trimEllip = function(length) {
  return this.length > length ? this.substring(0, length) + "..." : this
}

const MiniCourseCard = ({ course }) => {
  const students = course.students ? take(course.students, 4) : []
  const dispatch = useDispatch()

  function getGoToCourse() {
    navigate(`/cursos/${course.id}`)
  }

  function remove() {
    dispatch(removeFromCart(course.id))
  }

  return (
    <div
      className="rounded-xl shadow-mini w-full mx-auto relative flex overflow-hidden bg-white h-24 mb-2"
      onClick={getGoToCourse}
    >
      <button
        className="w-6 h-6 absolute top-0 right-0 mx-1 my-2"
        onClick={e => {
          e.stopPropagation()
          remove()
        }}
      >
        <FontAwesomeIcon icon={faTimes} className="text-gray-400 mr-2" />
      </button>
      <div className="bg-black relative h-full w-28">
        <img
          src={course.image}
          alt="algo"
          className="h-full absolute top-0 left-0 w-full object-cover"
        />
      </div>
      <div className="flex flex-col text-lg flex-1 px-4 py-2 justify-between">
        <div className="flex flex-col justify-around">
          <h2 className="font-sans text-lg font-semibold leading-tight block mb-1">
            {course.name.trimEllip(20)}
          </h2>
          <span className="text-sm md:text-base leading-tight">
            {course.teacher.full_name}
          </span>
          <StarRatings
            rating={course.average_score}
            starSpacing="13px"
            starRatedColor="rgb(245, 202, 35)"
            starDimension="16px"
            starSpacing="0px"
          />
        </div>
        <div className="flex flex-row justify-between items-end">
          {course.discount ? (
            <div className="flex flex-col text-right absolute bottom-0 right-0 px-4 py-1">
              <span className="text-base line-through -mb-2 h-6">
                ${course.price}
              </span>
              <span className="text-2xl md:text-3xl font-bold text-primary">
                ${course.discount}
              </span>
            </div>
          ) : (
            <div className="flex flex-col text-right absolute bottom-0 right-0 px-4 py-1">
              <span className="text-2xl md:text-3xl font-bold text-primary">
                ${course.price}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default MiniCourseCard
