import React, { useEffect, useState } from "react"
import "@scss/main.scss"
import SvgIcon from "./svgIcon"
import Header from "../components/share/header"
import SEO from "../components/seo"
import Footer from "../components/share/footer"
import Login from "./Home/Login"
import { useDispatch, useSelector } from "react-redux"
import { currentUser } from "../actions/session"
import { Location } from "@reach/router"
const Layout = props => {
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  const session = useSelector(state => state.session)
  useEffect(() => {
    if (session) {
      dispatch(currentUser())
    }
  }, [])
  useEffect(() => {
    const { location } = props
    const { state } = location
    if (state && state.openModal) {
      setShowModal(true)
    }
  }, [props.location])
  return (
    <div className={props.className}>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <SvgIcon />
      <Location>
        {({ location }) => {
          return (
            <Header
              setShowModal={setShowModal}
              location={location}
              session={session}
            />
          )
        }}
      </Location>
      {showModal && <Login setShowModal={setShowModal} />}
      <div className="mt-16 md:mt-0">{props.children}</div>
      <Location>
        {({ location }) => {
          return <Footer location={location} session={session} />
        }}
      </Location>
    </div>
  )
}
export default Layout
