import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faPlay } from "@fortawesome/free-solid-svg-icons"
import ContentShowCase from "./ContentShowCase"

const ShowCaseSection = ({ course }) => {
  return (
    <div>
      <div className="md:flex md:justify-between">
        <span className="section-title block mb-4">Contenido del curso</span>
        <div className="mb-4 flex items-center">
          <div className="flex items-center mr-4">
            <FontAwesomeIcon icon={faPlay} className="mr-2 text-sm" />
            <span className="text-lg">{course.total_classes}</span>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faClock} className="mr-2 text-sm" />
            <span className="text-lg">{course.duration}</span>
          </div>
        </div>
      </div>
      <div>
        {course.contents.map((content, index) => (
          <ContentShowCase key={index} content={content} />
        ))}
      </div>
    </div>
  )
}

export default ShowCaseSection
