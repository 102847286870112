import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { clearErrorMessage } from "@actions/errorMessage"
import { hideCheckoutModal } from "@actions/checkoutModal"

const BuySuccessful = () => {
  const dispatch = useDispatch()
  const errorMessage = useSelector(state => state.errorMessage)

  function handleClose() {
    dispatch(clearErrorMessage())
    dispatch(hideCheckoutModal())
  }

  return (
    <div className="fixed pin w-screen h-screen flex items-center justify-center z-100 top-0">
      <div className="min-w-modal rounded z-100 h-full md:h-auto md:rounded-semi flex flex-row overflow-hidden">
        <div className="flex flex-col justify-center p-10 bg-white relative max-w-lg">
          <div className="flex flex-col items-center">
            <span
              className="absolute top-0 right-0 font-semibold text-gray-500 inline-block text-right text-xl cursor-pointer mx-4 mt-1"
              onClick={handleClose}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="text-gray-400 text-base"
              />
            </span>
          </div>
          <div className="flex flex-col items-center">
            <div className="rounded-full h-20 w-20 bg-red-600 flex items-center justify-center">
              <FontAwesomeIcon icon={faTimes} className="text-white text-4xl" />
            </div>
            <span className="text-2xl text-chocolate font-bold text-center my-4 leading-snug">
              Ha habido un problema al realizar tu pago
            </span>
            <span className="text-xl text-center text-gray-600">
              {errorMessage}
            </span>
            <div className="mt-6 flex justify-center w-auto">
              <button
                onClick={handleClose}
                className="btn btn-primary btn--small"
              >
                Volver a intentarlo
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute pin w-full h-full bg-chocolate opacity-25 hidden md:block"
        onClick={handleClose}
      />
    </div>
  )
}
export default BuySuccessful
