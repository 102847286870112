import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import Http from "../../modules/Http"
import InterestCard from "./InterestCard"
import { BeatLoader } from "react-spinners"

const SecondStep = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [interests, setInterests] = useState([])
  const [selectedInterests, setSelectedInterests] = useState([])

  useEffect(() => {
    getInterest()
  }, [])

  async function getInterest() {
    try {
      setIsLoading(true)
      const { data: authResponse } = await Http.get("/interest")
      setIsLoading(false)
      setInterests(authResponse.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  // segundo renderizarlos
  function handleClick(interest) {
    const isInArray = selectedInterests.some(({ id }) => id === interest.id)
    if (isInArray) {
      const selectedFiltered = selectedInterests.filter(
        ({ id }) => id !== interest.id
      )
      return setSelectedInterests(selectedFiltered)
    }
    setSelectedInterests([...selectedInterests, interest])
  }

  // tercero poder escogerlos
  async function sendInterest() {
    const data = selectedInterests.map(interest => {
      return interest.id
    })
    await Http.post("/interest/client", { interests: data }).then(
      navigate("/estudiante")
    )
  }

  return (
    <div className="flex flex-col items-center">
      <span className="font-bold text-xl md:text-2xl">
        Escoge tus áreas de interés
      </span>
      <span className="text-lg md:text-xl block mb-6">
        Elige al menos 1 categoría
      </span>
      <div>
        {interests && (
          <div className="flex flex-row flex-wrap w-full justify-around grid grid-columns-2 md:grid-columns-3 px-6 md:px-0">
            {interests.map((interest, index) => (
              <InterestCard
                interest={interest}
                key={index}
                handleClick={handleClick}
                selectedInterests={selectedInterests}
              />
            ))}
          </div>
        )}
      </div>
      <div className="w-64 flex items-center justify-center mt-6">
        {isLoading ? (
          <button
            className="btn btn-primary"
            onClick={sendInterest}
            disabled={selectedInterests.length < 1}
          >
            <BeatLoader color="white" />
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={sendInterest}
            disabled={selectedInterests.length < 1}
          >
            Finalizar
          </button>
        )}
      </div>
    </div>
  )
}

export default SecondStep
