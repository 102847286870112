import React from "react"
import { Link } from "@reach/router"

const link = "hover:text-primary mb-4"
const activeLink =
  "hover:text-primary mb-4 text-primary"

const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: activeLink } : { className: link }
}

const Sidebar = props => (
  <div>
    <div className="hidden md:flex flex-col rounded-lg shadow-second border rounded-xl shadow-mini p-8 text-xl mr-10">
      <Link to="terminos-y-condiciones" getProps={isActive} className="hover:text-primary mb-4">
        Términos de Uso
      </Link>
      <Link to="politica-de-privacidad" getProps={isActive} className="hover:text-primary mb-4">
        Política de Privacidad
      </Link>
      <Link to="/"  getProps={isActive}className="hover:text-primary">
        Política de Cookies
      </Link>
    </div>
    <div className="flex md:hidden mb-10 w-full overflow-y-auto">
      <Link
        to="terminos-y-condiciones"
        className="bg-primary px-4 text-white rounded-lg mr-4 w-64"
      >
        Términos de Uso
      </Link>
      <Link
        to="politica-de-privacidad"
        className="bg-primary px-4 text-white rounded-lg mr-4"
      >
        Política de Privacidad
      </Link>
      <Link
        to="/"
        getProps={isActive}
        className="bg-primary px-4 text-white rounded-lg mr-4"
      >
        Política de Cookies
      </Link>
    </div>
  </div>
)

export default Sidebar
