import axios from "axios"
import has from "lodash/has"
import castArray from "lodash/castArray"
import flatten from "lodash/flatten"
import first from "lodash/first"
import isEmpty from "lodash/isEmpty"

const BASE_URL = process.env.GATSBY_API_URL
const GRAPH_QL_BASE_URL = process.env.GATSBY_GRAPH_QL_URL
const GRAPH_QL_BASE_URL_AUTH = `${GRAPH_QL_BASE_URL}/auth`

/**
 * Parse errors to a friendly Object
 * @param {Array} errors
 */
function extractErrors(errors) {
  // errors.*.extensions.validation
  let errorData = castArray(errors)
  errorData = flatten(
    errorData
      .map(({ extensions }) => extensions)
      .map(({ validation }) => validation)
  )

  let objErrors = {}
  errorData.forEach(log => {
    let obj = {}
    for (const key in log) {
      obj[key] = first(log[key])
    }
    objErrors = {
      ...objErrors,
      ...obj,
    }
  })

  return objErrors
}

const Axios = axios.create({
  baseURL: BASE_URL,
})

Axios.interceptors.request.use(config => {
  const token = localStorage.getItem("jwt")
  config.headers["Authorization"] = `Bearer ${token}`
  return config
})

Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem("jwt")
      window.location = "/"
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

Axios.graphql = query => {
  const baseURL = GRAPH_QL_BASE_URL
  return Axios({
    baseURL,
    method: "POST",
    data: {
      query,
    },
  }).then(({ data }) => data)
}

Axios.mutation = mutation => {
  const baseURL = GRAPH_QL_BASE_URL_AUTH
  return Axios({
    baseURL,
    method: "POST",
    data: mutation,
  }).then(({ data }) => {
    // Handle graphql Errors
    if (has(data, "errors")) {
      const errors = extractErrors(data.errors)
      if (isEmpty(errors)) {
        console.error("Error on mutation:", data.errors)
      }
      Promise.reject(errors)
    }
    return data
  })
}

export default Axios
