import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getCourse } from "@actions/course"
import CourseLayout from "./CourseLayout"
import Loading from "./../share/Loading"
import Course from "./Course"
import queryString from "query-string"

const CoursePending = ({ id, children, location }) => {
  const [isFetching, setIsFetching] = useState(true)
  const course = useSelector(state => state.course)
  const [params, setParams] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    getCourseData(id)
  }, [id])

  useEffect(() => {
    if (location.search) {
      setParams(queryString.parse(location.search))
    }
  }, [location])

  async function getCourseData(id) {
    setIsFetching(true)
    try {
      await dispatch(getCourse(id))
      setIsFetching(false)
    } catch (error) {
      console.log("error", error)
    }
  }

  if (isFetching) {
    return <Loading />
  }

  if (!course.purchased) {
    return <Course params={params} />
  }

  return (
    <section className="container px-4 xs:px-0 md:px-12 flex flex-col justify-between mx-auto mt-6 md:mt-12">
      <CourseLayout>{children}</CourseLayout>
    </section>
  )
}
export default CoursePending
