import React, { useState } from "react"
import Terms from "./Terms"
import Cookies from "./Cookies"
import Privacidad from "./Privacidad"
import TabLinks from "./TabLinks"

const Politics = () => {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <section className="mt-4 lg:mt-16 md:mt-24 max-w-5xl px-6 sm:px-0 md:px-0 mx-auto">
      <div className=" flex flex-col lg:flex-row justify-between">
        <TabLinks handleClick={setCurrentTab} currentTab={currentTab} />
        <div className="flex flex-col w-full mb-32">
          {currentTab === 0 && <Terms />}
          {currentTab === 1 && <Privacidad />}
          {currentTab === 2 && <Cookies />}
        </div>
      </div>
    </section>
  )
}
export default Politics
