import React from "react"
import Avatar from "react-avatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faThLarge,
  faAngleRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"
import { logOutUser } from "@actions/session"
import Http from "@modules/Http"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "@reach/router"
const Mobile = ({ setIsOpenMenuMobile, type, fullName }) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  async function logout() {
    await Http.post("/logout")
    window.location = "/"
    dispatch(logOutUser())
  }
  const profileLink =
    type === "user" ? "/estudiante/mi-perfil" : "/tutores/mi-perfil"
  const coursesLik = type === "user" ? "/estudiante" : "/tutores"
  return (
    <div>
      {type === "user" || type === "tutor" ? (
        <div className="w-4/5 z-100 h-screen fixed top-0">
          <div className="flex flex-col z-20 bg-white justify-between h-full shadow-lg relative">
            <div className="flex flex-col">
              <div className="bg-yellow-10 flex flex-row p-8 justify-between">
                <div className="flex flex-row">
                  <Avatar
                    name={user.full_name}
                    src={user.profile.image}
                    round={true}
                    size={"60px"}
                  />
                  <div className="flex flex-col ml-4 flex-1">
                    <span className="font-semibold text-xl text-chocolate">
                      {user.full_name}
                    </span>
                    <Link
                      to={profileLink}
                      onClick={() => setIsOpenMenuMobile(false)}
                      className="flex flex-row font-light text-lg text-primary items-center"
                    >
                      <span className="mr-2">Perfil</span>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                  </div>
                </div>
                <div
                  className="font-bold text-2xl text-gray-500 absolute top-0 right-0 flex items-center justify-center w-8 h-8 pt-2 pr-2"
                  onClick={() => setIsOpenMenuMobile(false)}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-sm" />
                </div>
              </div>
              <div className="flex flex-col p-8 items-start text-chocolate text-lg bg-white">
                <Link
                  to={coursesLik}
                  onClick={() => setIsOpenMenuMobile(false)}
                  className="py-2"
                >
                  Mis cursos
                </Link>
                <button className="py-2">Notificaciones</button>
                {type === "user" ? (
                  <Link
                    to="/carrito-de-compras"
                    onClick={() => setIsOpenMenuMobile(false)}
                    className="py-2"
                  >
                    Carrito de Compras
                  </Link>
                ) : (
                  <span></span>
                )}
                {type === "tutor" && (
                  <Link
                    to="/guia"
                    onClick={() => setIsOpenMenuMobile(false)}
                    className="py-2"
                  >
                    ¿Cómo usar Comuni?
                  </Link>
                )}
                {/* <div className="border-t border-b w-full py-4">
 <div className="flex flex-row justify-between py-2 items-center py-2">
 <div className="flex flex-row items-center">
 <FontAwesomeIcon
 icon={faThLarge}
 className="mr-2 text-primary"
 />
 <span> Categorías</span>
 </div>
 <FontAwesomeIcon
 icon={faAngleRight}
 className="mr-2 text-chocolate"
 />
 </div>
 <button className="py-2">Lives</button>
 </div> */}
              </div>
            </div>
            <div className="flex flex-col p-8 items-start border-r">
              <button className="py-2 text-gray-500 mb-2" onClick={logout}>
                Cerrar sesión
              </button>
              <button className="border-t w-full flex items-start">
                <span className="text-chocolate mr-2 pt-4">
                  ¿Necesitas ayuda?
                </span>
                <span className="text-primary pt-4">Contáctanos</span>
              </button>
            </div>
          </div>
          <div
            className="opacity-50 z-10 h-screen w-screen fixed top-0 bg-gray-800"
            onClick={() => setIsOpenMenuMobile(false)}
          />
        </div>
      ) : (
        <div className="w-4/5 z-100 h-screen fixed top-0">
          <div className="flex flex-col z-20 bg-white justify-between h-full shadow-lg relative">
            <div className="flex flex-col">
              <div className="flex flex-col p-8 items-start text-chocolate text-lg bg-white relative">
                <div
                  className="font-bold text-2xl text-gray-500 absolute top-0 right-0 flex items-center justify-center w-8 h-8 pt-2 pr-2"
                  onClick={() => setIsOpenMenuMobile(false)}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-sm" />
                </div>
                <button className="py-2 font-semibold text-2xl">
                  Categorías
                </button>
                <button className="pt-2 pb-8 font-semibold text-2xl">
                  Lives
                </button>
                <div className="border-t flex flex-col w-full pt-8">
                  <Link
                    to="/"
                    state={{ openModal: true }}
                    onClick={() => setIsOpenMenuMobile(false)}
                    className="py-2 w-full border-2 rounded-lg border-primary bg-white text-primary font-semibold text-center"
                  >
                    Inicia Sesión
                  </Link>
                  <Link
                    to="/registro"
                    onClick={() => setIsOpenMenuMobile(false)}
                    className="py-2 w-full bg-primary rounded-lg text-white font-semibold mt-4 text-center"
                  >
                    Regístrate
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col p-8 items-start border-r">
              <button className="border-t w-full flex items-start">
                <span className="text-chocolate mr-2 pt-4">
                  ¿Necesitas ayuda?
                </span>
                <span className="text-primary pt-4">Contáctanos</span>
              </button>
            </div>
          </div>
          <div
            className="opacity-50 z-10 h-screen w-screen fixed top-0 bg-gray-800"
            onClick={() => setIsOpenMenuMobile(false)}
          />
        </div>
      )}
    </div>
  )
}
export default Mobile
