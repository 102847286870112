import initialState from "./initialState"
import { SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE } from "./../constants"

function errorMessage(state = initialState.errorMessage, action) {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return action.payload
    case CLEAR_ERROR_MESSAGE:
      return ""
    default:
      return state
  }
}

export default errorMessage
