import Http from "@modules/Http"
import { navigate } from "@reach/router"
import store from "./../store"
import { setCart } from "@actions/cart"
import { getCourses } from "@actions/userCourses"
import { setErrorMessage } from "@actions/errorMessage"
import { showCheckoutModal } from "@actions/checkoutModal"

let paymentezCheckout = {}

if (typeof window !== "undefined") {
  paymentezCheckout = new window.PaymentezCheckout.modal({
    client_app_code: "TPP2-EC-CLIENT",
    client_app_key: "sDAwQAdBqetYhVZFFLpOg6FU2cmjF0",
    locale: "es",
    env_mode: "stg",
    onOpen: () => {},
    onClose: () => {},
    onResponse: async response => {
      if (response.error) {
        console.log({
          Error: response.error,
        })
        // Show error modal
      }

      // Get order id
      const { dev_reference } = response.transaction

      if (response.transaction && response.transaction.status === "failure") {
        // Delete order
        Http.delete(`/order/${dev_reference}`)
        // Show error modal
        store.dispatch(setErrorMessage(response.transaction.message))
        store.dispatch(showCheckoutModal())
        return
      }

      try {
        await Http.patch(`/order/${dev_reference}`, response)
        // Clear cart
        store.dispatch(getCourses())
        store.dispatch(setCart([]))
        // Show success modal
        navigate("/estudiante")
      } catch (error) {
        console.error(error)
      }
    },
  })
}

export default paymentezCheckout
