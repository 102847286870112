import { SET_CART, ADD_TO_CART, REMOVE_FROM_CART } from "@constants"
import Http from "@modules/Http"
import has from "lodash/has"
import isEmpty from "lodash/isEmpty"

export function getCart() {
  return async function(dispatch, getState) {
    try {
      const { session } = getState()
      if (session) {
        const { data } = await Http.get("/cart")

        if (has(data, "courses")) {
          dispatch(setCart(data.courses))
        }
      }
    } catch (error) {
      console.error(error.response.data)
    }
  }
}

export function setCart(items) {
  return function(dispatch) {
    dispatch({
      type: SET_CART,
      payload: items,
    })
  }
}

export function addToCart(item, referralId = null) {
  return async function(dispatch, getState) {
    try {
      const { cart, session } = getState()

      if (session) {
        await Http.post("/add_to_cart", {
          course_id: item.id,
          referral_id: referralId,
        })
      }

      const course = cart.find(course => course.id === item.id)

      if (!course) {
        dispatch({
          type: ADD_TO_CART,
          payload: {
            ...item,
            referralId,
          },
        })
      }
    } catch (error) {
      console.error(error.response.data)
    }
  }
}

export function syncCart() {
  return async function(dispatch, getState) {
    try {
      const { cart } = getState()
      if (!isEmpty(cart)) {
        const { data } = await Http.patch("/cart", {
          courses: cart.map(({ id, referralId }) => {
            return {
              [id]: {
                referral_id: referralId,
              },
            }
          }),
        })
        dispatch(setCart(data.courses))
      }
      return Promise.resolve("Ok")
    } catch (error) {
      console.error(error.response.data)
      return Promise.reject(error.response.data)
    }
  }
}

export function removeFromCart(id) {
  return async function(dispatch) {
    try {
      await Http.post("/delete_from_cart", { course_id: id })
      dispatch({
        type: REMOVE_FROM_CART,
        payload: id,
      })
    } catch (error) {
      console.error(error.response.data)
    }
  }
}
