import React from "react"
import SearchMobile from "./../SearchAutocomplete"

const Search = ({ setIsOpenMenuSearch }) => {
  return (
    <div>
      <div className="w-full z-100 h-screen fixed top-0">
        <div className="flex flex-col z-20 bg-white justify-between h-full shadow-lg relative">
          <div className="flex flex-row items-center px-3 py-4">
            <div className="flex-1 mr-4">
              <SearchMobile
                isMobile={true}
                setIsOpenMenuSearch={setIsOpenMenuSearch}
              />
            </div>
            <div className="w-24 text-center">
              <button
                className="text-primary text-lg"
                onClick={() => setIsOpenMenuSearch(false)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
        <div
          className="opacity-50 z-10 h-screen w-screen fixed top-0 bg-gray-800"
          onClick={() => setIsOpenMenuSearch(false)}
        />
      </div>
    </div>
  )
}

export default Search
