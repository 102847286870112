import React from "react"

const CourseQuestions = props => {
  return (
    <div>
      {props.children}
      {/* {!isOpen && (
        <div className="flex flex-col md:flex-row mb-8">
          <input
            type="text"
            placeholder="Buscar pregunta"
            className="md:flex-1 block bg-gray-10 md:mr-8 rounded-xl px-4 md:px-6 font-medium w-full h-12 md:h-auto"
          />
          <div className="md:w-42 mt-4 md:mt-0">
            <button className="btn btn-primary">Preguntar</button>
          </div>
        </div>
      )}
      {isOpen && <QuestionOpen question={questionActive} />}
      {!isOpen &&
        course.questions.map(question => {
          return (
            <Question
              question={question}
              key={question.id}
              handleClick={handleClick}
            />
          )
        })} */}
    </div>
  )
}

export default CourseQuestions
