import React from "react"

String.prototype.trimEllip = function(length) {
  return this.length > length ? this.substring(0, length) + "..." : this
}

const Testimonial = props => {
  return (
    <div className="shadow-lg rounded-xl overflow-hidden md:max-w-95 mx-auto md:w-full bg-white relative md:h-370 w-full">
      <div>
        <img
          src={props.testimonial.image}
          alt=""
          className="w-full object-cover h-200 block"
        />
      </div>
      <div className="p-4">
        <span className="text-base md:text-2xl font-semibold mb-1 block">
          {props.testimonial.name}
        </span>
        <p className="text-sm md:hidden">
          {props.testimonial.description.trimEllip(225)}
        </p>
        <p className="hidden md:block text-lg">
          {props.testimonial.description.trimEllip(135)}
        </p>
      </div>
    </div>
  )
}
export default Testimonial
