import React from "react"
import { useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import { Link } from "@reach/router"
import sum from "lodash/sum"
import numeral from "numeral"

const ShoppingCart = props => {
  const cart = useSelector(state => state.cart)

  if (isEmpty(cart)) {
    return (
      <div className="bg-white w-64 p-4 rounded-lg h-42">
        <div className="flex flex-col items-center justify-between h-full">
          <span className="text-chocolate text-base">
            Tu carrito de compras está vacío :(
          </span>
          <img src={require("./../../images/icon-empty-cart.svg")} alt="" />
          <button className="btn btn-primary w-full">Explorar cursos</button>
        </div>
      </div>
    )
  }

  const total = sum(
    cart.map(course => {
      const price = course.discount ? course.discount : course.price
      return parseFloat(price)
    })
  ).toFixed(2)

  return (
    <div className={cart.length > 0 ? "bg-white w-auto" : "bg-white w-64"}>
      <div style={{ width: "325px" }}>
        <div className="p-4">
          {cart.map(course => {
            return (
              <div
                className="flex flex-row mb-2 relative rounded-xl overflow-hidden h-20"
                key={course.id}
              >
                <img
                  src={course.image}
                  alt=""
                  className="w-16 h-full object-cover"
                />
                <div className="flex flex-col text-chocolate justify-start w-full border items-start p-2 rounded-r-xl text-left">
                  <span className="text-lg truncate w-48">{course.name}</span>
                  <span className="">
                    {course.teacher.names} {course.teacher.surnames}
                  </span>
                </div>
                {course.discount > 0 ? (
                  <div className="flex text-right absolute bottom-0 right-0 px-4 py-1">
                    <span className="text-sm line-through text-chocolate h-6 mr-2 -mb-1">
                      {numeral(course.price).format("$0,0.00")}
                    </span>
                    <span className="text-base font-bold text-primary">
                      {numeral(course.discount).format("$0,0.00")}
                    </span>
                  </div>
                ) : (
                  <div className="flex text-right absolute bottom-0 right-0 px-4 py-1">
                    <span className="text-base font-bold text-primary">
                      ${course.price}
                    </span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className="w-full px-4 pb-4">
          <div className="flex flex-row justify-between mb-2 text-base md:text-lg font-normal">
            <span className="block text-chocolate text-2xl">Total</span>
            <span className="text-primary block text-2xl">${total}</span>
          </div>
          <div className="flex justify-between items-center h-12">
            <div className="mr-2 flex-1 flex">
              <Link
                className="btn btn-outline-primary"
                to="/carrito-de-compras"
              >
                Ir al carrito
              </Link>
            </div>
            <div className="ml-2 flex-1 flex">
              <Link
                className="btn btn-primary"
                to="/carrito-de-compras/checkout"
              >
                Comprar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShoppingCart
