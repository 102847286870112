import React from "react"
import dayjs from "dayjs"
import "dayjs/locale/es"
import truncate from "lodash/truncate"

dayjs.locale("es")

const Question = props => {
  /**
   * Format subtitle to be:
   * Realizada por [UserFullName] el [CreatedAt]
   */
  function getSubtitle() {
    const hasDate = !!props.question.created_at
    const createdAt = dayjs(props.question.created_at).format(
      "DD [de] MMMM [del] YYYY"
    )
    return (
      <p className="hidden md:flex text-xs md:text-base font-base">
        Realizada por&nbsp;
        <span className="font-medium">{props.question.user.full_name}</span>
        {hasDate && <span>&nbsp;el&nbsp;</span>}
        {hasDate && <span className="font-medium">{createdAt}</span>}
      </p>
    )
  }

  return (
    <div
      className="flex justify-between items-center md:p-6 hover:bg-pale cursor-pointer rounded-xl mb-4"
      onClick={() => props.handleClick(props.question)}
    >
      <div className="flex w-full items-center">
        <img
          alt="comuni"
          src={props.question.user.profile.image}
          className="hidden w-16 h-16 overflow-hidden object-cover md:block rounded-full mr-4"
        />
        <div className="flex-1 pr-4 md:pr-0">
          <h5 className="text-base md:text-xl font-medium mb-1 leading-tight hidden md:block">
            {props.question.title}
          </h5>
          <h5 className="text-base md:text-xl font-medium mb-1 leading-tight md:hidden">
            {truncate(props.question.title, 35)}
          </h5>
          <span className="hidden md:flex text-xs md:text-base font-base">
            {getSubtitle()}
          </span>
        </div>
      </div>
      <div className="text-primary flex flex-col items-center justify-center md:w-auto">
        <span className="font-bold text-base md:text-3xl block mr-2 md:mr-0 -mt-1 md:mt-0">
          {props.question.total_answers}
        </span>
        <span className="leading-none">respuestas</span>
      </div>
    </div>
  )
}

export default Question
