import React from "react"
import Slider from "./../Slider"
const ToolTipDescription = ({ descriptions }) => (
  <div className="bg-white w-48 rounded-lg text-chocolate p-2">
    {descriptions}
  </div>
)
const TopBarMobile = props => {
  const data = [
    {
      id: 1,
      image: "#pc",
      label: "100% Online",
      width: "210px",
      description:
        "Cursos en línea donde puedes avanzar a través de lecciones a tu propio ritmo y desde cualquier lugar.",
    },
    {
      id: 2,
      image: "#hand",
      label: "Pago único",
      width: "194",
      description:
        "Paga una sola vez por el curso y accede cuando quieras. No tiene fecha límite de visualización.",
    },
    {
      id: 3,
      image: "#lock",
      label: "Acceso permanente",
      width: "210px",
      description:
        "Una vez que compras el curso tienes acceso 24/7 desde cualquier dispositivo móvil con conexión a internet.",
    },
    {
      id: 4,
      image: "#conversation",
      label: "Sección de Q&A",
      width: "210px",
      description:
        "Arma o participa en foros de consulta y recibe apoyo de tutores en el proceso de aprendizaje.",
    },
    {
      id: 5,
      image: "#diploma",
      label: "Certificación al finalizar",
      width: "210px",
      description:
        "Descarga el certificado firmado por el tutor y avalado por Comuni al completar el curso.",
    },
  ]

  return (
    <section className="block md:hidden bg-yellow-10">
      <div className="mx-auto w-11/12">
        <Slider slidesPerView={2}>
          {data.map(item => (
            <div
              className="flex items-center justify-center"
              key={item.id}
              style={{ width: item.width }}
            >
              <div className="bg-white rounded-full shadow-second w-20 h-20 mr-3 text-primary hover:text-white hover:bg-gradient flex justify-center items-center ">
                <svg
                  className={
                    item.image === "#hand"
                      ? "w-full h-full fill-current"
                      : "w-12 h-12 fill-current"
                  }
                >
                  <use xlinkHref={item.image} />
                </svg>
              </div>
              <span className="font-medium text-xl leading-tight w-1/3">
                {item.label}
              </span>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
export default TopBarMobile
