import React from "react"
import GigaButton from "../share/gigantButton"
import useGraphQL from "@hooks/useGraphQL"
import banner from "../../images/home-banner.png"
import Hack from "./Hack"
import PopTutor from "./PopTutor"
import Separator from "../../images/icon-separator.svg"
import { GET_HOME_CLIENT } from "@queries"
import LatestCourses from "./LatestCourses"
import Testimonials from "./Testimonials"
import Interest from "./Interest"
import MeetToTutor from "./MeetToTutor"
import SearchAutocomplete from "./../SearchAutocomplete"

const Index = () => {
  const data = useGraphQL(GET_HOME_CLIENT())
  return (
    <div>
      <section>
        <div className="lg:h-sm flex items-center relative">
          <div className="bg-black md:h-10/12 lg:h-full w-full">
            <img
              src={banner}
              alt="algo"
              className="object-cover h-full w-full md:block hidden"
            />
            <div className="h-64 bg-gradient md:hidden" />
          </div>
          <div className="absolute w-full md:w-5/12 text-white flex flex-col justify-center align-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-start flex-col w-4/5 text-center md:text-left">
                <span className="text-3xl lg:text-4xl xl:text-4.1xl font-bold leading-tight">
                  ¡TÚ ELIGES TU CAMINO!
                </span>
                <span className="lg:text-xl xl:text-3xl font-medium block mb-2 leading-tight">
                  Descubre las nuevas tendencias y aprende sin límites ni
                  horario.
                </span>
                <div className="mt-4">
                  <GigaButton title="Únete Gratis" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div />
      </section>
      {data.courses && <LatestCourses courses={data.courses} />}
      <section
        className="px-6 py-16 mb-16 md:mb-24 bg-no-repeat bg-cover py-40 text-chocolate"
        style={{ backgroundImage: `url('${Separator}')` }}
      >
        <div className="flex flex-col items-center mb-12 pt-16 md:pt-0">
          <h2 className="text-2xl md:text-4.1xl mb-2 font-bold text-center">
            Explora las categorías de tu preferencia
          </h2>
          <p className="text-xl md:text-3xl text-center">
            Aprende desde cero con videos de alta calidad 100% interactivos
          </p>
        </div>
        <div className="max-w-5xl mx-auto grid grid-columns-2 md:grid-columns-3 grid-gap-4">
          {data.interests
            ? data.interests.map(interest => {
                return <Interest interest={interest} />
              })
            : []}
        </div>
      </section>
      <section className="container mx-auto px-6 my-20 pb-10 text-chocolate">
        <div className="flex flex-col items-center md:text-center mb-12">
          <span className="text-2xl md:text-4.1xl font-bold">
            ¿Por qué usar Comuni?
          </span>
          <span className="text-xl md:text-3xl text-center">
            Aprende desde cero con videos de alta calidad 100% interactivos
          </span>
        </div>
        <div className="w-full mx-auto flex justify-center">
          <Hack />
        </div>
      </section>
      <section className="w-full bg-pink-10 pt-16 text-chocolate pb-8">
        <div className="container mx-auto px-6 md:mb-24 pb-10">
          <div className="flex flex-col items-center">
            <span className="text-2xl md:text-4.1xl font-bold text center">
              Conoce a nuestros tutores
            </span>
            <span className="text-xl md:text-3xl">
              ¡Elegimos a los mejores para ti!
            </span>
          </div>
          <div className="md:w-4/5 mx-auto">{/* <PopTutor /> */}</div>
          <div className="max-w-6xl mx-auto">
            {data.tutores && <MeetToTutor tutores={data.tutores} />}
          </div>
        </div>
      </section>
      <section>
        {data.testimonials && <Testimonials testimonials={data.testimonials} />}
      </section>
      <section>
        <div className="lg:h-sm flex items-center relative">
          <div className="bg-black md:h-10/12 lg:h-full w-full">
            <img
              src={banner}
              alt="algo"
              className="hidden md:flex object-cover h-full w-full"
            />
            <div className="h-64 bg-gradient md:hidden" />
          </div>
          <div className="absolute w-full md:w-5/12 text-white flex flex-col justify-center align-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-start flex-col w-4/5 text-center md:text-left">
                <span className="text-3xl lg:text-4xl xl:text-5xl font-bold">
                  ¿Qué esperas?
                </span>
                <span className="lg:text-xl xl:text-3xl font-medium block mb-2 leading-tight">
                  ¡Únete a nuestra comunidad y aprende a tu ritmo habilidades
                  demandadas por el mercado actual!
                </span>
                <div className="mt-6">
                  <GigaButton title="Únete Gratis" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Index
