import { createStore, applyMiddleware } from "redux"
import reduxThunk from "redux-thunk"
import rootReducer from "./reducers"

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)

let store
if (typeof window !== `undefined`) {
  store = createStoreWithMiddleware(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStoreWithMiddleware(rootReducer)
}

export default store
