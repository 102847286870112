import Http from "@modules/Http"
import { GET_COURSE_PER_USER } from "@queries"
import { SET_USER_COURSES } from "@constants"

export function getCourses(id) {
  return async function(dispatch, getState) {
    const { user } = getState()
    const { data } = await Http.graphql(GET_COURSE_PER_USER(id || user.id))
    const courses = data.user.courses
    dispatch({
      type: SET_USER_COURSES,
      payload: courses,
    })
    return
  }
}
