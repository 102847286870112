import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar, faUser } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { addToCart } from "@actions/cart"
import Breadcrumbs from "../Breadcrumbs"
import CourseVideo from "./CourseVideo"
import TopBar from "./TopBar"
import TopBarMobile from "./TopBarMobile"
import ShowCaseSection from "./ShowCaseSection"
import ScoreAndComments from "./ScoreAndComments"
import { navigate } from "gatsby"
import Countdown from "react-countdown-now"
import numeral from "numeral"

function createMarkup(html) {
  return { __html: html }
}

const TeacherInfo = ({ course }) => (
  <div className="bg-white rounded-xl shadow-card text-center max-w-md px-4 md:px-8 py-6 mx-auto">
    <span className="uppercase font-medium text-lg leading-tight">
      PROFESOR
    </span>
    <span className="section-title block leading-tight mb-4">
      {course.teacher?.full_name}
    </span>
    <div className="flex font-medium text-sm md:text-lg mb-2">
      <span className="flex-1">
        <FontAwesomeIcon icon={faStar} className="mr-2 text-primary text-xs" />
        {course.teacher?.average_score} valoraciones
      </span>
      <span className="flex-1">
        <FontAwesomeIcon icon={faUser} className="mr-2 text-primary text-xs" />
        {course.teacher?.total_students}+ estudiantes
      </span>
    </div>
    <p className="text-left text-lg">{course.teacher.profile.description}</p>
  </div>
)

const Course = props => {
  const course = useSelector(state => state.course)
  const dispatch = useDispatch()

  function addCourseToCart(buy) {
    const referralId = props.params.r
    dispatch(addToCart(course, referralId))
    if (buy) {
      navigate(`/carrito-de-compras/checkout`)
    }
  }

  const hasDiscount = course.discount > 0

  const renders = ({ hours, minutes, seconds, completed, total, days }) => {
    if (completed) {
      // Render a complete state
      return <span>Acabó la oferta</span>
    } else {
      // Render a countdown
      return (
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-center">
            <span className="w-12">{days} </span>:{" "}
            <span className="w-12">{hours}</span>:
            <span className="w-12">{minutes}</span>:
            <span className="w-12">{seconds}</span>
          </div>
          <div className="flex flex-row items-center justify-center text-xs font-light">
            <span className="w-12">Días</span>:{" "}
            <span className="w-12">Hrs</span>:<span className="w-12">Mins</span>
            :<span className="w-12">Segs</span>
          </div>
        </div>
      )
    }
  }
  return (
    <div>
      <section>
        <div className="relative py-6 md:py-12">
          <div className="bg-gradient absolute top-0 left-0 h-80 z-0 w-full" />
          <div className="container px-4 md:px-12 mx-auto flex flex-col relative z-10">
            <Breadcrumbs course={course} color={"white"} />
            <div className="flex flex-col lg:flex-row items-center justify-between ">
              <div className="h-full flex-1 w-full rounded-xl overflow-hidden md:h-sm lg:w-650 md:mr-8">
                <CourseVideo fluid={true} />
              </div>
              <div className="mt-4 md:mt-8 lg:mt-0 bg-white rounded-xl shadow-second max-w-md overflow-hidden flex flex-col h-full">
                <div className="py-6 px-4 md:px-10 flex-1">
                  <h1 className="text-4xl font-semibold leading-tight">
                    {course.name}
                  </h1>
                  <span className="text-4xl font-bold text-primary block">
                    {hasDiscount
                      ? numeral(course.discount).format("$0,0.00")
                      : numeral(course.price).format("$0,0.00")}
                  </span>
                  {hasDiscount && (
                    <small className="text-xl leading-normal mb-4 block">
                      Precio Regular: {numeral(course.price).format("$0,0.00")}
                    </small>
                  )}
                  <div>
                    <button
                      className="btn btn-primary btn--rounded mb-4"
                      onClick={() => addCourseToCart(true)}
                    >
                      ¡Cómpralo Ahora!
                    </button>

                    <button
                      className="btn btn-outline-primary"
                      onClick={() => addCourseToCart(false)}
                    >
                      Añadir al carrito
                    </button>
                  </div>
                </div>
                <div className="bg-chocolate text-white text-center text-xl py-4">
                  <span className="block leading-tight text-base">
                    ¡Aprovecha! La oferta termina en:
                  </span>
                  <span className="font-bold leading-tight">
                    <Countdown renderer={renders} date={"2019-11-1"} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-gray-25">
        <TopBarMobile />

        <TopBar />
        <section className="container px-4 md:px-12 mx-auto flex flex-col lg:flex-row pb-12 md:py-10">
          <div className="lg:w-7/12 lg:p-0">
            <div className="lg:max-w-xl mb-6">
              <span className="section-title block mb-4">Descripción</span>
              <p
                className="leading-normal"
                dangerouslySetInnerHTML={createMarkup(course.about)}
              ></p>
            </div>
            <div className="md:hidden mb-6">
              <TeacherInfo course={course} />
            </div>
            <div className="lg:max-w-xl mb-6">
              <ShowCaseSection course={course} />
            </div>
            <div className="lg:max-w-xl mb-6">
              <ScoreAndComments course={course} />
            </div>
          </div>
          <div className="hidden md:block flex-1">
            {/* <img
              alt="comuni"
              src="https://i.ibb.co/h9jjxdN/bitmap.png"
              className="block mx-auto"
            /> */}
            <TeacherInfo course={course} />
          </div>
        </section>
      </div>
    </div>
  )
}

export default Course
