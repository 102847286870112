import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { navigate } from "gatsby"
const CardProgress = props => {
  function goToCourse() {
    navigate(`/cursos/${props.course.id}`)
  }
  const { course } = props
  const percent = Math.round(course.completed_percent)
  return (
    <div
      className="rounded-xl shadow-mini w-full max-w-99 md:max-w-95 relative flex overflow-hidden bg-white h-28 md:h-32 cursor-pointer mx-auto"
      onClick={goToCourse}
    >
      <div className="bg-black relative h-full w-28 hidden md:block">
        <img
          src={course.image}
          alt="algo"
          className="h-full absolute top-0 left-0 w-full object-cover"
        />
      </div>
      <div className="flex flex-col text-lg flex-1 px-4 py-2 justify-between">
        <div className="flex flex-col justify-around h-full md:justify-center">
          <span className="text-sm">
            Clase {course.completed_contents} de 46 (Módulo{" "}
            {course.completed_contents} de {course.total_contents})
          </span>
          <h2 className="font-sans text-base font-semibold block md:text-lg leading-tight truncate">
            {course.name}
          </h2>
          <div className="hidden md:block">
            <FontAwesomeIcon
              icon={faClock}
              className="text-chocolate mr-2 text-sm"
            />
            <span className="text-sm">{course.remaining_hours} restantes</span>
          </div>
          <div className="flex justify-between items-center mt-auto">
            <div className="h-3 w-full rounded-full overflow-hidden bg-gray-35 mr-6 -mb-1">
              <div
                className="h-full bg-primary rounded-r-full"
                style={{ width: `${percent}%` }}
              />
            </div>
            <span className="text-sm">{percent}%</span>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CardProgress
