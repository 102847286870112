import React, { useEffect, useState } from "react"
import { Link } from "@reach/router"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Reposteria from "../../images/icon-cupcake.svg"
import Estetica from "../../images/icon-liner.svg"
import Bienestar from "../../images/icon-diet.svg"
import { GET_CATEGORY } from "@queries"
import useGraphQL from "@hooks/useGraphQL"
import Loading from "./../share/Loading"
import { navigate } from "@reach/router"
import take from "lodash/take"
import first from "lodash/first"
import isEmpty from "lodash/isEmpty"
import StarRatings from "react-star-ratings"

String.prototype.trimEllip = function(length) {
  return this.length > length ? this.substring(0, length) + "..." : this
}

const Categoria = props => {
  const [selectedCategory, setSelectedCategory] = useState({
    courses: [],
  })
  const [categories, setCategories] = useState([])
  const data = useGraphQL(GET_CATEGORY())
  useEffect(() => {
    if (!isEmpty(data)) {
      setCategories(data.categorias)
      setSelectedCategory(first(data.categorias))
    }
  }, [data])

  function selectCategory(id) {
    const select = categories.find(category => category.id === id)
    setSelectedCategory(select)
  }

  const imageStyles = "w-6 mr-4"
  const listItemStyles =
    "flex flex-row items-center text-chocolate hover:bg-yellow-10 rounded-lg h-12 flex items-center px-4 mb-2 justify-between cursor-pointer"

  return (
    <div className="bg-white rounded-lg">
      {data ? (
        <div className=" flex flex-row">
          <div className="flex flex-col pl-3 pr-6 py-6 border-r">
            <div className={listItemStyles} onClick={() => selectCategory(1)}>
              <img src={Reposteria} alt="" className={imageStyles} />
              <span className="mx-2 text-xl">Pastelería</span>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="font-light ml-2 text-xl"
              />
            </div>
            <div className={listItemStyles} onClick={() => selectCategory(2)}>
              <img src={Estetica} alt="" className={imageStyles} />
              <span className="mx-2 text-xl">Estética</span>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="font-light ml-2 text-xl"
              />
            </div>
            <div className={listItemStyles} onClick={() => selectCategory(3)}>
              <img src={Bienestar} alt="" className={imageStyles} />
              <span className="mx-2 text-xl">Bienestar</span>
              <FontAwesomeIcon
                icon={faAngleRight}
                className="font-light ml-2 text-xl"
              />
            </div>
          </div>
          <div className="p-6">
            {take(selectedCategory.courses, 3).map(course => {
              return (
                <div
                  className="flex flex-row mb-2 min-w-275 max-w-275 h-20"
                  key={course.id}
                  onClick={() => navigate(`/cursos/${course.id}`)}
                >
                  <img
                    src={course.image}
                    alt=""
                    className="w-16 h-full rounded-l-lg object-cover"
                  />
                  <div className="flex flex-col text-chocolate justify-start flex-1 border rounded-r-lg items-start px-2 pt-2">
                    <span className="text-sm w-full truncate text-left font-semibold">
                      {course.name}
                    </span>
                    <span className="text-xs truncate">
                      {course.teacher.full_name}{" "}
                    </span>
                    <div className="flex flex-row justify-between w-full">
                      <StarRatings
                        rating={course.total_scores}
                        starSpacing="10px"
                        starRatedColor="rgb(245, 202, 35)"
                        starDimension="15px"
                        starSpacing="0px"
                      />
                      <span className="text-base font-semibold">
                        ${course.price}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default Categoria
