import {
  SET_COURSE,
  SET_CURRENT_VIDEO,
  SET_QUESTIONS,
  SET_ANSWERS,
} from "@constants"
import Http from "@modules/Http"
import { GET_COURSE_CURRENT } from "@queries"
import first from "lodash/first"
import omit from "lodash/omit"
import flatten from "lodash/flatten"

export function getCourse(id) {
  return async function(dispatch) {
    const { data } = await Http.graphql(GET_COURSE_CURRENT(id))
    const { course } = data

    // Set current video
    const content = first(course.contents)
    const video = first(content.videos)
    dispatch({
      type: SET_CURRENT_VIDEO,
      payload: video,
    })

    // Set Questions
    const questions = course.questions
    dispatch({
      type: SET_QUESTIONS,
      payload: questions,
    })

    // Set Answers
    const answers = flatten(questions.map(({ answers }) => answers))
    dispatch({
      type: SET_ANSWERS,
      payload: answers,
    })

    dispatch({
      type: SET_COURSE,
      payload: omit(course, ["questions"]),
    })

    return
  }
}

export function completeVideo(id) {
  return async function(dispatch, getState) {
    const { course, currentVideo } = getState()

    const videoId = currentVideo.id

    try {
      await Http.post(`/videos/${videoId}/complete`)

      const updatedCoure = {
        ...course,
        contents: course.contents.map(content => {
          return {
            ...content,
            videos: content.videos.map(video => {
              return {
                ...video,
                completed: videoId === video.id ? true : video.completed,
              }
            }),
          }
        }),
      }

      dispatch({
        type: SET_COURSE,
        payload: updatedCoure,
      })
    } catch (error) {
      console.error(error)
    }
  }
}
