import React from "react"

const Terms = () => {
  return (
    <div className="w-full mx-auto mb-6">
      Utilizamos la dirección IP del usuario para identificar problemas,
      administrar el Sitio Web e identificar patrones de comportamiento del
      tráfico. También se utilizan cookies o tecnología similar para poder
      ofrecerle contenido acorde a sus intereses y para almacenar su contraseña
      y así el Usuario no tenga que ingresarla nuevamente cada vez que regresa
      al Sitio Web. Divulgación de información La información del Usuario va a
      poder ser utilizada y divulgada en los siguientes puntos: a. Promoción de
      nuestro servicio: cuando el Usuario consume un servicio y podría
      beneficiarse de otro, podríamos enviarle un correo comunicándolo. b. Envío
      de mensajes internos sobre cambios o modificaciones del Sitio Web. c.
      Servicio al cliente. d. Cumplimiento de requisitos legales y de las
      Condiciones de Uso. e. Entregar información a representantes legales o
      consejeros. f. Procesos y defensas ante cortes, tribunales o cualquier
      proceso legal. g. Transferencia de información en caso de venta, fusión,
      consolidación o adquisición de la compañía. h. Compartir la información
      con proveedores externos i. Compartir información con empleados,
      consultores o ejecutivos para que procesen la información por nosotros. De
      ser necesario, o solo en algunos casos a criterio de COMUNI o según el
      acuerdo con la otra parte, los datos de nuestros usuarios podrán ser
      compartidos con profesores, mentores, otros estudiantes, empresas que nos
      prestan servicios, socios comerciales, proveedores de análisis,
      inversionistas y proveedores de redes sociales, publicidad y promociones y
      encuestas. Seguridad COMUNI se compromete a proteger sus datos personales.
      Protegemos su información contra malos usos, no autorizados o divulgación
      con tecnología y procesos utilizados en la industria. El Sitio Web puede
      contener enlaces a otros sitios. COMUNI no se hace responsable del
      contenido de las políticas de privacidad y/o prácticas de otros sitios
      web. Cuando un Usuario acceda a un enlace que lo redirija a otro sitio
      web, éste deberá aceptar y sujetarse a la política de privacidad de dicho
      sitio web. Flujo transfronterizo de datos Los servidores y/o ordenadores
      utilizados por COMUNI para prestar sus Servicios podrán estar ubicados
      fuera del país u bajo otra jurisdicción gubernamental. El Usuario autoriza
      a COMUNI a transferir, almacenar y procesar sus Datos Personales a nivel
      internacional (“flujo transfronterizo”) de acuerdo a las disposiciones
      establecidas en la Ley para lo cual manifiesta su consentimiento previo,
      informado, libre, expreso e inequívoco. Modificaciones Los Usuarios de
      COMUNI pueden editar, modificar o eliminar su cuenta desde las opciones de
      Configuración de Perfil del Sitio Web o poniéndose en contacto con COMUNI.
      Menores de edad Para el uso de COMUNI, los Usuarios deben de tener más de
      13 años de edad. Por lo tanto, no recopilamos información de niños. Si el
      Usuario tiene entre 14 a 18 años, debe de pedir permiso a los padres de
      familia. Contacto COMUNI acepta todo tipo de duda, queja o comentario con
      respecto a las Políticas de Privacidad. Envíenos un correo
      a ayuda@crehana.com o escríbanos a la siguiente dirección: km. 1.5 vía a
      Samborondón, Edificio Samborondón Plaza, oficina 513. Preferencias de
      comunicaciones y de suscripciones Si quieres dejar de recibir nuestras
      promos y noticias, solo haz click en la parte inferior (de cada correo
      electrónico), o escríbenos a ayuda@crehana.com
    </div>
  )
}
export default Terms
