import React, { useEffect, useState } from "react"
import Http from "@modules/Http"
import { useSelector } from "react-redux"
import includes from "lodash/includes"
import compact from "lodash/compact"
import Search from "./../SearchAutocomplete"
import MobileSearch from "../Header/Search"
import Avatar from "react-avatar"
import logo from "../../images/icon-comuni-logo.svg"
import TooltipCourse from "../Tooltips/Courses"
import ToolTipProfile from "../Tooltips/User"
import Mobile from "../Header/Mobile"
import { Link } from "@reach/router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faThLarge,
  faShoppingCart,
  faBars,
  faSearch,
  faBell,
} from "@fortawesome/free-solid-svg-icons"
import Tippy from "@tippy.js/react"
import { useDispatch } from "react-redux"
import { logOutUser } from "@actions/session"
import { getCart } from "@actions/cart"
import TooltipCategoria from "./../Tooltips/Categoria"
import ShoppingCart from "./../Tooltips/ShoppingCart"
import TooltipNotifications from "./../Tooltips/Notifications"

const NOPATH = ["registro", "recuperar-clave", "login"]

const Logo = props => (
  <Link to={props.link}>
    <img src={logo} className="md:w-24 lg:w-28 mr-8 -mt-1" alt="" />
  </Link>
)

const SearchBar = props => (
  <div className="ml-4 w-72 xl:w-21">
    <Search placeholder="Buscar Cursos" />
  </div>
)

const Header = props => {
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false)
  const [isOpenMenuSearch, setIsOpenMenuSearch] = useState(false)
  const [isOpenMenuCurso, setIsOpenMenuCurso] = useState(false)
  const pathName = compact(props.location.pathname.split("/"))[0]
  const hasHeader = includes(NOPATH, pathName)
  const user = useSelector(state => state.user)
  const cart = useSelector(state => state.cart)
  const userType = user.type || {}
  const dispatch = useDispatch()
  const [unread, setUnread] = useState(0)

  useEffect(() => {
    dispatch(getCart())
  }, [dispatch])

  if (hasHeader) {
    return <div />
  }

  function markAsRead() {
    setUnread(0)
    Http.post("/notifications/mark_as_read").catch(console.error)
  }

  async function logout() {
    await Http.post("/logout")
    window.location = "/"
    dispatch(logOutUser())
  }

  const logoUrl = userType.type === "user" ? "/estudiante" : "/tutores"

  return (
    <header className="border-b">
      {!props.session ? (
        <div className="text-chocolate">
          <div>
            <div className="hidden lg:flex flex-row w-full h-24 font-semibold text-lg justify-between container container mx-auto bg-white">
              <div className="flex flex-1 flex-row items-center mt-1 lg:mt-0">
                <Logo link="/" />
                <div className="cursor-pointer mr-6 h-full flex items-center">
                  <Tippy
                    content={<TooltipCategoria />}
                    arrow={true}
                    animation="scale"
                    duration={0}
                    interactive={true}
                    delay={[0, 0]}
                    trigger="click"
                  >
                    <div className="flex flex-row items-center h-50">
                      <FontAwesomeIcon
                        icon={faThLarge}
                        className="text-primary"
                      />
                      <span className="ml-2">Categorias</span>
                    </div>
                  </Tippy>
                </div>

                <SearchBar />
              </div>
              <div className="flex flex-row items-center justify-between md:text-base lg:text-xl">
                <Tippy
                  content={<ShoppingCart />}
                  arrow={true}
                  animation="scale"
                  duration={0}
                  interactive={true}
                  delay={[0, 0]}
                  trigger="click"
                >
                  <button className="relative h-50 mr-8">
                    <div className="with-badge" data-cart={cart.length}>
                      <FontAwesomeIcon
                        icon={faShoppingCart}
                        className="text-primary text-xl"
                      />
                    </div>
                  </button>
                </Tippy>
                <div
                  onClick={() => props.setShowModal(true)}
                  className="cursor-pointer mr-8"
                >
                  Inicia sesion
                </div>
                <div>
                  <Link
                    to="/registro"
                    className="btn btn-primary font-semibold"
                  >
                    Regístrate
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:hidden flex flex-row justify-between h-16 p-4 items-center text-xl fixed w-full bg-white z-20">
              <div className="flex flex-row items-center">
                <div
                  onClick={() => setIsOpenMenuMobile(true)}
                  className="cursor-pointer"
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faBars}
                    className="text-primary mr-6 cursor-pointer"
                  />
                </div>
                <div onClick={() => setIsOpenMenuSearch(true)}>
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-primary cursor-pointer"
                  />
                </div>
              </div>
              <Link to="/">
                <img src={logo} className="mr-8 w-24" />
              </Link>
              <FontAwesomeIcon icon={faShoppingCart} className="text-primary" />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-chocolate">
          <div className="hidden lg:flex flex-row w-full h-24 justify-between bg-white border-b shadow-second">
            <div className="lg:flex flex-row justify-between container mx-auto ">
              <div className="flex flex-1 flex-row items-center">
                <Link to={logoUrl}>
                  <img
                    src={logo}
                    className="md:w-24 lg:w-28 mr-8 -mt-1"
                    alt=""
                  />
                </Link>
                <div className="cursor-pointer">
                  <Tippy
                    content={<TooltipCategoria />}
                    arrow={true}
                    animation="scale"
                    duration={0}
                    interactive={true}
                    delay={[0, 0]}
                    trigger="click"
                  >
                    <div className="flex flex-row items-center h-50">
                      <FontAwesomeIcon
                        icon={faThLarge}
                        className="text-primary"
                      />
                      <span className="ml-2 text-chocolate font-semibold text-xl">
                        Categorias
                      </span>
                    </div>
                  </Tippy>
                </div>
                <SearchBar />
              </div>
              {userType.type === "user" && (
                <div className="flex flex-row items-center w-72 justify-between text-chocolate">
                  {/* <span>Lives</span> */}
                  <Tippy
                    content={
                      <TooltipCourse isOpenMenuCurso={isOpenMenuCurso} />
                    }
                    arrow={true}
                    animation="scale"
                    duration={0}
                    delay={[0, 0]}
                    trigger="click"
                    interactive={true}
                    onShow={() => setIsOpenMenuCurso(true)}
                    onHide={() => setIsOpenMenuCurso(false)}
                  >
                    <button className="text-xl font-semibold h-50">
                      Mis cursos
                    </button>
                  </Tippy>
                  <Tippy
                    content={<ShoppingCart />}
                    arrow={true}
                    animation="scale"
                    duration={0}
                    interactive={true}
                    delay={[0, 0]}
                    trigger="click"
                  >
                    <button className="relative h-50">
                      <div className="with-badge" data-cart={cart.length}>
                        <FontAwesomeIcon
                          icon={faShoppingCart}
                          className="text-primary text-xl"
                        />
                      </div>
                    </button>
                  </Tippy>
                  <Tippy
                    content={
                      <TooltipNotifications
                        type={userType.type}
                        logout={logout}
                        unread={unread}
                        setUnread={setUnread}
                      />
                    }
                    arrow={true}
                    animation="scale"
                    duration={0}
                    delay={[0, 0]}
                    interactive={true}
                    trigger="click"
                    onShown={markAsRead}
                  >
                    <button className="h-50 relative">
                      <div className="with-badge" data-cart={unread}>
                        <FontAwesomeIcon
                          icon={faBell}
                          className="text-primary text-2xl"
                        />
                      </div>
                    </button>
                  </Tippy>
                  <Tippy
                    content={
                      <ToolTipProfile
                        type={userType.type}
                        logout={logout}
                        user={user}
                      />
                    }
                    arrow={true}
                    animation="scale"
                    duration={0}
                    delay={[0, 0]}
                    trigger="click"
                    interactive={true}
                  >
                    <div className="cursor-pointer">
                      {user.profile ? (
                        <Avatar
                          name={user.names}
                          round={true}
                          size={"50px"}
                          src={user.profile.image}
                        />
                      ) : (
                        <Avatar name={user.names} round={true} size={"50px"} />
                      )}
                    </div>
                  </Tippy>
                </div>
              )}
              {userType.type === "tutor" && (
                <div className="flex flex-row items-center w-1/3 justify-between">
                  <Link to="/guia" className="text-chocolate font-semibold">
                    ¿Cómo usar Comuni?
                  </Link>
                  <Link to="/tutores" className="text-chocolate font-semibold">
                    Mis cursos
                  </Link>
                  <Tippy
                    content={
                      <TooltipNotifications
                        unread={unread}
                        setUnread={setUnread}
                      />
                    }
                    arrow={true}
                    animation="scale"
                    duration={0}
                    interactive={true}
                    delay={[0, 0]}
                    trigger="click"
                    onShown={markAsRead}
                  >
                    <button className="h-50 relative">
                      <div className="with-badge" data-cart={unread}>
                        <FontAwesomeIcon
                          icon={faBell}
                          className="text-primary text-2xl"
                        />
                      </div>
                    </button>
                  </Tippy>
                  <Tippy
                    content={
                      <ToolTipProfile
                        type={userType.type}
                        logout={() => logout()}
                        user={user}
                      />
                    }
                    arrow={true}
                    animation="scale"
                    duration={0}
                    delay={[0, 0]}
                    trigger="click"
                    interactive={true}
                  >
                    <div className="cursor-pointer">
                      <Avatar
                        name={user.names}
                        round={true}
                        size={"55px"}
                        src={user.profile.image}
                      />
                    </div>
                  </Tippy>
                </div>
              )}
            </div>
          </div>
          <div className="lg:hidden flex flex-row justify-between h-16 p-4 items-center text-xl fixed w-full bg-white z-20">
            <div className="flex flex-row items-center">
              <div
                onClick={() => setIsOpenMenuMobile(true)}
                className="cursor-pointer"
              >
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-primary mr-6 cursor-pointer"
                />
              </div>
              <div onClick={() => setIsOpenMenuSearch(true)}>
                <FontAwesomeIcon
                  icon={faSearch}
                  className="text-primary cursor-pointer"
                />
              </div>
            </div>
            {userType.type === "user" ? (
              <Link to="/estudiante">
                <img src={logo} className="w-24 mr-8" />
              </Link>
            ) : (
              <Link to="/tutores">
                <img src={logo} className="w-24 mr-8" />
              </Link>
            )}

            {userType.type === "user" ? (
              <div className="with-badge-mobile" data-cart={cart.length}>
                <Link to="/carrito-de-compras">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="text-primary text-xl"
                  />
                </Link>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      )}
      {isOpenMenuMobile && (
        <Mobile
          setIsOpenMenuMobile={setIsOpenMenuMobile}
          type={userType.type}
        />
      )}
      {isOpenMenuSearch && (
        <MobileSearch setIsOpenMenuSearch={setIsOpenMenuSearch} />
      )}
    </header>
  )
}
export default Header
