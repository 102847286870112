import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons"

const Method = () => {
  return (
    <div className="w-full mx-auto mb-8">
      <span className="text-3xl md:text-4xl leading-tight font-semibold">
        Métodos de Pago
      </span>
      <div className="bg-orange-200 border rounded-xl w-full flex flex-col p-8 mt-4">
        <span className="text-2xl font-medium">Configuración</span>
        <div className="flex flex-row bg-white rounded-semi p-4 w-full text-xl items-center my-4">
          <FontAwesomeIcon icon={faCheckSquare} className="mr-2" />
          <p className="text-sm md:text-base">
            Mantener información de la transacción para futuras compras
          </p>
        </div>
        <span className="my-4 text-xl">
          Esto le permitirá realizar compras con un sólo click.
        </span>
        <div>
          <button className="btn btn-primary flex-1 md:w-50 md:flex-none">
            Guardar cambios
          </button>
          <button className="rounded-lg bg-transparent px-8 text-primary text-xl font-semibold py-2">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  )
}

export default Method
