import React from "react"
import { Link } from "@reach/router"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Avatar from "react-avatar"
const User = ({ type, logout, user }) => {
  return (
    <div className="bg-white w-64 p-4 rounded-lg">
      <div className="flex flex-col h-full">
        <div className="flex flex-row border-b pb-4">
          {user && user.profile ? (
            <Avatar
              name={user.names}
              round={true}
              size={"50px"}
              src={user.profile.image}
            />
          ) : (
            <Avatar name={user.names} round={true} size={"50px"} />
          )}
          <div className="flex flex-col text-chocolate ml-2 flex justify-center">
            <span className="text-lg font-semibold text-left leading-tight mb-1 -mt-1">
              {user.names} {user.surnames}
            </span>
            <Link
              to={
                type === "user" ? "/estudiante/mi-perfil" : "/tutores/mi-perfil"
              }
            >
              <div className="flex flex-row items-center">
                <span className="text-base">Mi perfil</span>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className="font-light ml-2 text-xl"
                />
              </div>
            </Link>
          </div>
        </div>
        <button
          className="text-chocolate text-lg font-semibold text-left pt-4"
          onClick={logout}
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  )
}
export default User
