import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setQuestion } from "@actions/question"
import { navigate } from "@reach/router"
import { sortByDate } from "@helpers"
import Fuse from "fuse.js"
import Modal from "./../Modal"
import ModalQuestion from "./ModalQuestion"
import Question from "../Question"

const Questions = props => {
  const course = useSelector(state => state.course)
  const questions = useSelector(state => state.questions)
  const [openModal, setOpenModal] = useState(false)
  const [sortedQuestions, setSortedQuestions] = useState(
    questions.sort(sortByDate)
  )
  const dispatch = useDispatch()

  var options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ["title", "author.firstName"],
  }

  function handleClick(question) {
    dispatch(setQuestion(question))
    navigate(`preguntas/${question.id}`, {
      state: {
        updateScroll: false,
      },
    })
  }

  function handleSearch(e) {
    if (e.target.value.length !== 0) {
      var fuse = new Fuse(questions, options) // "list" is the item array
      var result = fuse.search(e.target.value)
      setSortedQuestions(result)
    } else {
      setSortedQuestions(questions)
    }
  }

  return (
    <div>
      <div className="flex items-center mb-8">
        <input
          type="text"
          placeholder="Buscar pregunta"
          onChange={handleSearch}
          className="md:flex-1 block bg-gray-10 md:mr-8 rounded-xl px-4 md:px-6 font-medium w-full h-12 mr-2"
        />
        <div className="md:w-42 md:mt-0">
          <button
            className="btn btn-primary"
            onClick={() => setOpenModal(true)}
          >
            Preguntar
          </button>
        </div>
      </div>

      {sortedQuestions.map(question => {
        return (
          <Question
            question={question}
            key={question.id}
            handleClick={handleClick}
          />
        )
      })}
      <Modal show={openModal} handleClose={() => setOpenModal(!openModal)}>
        <ModalQuestion
          course={course}
          handleClose={() => setOpenModal(!openModal)}
        />
      </Modal>
    </div>
  )
}

export default Questions
