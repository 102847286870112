import React from "react"
import Sidebar from "./Sidebar"

const Terms = props => {
  return (
    <section className="py-16 container mx-auto flex flex-col md:flex-row">
      <Sidebar />
      <div className="flex flex-col border rounded-xl shadow-mini p-8 w-full flex-1">
        <span className="text-4xl font-semibold">Términos y Condiciones</span>
        <span className="text-2xl my-2">Última vez actualizada: 12/12/12</span>
        <p className="text-xl">
          Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
          viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitudin
          ipsum quis nunc sollicitudin ultrices. Donec euismod scelerisque
          ligula. Maecenas eu varius risus, eu aliquet arcu. Curabitur fermentum
          suscipit est, tincidunt mattis lorem luctus id. Donec eget massa a
          diam condimentum pretium. Aliquam erat volutpat. Integer ut tincidunt
          orci.
        </p>
        <span className="text-4xl font-semibold mt-4">¿Lorem Ipsum?</span>
        <p className="text-xl">
          Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
          viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitudin
          ipsum quis nunc sollicitudin ultrices. Donec euismod scelerisque
          ligula. Maecenas eu varius risus, eu aliquet arcu. Curabitur fermentum
          suscipit est, tincidunt mattis lorem luctus id. Donec eget massa a
          diam condimentum pretium. Aliquam erat volutpat. Integer ut tincidunt
          orci. Etiam tristique, elit ut consectetur iaculis, metus lectus
          mattis justo, vel mollis eros neque quis augue. Sed lobortis ultrices
          lacus, a placerat metus rutrum sit amet. Aenean ut suscipit justo.
          Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
          viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitudin
          ipsum quis nunc sollicitudin ultrices. Donec euismod scelerisque
          ligula. Maecenas eu varius risus, eu aliquet arcu. Curabitur fermentum
          suscipit est, tincidunt mattis lorem luctus id. Donec eget massa a
          diam condimentum pretium. Aliquam erat volutpat. Integer ut tincidunt
          orci. Etiam tristique, elit ut consectetur iaculis, metus lectus
          mattis justo, vel mollis eros neque quis augue. Sed lobortis ultrices
          lacus, a placerat metus rutrum sit amet. Aenean ut suscipit justo.
        </p>
      </div>
    </section>
  )
}

export default Terms
