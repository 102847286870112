import React from "react"
import Slider from "./../Slider"
import Testimonial from "./Testimonial"

const Testimonials = props => {
  return (
    <section className="md:mb-12 max-w-5xl mx-auto text-chocolate py-12 md:pb-0">
      <div className="flex flex-col items-center md:mb-8 text-center">
        <span className="text-2xl">TESTIMONIOS</span>
        <span className="text-2xl md:text-4.1xl font-bold">
          ¿Qué dicen de nosotros?
        </span>
      </div>
      {props.testimonials && props.testimonials.length > 0 && (
        <div className="w-full md:max-w-95 mx-auto progress-slider">
          <Slider spaceBetween={0} slidesPerView={2}>
            {props.testimonials.map(testimonial => {
              return (
                <div key={testimonial.id}>
                  <Testimonial testimonial={testimonial} />
                </div>
              )
            })}
          </Slider>
        </div>
      )}
    </section>
  )
}
export default Testimonials
