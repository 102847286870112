import React from "react"
import StarRatings from "react-star-ratings"
import Avatar from "react-avatar"

const UserScore = ({ score, className }) => (
  <div className={className}>
    <StarRatings
      rating={score.score}
      starSpacing="15px"
      starRatedColor="rgb(245, 202, 35)"
      starDimension="16px"
      starSpacing="0px"
    />
    &nbsp;-&nbsp;{score.human_created_at}
  </div>
)

const Score = ({ score }) => (
  <div className="md:flex md:border-b md:mb-4">
    <div className="hidden md:flex md:flex-col md:items-center md:justify-center md:w-40">
      <Avatar
        name={score.user.full_name}
        round={true}
        size={"50px"}
        src={score.user.profile.image}
      />
      <span className="block mt-2 font-medium">{score.user.full_name}</span>
    </div>
    <div className="md:flex-1">
      <UserScore score={score} className="flex items-center md:hidden" />
      <div className="border-b md:border-none pb-2 md:pb-4 mb-2 md:mb-0">
        <span className="font-medium mb-2 leading-normal md:text-lg">
          <span className="md:hidden">{score.user.full_name} </span>
          {score.title}
        </span>
        <UserScore
          score={score}
          className="flex items-center hidden md:block"
        />
        <p>{score.comment}</p>
      </div>
    </div>
  </div>
)

export default Score
