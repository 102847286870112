export default {
  user: {},
  course: {},
  currentVideo: {},
  questions: [],
  question: {},
  answers: [],
  userCourses: [],
  cart: [],
  checkoutModal: false,
  errorMessage: "",
  session: typeof window !== "undefined" && !!window.localStorage.jwt,
}
