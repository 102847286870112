import { SET_QUESTIONS, ADD_QUESTION } from "@constants"

export function setQuestions(questions) {
  return function(dispatch) {
    dispatch({
      type: SET_QUESTIONS,
      payload: questions,
    })
  }
}

export function addQuestion(question) {
  return function(dispatch) {
    dispatch({
      type: ADD_QUESTION,
      payload: question,
    })
  }
}
