import React, { useState, useEffect } from "react"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import { GET_BILLING } from "@queries"
import { useSelector } from "react-redux"
import isEmpty from "lodash/isEmpty"
import dayjs from "dayjs"
import Loading from "./../share/Loading"
import { Link } from "@reach/router"

const Payments = () => {
  const [history, setHistory] = useState({})
  const { type } = useSelector(state => state.user.type)
  const { id } = useSelector(state => state.user)
  const [data, isFetching] = useAsyncGraphQL(GET_BILLING(id), id)

  useEffect(() => {
    if (!isEmpty(data)) {
      setHistory(type === "user" ? data.user.orders : data.user.payments)
    }
  }, [data])

  if (isFetching) {
    return <Loading />
  }

  return (
    <div className="w-full mx-auto">
      <span className="text-4xl font-semibold">Mis Pagos</span>
      <div className="flex flex-col text-xl md:mt-8">
        <div className="flex-row border-b py-4 justify-between md:pl-4 hidden md:flex">
          <div className="flex flex-row lg:w-2/5 items-center">
            <span>Historial</span>
          </div>
          <div className="lg:w-1/5">
            <span>Fecha</span>
          </div>
          <div className="lg:w-1/5">
            <span>Monto</span>
          </div>
          <div className="lg:w-1/5" />
        </div>
        {history.map(order => (
          <div
            className="flex flex-col lg:flex-row border-b py-4 text-base md:text-base md:font-semibold md:pl-4 justify-between"
            key={order.id}
          >
            <div className="flex flex-row flex-wrap justify-between w-full">
              <div className="flex flex-row lg:w-2/5 items-center">
                <span>{order.details.length} cursos</span>
              </div>
              <div className="lg:w-1/5">
                <span>{dayjs(order.created_at).format("DD/MM/YY")}</span>
              </div>
              <div className="lg:w-1/5">
                <span>${order.total}</span>
              </div>
              <div className="w-full lg:w-1/5 flex justify-center mt-4 md:mt-0">
                <Link to={`/mi-perfil/factura/${order.id}`}>
                  <button className="rounded-lg w-full border-primary border-2 md:bg-white px-4 text-primary text-xl font-semibold">
                    Factura
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Payments
