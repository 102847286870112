import { SHOW_CHECKOUT_MODAL, HIDE_CHECKOUT_MODAL } from "@constants"

export function showCheckoutModal() {
  return function(dispatch) {
    dispatch({
      type: SHOW_CHECKOUT_MODAL,
    })
  }
}

export function hideCheckoutModal() {
  return function(dispatch) {
    dispatch({
      type: HIDE_CHECKOUT_MODAL,
    })
  }
}
