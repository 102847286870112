import React, { useState } from "react"
import Http from "@modules/Http"
import useValidateObject from "@hooks/useValidateObject"
import { BeatLoader } from "react-spinners"

import ErrorLabel from "@share/ErrorLabel"

const Forgot = () => {
  const [successful, setSuccessful] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({})
  const [isValid, validationErrors] = useValidateObject(
    data,
    {
      email: "required|email",
    },
    {
      "required.email": "Campo correo electrónico requerido",
    }
  )

  async function forgot() {
    if (isValid) {
      try {
        setLoading(true)
        await Http.post("/forgot/password", { email: data.email }).then()
        setLoading(false)
        setSuccessful(true)
      } catch (error) {
        console.log("error", error)
      }
    } else {
      setErrors(validationErrors)
    }
  }

  function handleInput(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  if (successful) {
    return (
      <div className="flex items-center justify-center w-full h-screen bg-yellow-10">
        <div className="w-full p-6 md:p-0 max-w-md flex flex-col items-center">
          <span className="text-2xl md:text-4xl font-bold">
            Restablecer contraseña
          </span>
          <span className="text-base md:text-xl text-center mb-4">
            Hemos enviado un enlace a tu correo electrónico con instrucciones
            para restablecer tu contraseña
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center justify-center w-full h-screen bg-yellow-10">
      <div className="w-full p-6 md:p-0 max-w-md flex flex-col items-center">
        <span className="text-2xl md:text-4xl font-bold">
          Restablecer contraseña
        </span>
        <span className="text-base md:text-xl text-center mb-4">
          Ingrese su correo de registro, le enviaremos un mensaje para cambiar
          su contraseña
        </span>
        <div className="mb-4 w-full">
          <input
            type="text"
            name="email"
            className="input h-12 w-full"
            placeholder="Correo electrónico"
            onChange={handleInput}
          />
          <ErrorLabel className="mt-1" errors={errors} name="email" />
        </div>
        {!isLoading && (
          <div className="w-64">
            <button className="btn btn-primary" onClick={forgot}>
              Enviar
            </button>
          </div>
        )}
        {isLoading && (
          <div className="w-64">
            <button className="btn btn-primary" onClick={forgot}>
              <BeatLoader color="white" />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
export default Forgot
