import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import take from "lodash/take"
import StarRatings from "react-star-ratings"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { addToCart } from "@actions/cart"

const Card = ({ course, score, setRating, setSelectCourseId }) => {
  const students = course.students ? take(course.students, 4) : []
  const dispatch = useDispatch()

  function getGoToCourse() {
    navigate(`/cursos/${course.id}`)
  }
  function addCourseToCart() {
    dispatch(addToCart(course))
  }
  function selectRating(id) {
    setRating(true)
    setSelectCourseId(id)
  }
  return (
    <div
      className="rounded-xl shadow-lg w-4/5 md:w-full mx-auto relative flex flex-col overflow-hidden bg-white"
      onClick={getGoToCourse}
    >
      <div className="bg-black relative w-full h-42">
        <img
          src={course.image}
          alt="algo"
          className="h-full absolute top-0 left-0 w-full object-cover"
        />
      </div>

      <div className="flex flex-col py-2 px-4 text-lg flex-1">
        <div className="flex md:flex-col flex-col-reverse">
          <div className="flex flex-col">
            <h2 className="font-sans text-2xl font-semibold truncate">
              {course.name}
            </h2>
            <span className="text-sm leading-tight">
              {` ${course.teacher.names} ${course.teacher.surnames}`}
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div
              className={score ? "md:text-xl cursor-pointer" : "md:text-xl"}
              onClick={
                score
                  ? e => {
                      e.stopPropagation()
                      selectRating(course.id)
                    }
                  : ""
              }
            >
              <StarRatings
                rating={
                  course.user_score === null ? 0.0 : course.user_score.score
                }
                starSpacing="15px"
                starRatedColor="rgb(245, 202, 35)"
                starDimension="18px"
                starSpacing="0px"
              />
              {course.user_score === null ? (
                <span className="ml-4 text-primary text-base font-light">
                  Valorar curso
                </span>
              ) : (
                <span className="ml-4 text-primary text-base font-light">
                  Editar valoración
                </span>
              )}
            </div>
            <div className="flex flex-row items-center md:hidden md:text-base">
              <FontAwesomeIcon icon={faClock} className="bg-white mr-2" />
              <span>3h 25 m</span>
            </div>
          </div>
        </div>
        {!score && (
          <div className="flex flex-row justify-between items-end">
            <div>
              <button
                className="btn btn-primary btn--rounded btn--tiny text-xl font-semibold"
                onClick={e => {
                  e.stopPropagation()
                  addCourseToCart()
                }}
              >
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  className="text-white mr-2"
                />
                Añadir
              </button>
            </div>
            {course.discount ? (
              <div className="flex flex-col text-right -mb-2">
                <span className="text-base line-through -mb-1 h-6">
                  ${course.price}
                </span>
                <span className="text-lg md:text-2xl font-bold">
                  ${course.discount}
                </span>
              </div>
            ) : (
              <div className="flex flex-col text-right">
                <span className="text-base line-through -mb-2 h-6" />
                <span className="text-lg md:text-2xl font-bold -mb-1">
                  ${course.price}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default Card
