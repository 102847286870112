import React from "react"
import { useSelector } from "react-redux"

function createMarkup(html) {
  return { __html: html }
}

const CourseAbout = props => {
  const course = useSelector(state => state.course)

  function getTeacherName(teacher) {
    return `${teacher.names} ${teacher.surnames}`
  }

  const { teacher } = course

  return (
    <div className="flex flex-col md:flex-row">
      <div className="md:w-7/12">
        <div className="max-w-xl mb-6">
          <span className="section-title block mb-2 md:mb-4">
            ¿De qué trata este curso?
          </span>
          <p
            className="leading-normal"
            dangerouslySetInnerHTML={createMarkup(course.about)}
          ></p>
        </div>
        {/* <div>
              <span className="section-title block mb-4">
                En este curso aprenderás…
              </span>
              <div>
                {Array(4)
                  .fill(1)
                  .map((obj, index) => (
                    <div
                      className="flex items-start mb-4 leading-snug"
                      key={index}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary text-lg mr-4 mt-1"
                      />
                      <div>
                        Donec facilisis tortor ut augue birt lacinia, at viverra
                        est semper facilisis tortor ut augue birt lacinia.
                      </div>
                    </div>
                  ))}
              </div>
            </div> */}
      </div>
      <div className="flex-1">
        <div className="bg-white rounded-xl text-center max-w-md md:px-8 py-6 mx-auto">
          <div className="flex items-center mb-4">
            <div className="bg-pink-10 rounded-full w-16 h-16 overflow-hidden relative">
              <img
                alt="comuni"
                src={teacher.profile.image}
                className="block mx-auto w-full h-full top-0 left-0 object-contain"
              />
            </div>
            <div className="text-left pl-4 flex-1">
              <span className="uppercase font-medium text-lg leading-tight">
                PROFESOR
              </span>
              <span className="section-title block leading-tight">
                {getTeacherName(teacher)}
              </span>
            </div>
          </div>
          <p className="text-left text-lg">{teacher.profile.description}</p>
        </div>
      </div>
    </div>
  )
}

export default CourseAbout
