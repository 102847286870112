import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CourseCard from "./../share/SearchCard"
import MiniCourseCard from "./../Home/MiniCourseCard"
import {
  faSearch,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons"
import queryString from "query-string"
import Loading from "./../share/Loading"
import { SEARCH_COURSES } from "@queries"
import Http from "@modules/Http"
import isEmpty from "lodash/isEmpty"

const Search = ({ location }) => {
  const [query, setQuery] = useState("")
  const [interests, setInterests] = useState([])
  const [sortBy, setSortBy] = useState("popular")
  const [interest, setInterest] = useState(null)
  const [courses, setCourses] = useState([])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (location.search) {
      const params = queryString.parse(location.search)
      if (params.q) {
        setQuery(params.q)
      }
    }
  }, [location])

  useEffect(() => {
    searchCourses(query)
  }, [query, sortBy, interest])

  async function searchCourses(query) {
    try {
      setIsFetching(true)
      const { data } = await Http.graphql(
        SEARCH_COURSES(query, sortBy, interest)
      ).finally(() => setIsFetching(false))
      if (data && data.courses) {
        setCourses(data.courses)
      }
      if (data && data.interests) {
        setInterests(data.interests)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault()
      setQuery(e.target.value)
    }
  }

  if (isFetching) {
    return <Loading />
  }

  console.log({ sortBy, query })

  return (
    <section className="container px-4 xs:px-0 xl:px-12 mx-auto pt-8 pb-24">
      {isEmpty(query) ? (
        <span className="text-2xl lg:text-4xl font-semibold mb-4 md:mb-8 inline-block">
          Se encontraron {courses.length} resultados
        </span>
      ) : (
        <span className="text-2xl lg:text-4xl font-semibold mb-4 md:mb-8 inline-block">
          Se encontraron {courses.length} resultados para "{query}"
        </span>
      )}

      <div className="flex flex-col-reverse md:flex-row justify-between mx-auto">
        <div className="flex flex-row items-center">
          <div className="flex flex-row w-1/2 items-center">
            <span className="hidden md:flex text-xl font-medium w-28">
              Ordenar por
            </span>
            <div className="rounded-full border-primary h-8 w-full md:w-42 px-2 border md:ml-2">
              <select
                className="w-full h-full bg-transparent outline-none text-primary px-2"
                onChange={e => {
                  setSortBy(e.target.value)
                }}
                value={sortBy}
              >
                <option value="popular">Populares</option>
                <option value="discount">Ofertas</option>
              </select>
            </div>
          </div>
          <div className="flex flex-row ml-2 md:ml-8 w-1/2">
            <span className="hidden md:flex text-xl font-medium">
              Categoría
            </span>
            <div className="rounded-full border-primary h-8 w-full md:w-42 px-2 border md:ml-2">
              <select
                onChange={e => {
                  setInterest(e.target.value)
                }}
                value={interest}
                className="w-full h-full bg-transparent outline-none text-primary px-2"
              >
                <option value={0}>Categorías</option>
                {interests.map(interest => (
                  <option value={interest.id}>{interest.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between border rounded-full h-10 w-full md:w-64 items-center px-4 mb-4 md:mb-0 bg-white">
          <input
            type="text"
            className="bg-white outline-none"
            placeholder="Buscar curso"
            onKeyPress={handleKeyDown}
          />
          <FontAwesomeIcon icon={faSearch} className="text-primary" />
        </div>
      </div>
      <div className="w-full md:hidden max-w-full mt-6">
        {courses.map(course => (
          <div className="mb-4">
            <MiniCourseCard course={course} key={course.id} />
          </div>
        ))}
      </div>
      <div className="hidden md:grid md:grid-columns-3 lg:grid-columns-4 grid-gap-4 mt-6 w-full max-w-full">
        {courses.map(course => (
          <CourseCard course={course} key={course.id} />
        ))}
      </div>
      {/* <div className="w-full flex flex-row items-center justify-center mt-10">
        <div className="flex items-center bg-white rounded-full px-8  py-2 text-primary text-xl shadow-second">
          <FontAwesomeIcon icon={faChevronCircleDown} className=" mr-4" />{" "}
          <span> Ver más resultados</span>
        </div>
      </div> */}
    </section>
  )
}

export default Search
