import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock, faPlay } from "@fortawesome/free-solid-svg-icons"
import truncate from "lodash/truncate"

const ContentShowCase = ({ content }) => {
  return (
    <div className="flex mb-2">
      <div className="md:flex-1 w-full bg-white md:bg-transparent">
        <details>
          <summary className="flex flex-row-reverse items-center justify-between text-lg py-2 font-medium rounded-xl bg-gray-35 text-black px-4">
            <div className="flex flex-row-reverse">
              <span>{content.name}</span>
              <span className="md:font-normal mr-2">{content.duration}</span>
            </div>
          </summary>

          <div className="md:bg-white md:border md:rounded-xl md:overflow-hidden md:mt-2">
            {content.videos.map((video, index) => {
              return (
                <div
                  className="flex flex-row h-10 px-4 items-center justify-between border-b"
                  key={index}
                >
                  <div className="flex flex-row justify-between items-center text-sm md:text-base">
                    <FontAwesomeIcon
                      icon={faPlay}
                      className="mr-4 text-xs md:mr-2"
                    />
                    <span>{truncate(video.title, 20)}</span>
                  </div>
                  <div className="flex items-center">
                    <span>{video.duration}</span>
                    <FontAwesomeIcon icon={faLock} className="ml-2 text-xs" />
                  </div>
                </div>
              )
            })}
          </div>
          {/* <div className="flex flex-row h-12 items-center justify-between">
          <div className="flex flex-row items-center">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-4" />
            <span>¿De qué trata la repostería?</span>
          </div>
          <div className="flex flex-row">
            <DownloadButton className="hidden lg:flex mr-16" />
            <span>15min</span>
            </div>
          </div> */}
        </details>
      </div>
    </div>
  )
}

export default ContentShowCase
