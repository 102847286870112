import React from "react"
import { BounceLoader } from "react-spinners"

const Loading = props => (
  <div className="w-full py-50 flex items-center justify-center">
    <BounceLoader size={100} color="#ef7625" />
  </div>
)

export default Loading
