import { useState, useEffect } from "react"
import Http from "@modules/Http"

function useGraphQL(query, value = null, initialState = {}) {
  const [data, setData] = useState(initialState)

  useEffect(
    () => {
      getGraphql()
    },
    [value] // Re-run if eventName or element changes
  )

  async function getGraphql() {
    const { data } = await Http.graphql(query)
    setData(data)
  }

  return data
}

export function useAsyncGraphQL(query, value = null) {
  const [data, setData] = useState({})
  const [isFetching, setIsFetching] = useState(true)

  useEffect(
    () => {
      getGraphql()
    },
    [value] // Re-run if eventName or element changes
  )

  async function getGraphql() {
    const { data } = await Http.graphql(query)
    if (data) {
      setData(data)
    }
    setIsFetching(false)
  }

  return [data, isFetching]
}

export default useGraphQL
