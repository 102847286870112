import { SET_CART, ADD_TO_CART, REMOVE_FROM_CART } from "../constants"
import initialState from "./initialState"

function cart(state = initialState.cart, action) {
  switch (action.type) {
    case SET_CART:
      return [...action.payload]
    case ADD_TO_CART:
      return [...state, action.payload]
    case REMOVE_FROM_CART:
      return state.filter(item => item.id !== action.payload)
    default:
      return state
  }
}

export default cart
