import React from "react"
import chunk from "lodash/chunk"
import Slider from "./../Slider"
import Button from "@share/button"
import ChunkSlider from "./../Home/ChunkSlider"
import CourseCard from "./../Home/CourseCard"
import isEmpty from "lodash/isEmpty"
const RecommendedCourses = ({ courses = [] }) => {
  const chunkedCourses = chunk(courses, 3)
  return (
    <section className="bg-yellow-10">
      <div className="mx-auto py-12 mx-auto">
        <div className="flex justify-between mx-auto items-center container px-4 xs:px-0 xl:px-12">
          <span className="text-xl md:text-2xl lg:text-4xl font-semibold md:px-0">
            Cursos recomendados para ti
          </span>
        </div>
        <div className="md:hidden px-6">
          <ChunkSlider chunkRecomendCourse={chunkedCourses} />
        </div>
        <div className="hidden md:block container mx-auto lg:px-12">
          {!isEmpty(courses) && (
            <Slider slidesPerView={4}>
              {courses.map(course => (
                <div key={course.id}>
                  <CourseCard course={course} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </section>
  )
}
export default RecommendedCourses
