import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Http from "@modules/Http"
import { BeatLoader } from "react-spinners"
import has from "lodash/has"
import { useToasts } from "react-toast-notifications"
import ErrorLabel from "@share/ErrorLabel"

const INITIAL_DATA = {
  email: "",
  current_password: "",
  password: "",
  password_confirmation: "",
}

const Account = () => {
  const user = useSelector(state => state.user)
  const [data, setData] = useState(INITIAL_DATA)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const { addToast } = useToasts()

  useEffect(() => {
    setData({
      email: user.email,
    })
  }, [user])

  async function updateAccount() {
    try {
      setIsLoading(true)
      const requestData = {
        ...data,
        names: user.names,
        surnames: user.surnames,
      }
      await Http.patch("/user", requestData)
      await Http.patch("/user/password", requestData)
      resetState()
      addToast("Saved Successfully", {
        appearance: "success",
        autoDismiss: true,
      })
    } catch (errors) {
      setIsLoading(false)
      if (has(errors, "response.data.errors")) {
        setErrors(errors.response.data.errors)
      }
    }
  }

  function resetState() {
    setIsLoading(false)
    setErrors({})
    setData({
      ...INITIAL_DATA,
      email: user.email,
    })
  }

  function handleChange(event) {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <div className="w-full mx-auto">
      <span className="text-4xl font-semibold">Cuenta</span>
      <div className="flex flex-col mt-8">
        <span className="text-xl mb-2">Correo electrónico</span>
        <input
          type="text"
          placeholder="email@example.com"
          value={data.email}
          name="email"
          onChange={handleChange}
          className="rounded-xl w-full h-12 p-4 border outline-none rounded-xl shadow-mini"
        />
        <ErrorLabel className="mt-2" errors={errors} name="email" />
      </div>
      <div className="flex flex-col mt-8 mb-8">
        <span className="text-xl mb-2 font-semibold">Cambiar contraseña</span>
        <div className="bg-orange-200 rounded-xl w-full flex flex-col items-center justify-center p-8 mb-4">
          <div className="flex flex-col w-full">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col w-full">
                <span className="md:text-xl mb-2">Contraseña actual</span>
                <input
                  type="password"
                  name="current_password"
                  value={data.current_password}
                  onChange={handleChange}
                  className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini"
                />
                <ErrorLabel
                  className="mt-2"
                  errors={errors}
                  name="current_password"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-4 lg:mt-6 w-full">
            <div className="grid grid-columns-1 md:grid-columns-2 gid-gap-6 md:grid-gap-8">
              <div className="flex flex-col mb-4 md:mb-0">
                <span className="md:text-xl mb-2">Nueva contraseña</span>
                <input
                  type="password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                  className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini"
                />
                <ErrorLabel className="mt-2" errors={errors} name="password" />
              </div>
              <div className="flex flex-col">
                <span className="md:text-xl mb-2">Confirmar contraseña</span>
                <input
                  type="password"
                  name="password_confirmation"
                  onChange={handleChange}
                  value={data.password_confirmation}
                  className="rounded-xl h-12 px-4 border outline-none rounded-xl shadow-mini"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex">
          {isLoading ? (
            <button className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase">
              <BeatLoader color="white" />
            </button>
          ) : (
            <button
              onClick={updateAccount}
              className="btn btn-primary flex-1 md:w-64 h-12 md:flex-none uppercase"
            >
              Guardar cambios
            </button>
          )}
          {/* <button className="md:text-xl text-primary ml-8">Cancelar</button> */}
        </div>
      </div>
    </div>
  )
}

export default Account
