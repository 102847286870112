import React from "react"
import Guarantee from "../../images/icon-guarantee.svg"
import Diploma from "../../images/icon-diploma.svg"
import Rocket from "../../images/icon-rocket.svg"
import Team from "../../images/icon-team.svg"
const Hack = () => (
  <div className="flex flex-col justify-between grid md:grid-columns-1 lg:grid-columns-2 grid-gap-8 grid-reverse-2">
    <div className="flex flex-row shadow-second items-center rounded-semi h-auto md:h-48 relative hack">
      <div className="rounded-full flex justify-center items-center p-6 w-32 md:w-48 h-full bg-gradient z-10 relative shadow-custom">
        <img
          src={Guarantee}
          alt=""
          className="w-auto h-20 md:h-28 object-contain block"
        />
      </div>
      <div className="flex flex-row flex-1 p-4">
        <div className="flex flex-col">
          <span className="md:text-xl lg:text-4xl font-semibold">
            Tutores Reconocidos
          </span>
          <p className="md:text-base lg:text-2xl w-full">
            Fórmate con los mejores profesionales en su área de experticia.
          </p>
        </div>
      </div>
    </div>
    <div className="flex flex-row shadow-second items-center rounded-semi h-auto md:h-48 relative hack">
      <div className="rounded-full flex justify-center items-center p-6 w-32 md:w-48 h-full bg-gradient z-10 relative shadow-custom">
        <img
          src={Diploma}
          alt=""
          className="w-auto h-20 md:h-28 object-contain block"
        />
      </div>
      <div className="flex flex-row flex-1 p-4">
        <div className="flex flex-col">
          <span className="md:text-xl lg:text-4xl font-semibold">
            Certificado Oficial
          </span>
          <p className="md:text-base lg:text-2xl w-full">
            Consigue tu certificado firmado por el tutor y avalado por Comuni.
          </p>
        </div>
      </div>
    </div>
    <div className="flex flex-row shadow-second items-center rounded-semi h-auto md:h-48 relative hack">
      <div className="rounded-full flex justify-center items-center p-6 w-32 md:w-48 h-full bg-gradient z-10 relative shadow-custom">
        <img src={Rocket} alt="" className="w-full h-full" />
      </div>
      <div className="flex flex-row flex-1 p-4">
        <div className="flex flex-col">
          <span className="md:text-xl lg:text-4xl font-semibold">
            Emprendimiento
          </span>
          <p className="md:text-base lg:text-2xl w-full">
            Con nuestro cursos emprende negocios flexibles
          </p>
        </div>
      </div>
    </div>
    <div className="flex flex-row shadow-second items-center rounded-semi h-auto md:h-48 relative hack">
      <div className="rounded-full flex justify-center items-center p-6 w-32 md:w-48 h-full bg-gradient z-10 relative shadow-custom">
        <img
          src={Team}
          alt=""
          className="w-auto h-20 md:h-28 object-contain block"
        />
      </div>
      <div className="flex flex-row flex-1 p-4">
        <div className="flex flex-col">
          <span className="md:text-xl lg:text-4xl font-semibold">
            Acompañamiento
          </span>
          <p className="md:text-base lg:text-2xl w-full">
            Acceso a foros de consultas y apoyo de los tutores en todo el
            proceso.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Hack
