import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import take from "lodash/take"
import StarRatings from "react-star-ratings"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { addToCart } from "@actions/cart"
const Card = ({ course }) => {
  const students = course.students ? take(course.students, 4) : []
  const session = useSelector(state => state.session)
  const dispatch = useDispatch()
  function getGoToCourse() {
    navigate(`/cursos/${course.id}`)
  }
  function addCourseToCart() {
    if (!session) {
      //
    }
    dispatch(addToCart(course))
  }
  return (
    <div
      className="rounded-xl shadow-lg w-full mx-auto relative flex flex-col overflow-hidden bg-white"
      onClick={getGoToCourse}
    >
      <div className="bg-black relative w-full h-42">
        <img
          src={course.image}
          alt="algo"
          className="h-full absolute top-0 left-0 w-full object-cover"
        />
      </div>
      {course.discount && (
        <div className="absolute rounded-r-full bg-primaryPink w-24 text-center top-12 font-bold px-1">
          <span className="text-primaryOrange text-base md:text-lg">%</span>
          <span className="text-white text-base md:text-lg"> OFERTA</span>
        </div>
      )}
      <div className="flex flex-col p-4 text-lg flex-1">
        <div className="hidden md:flex flex-row justify-between w-full items-center">
          <div className="flex flex-row w-3/4 items-center">
            <div className="flex flex-row items-center w-16 relative h-7">
              {students
                ? students.map((student, i) => {
                    return (
                      <img
                        src={student.profile.image}
                        alt=""
                        key={i}
                        className="rounded-full w-7 h-7 bg-primary absolute top-0"
                        style={{ left: `${i !== 0 ? i * 15 : 0}%` }}
                      />
                    )
                  })
                : []}
            </div>
            <span className="text-sm">+{course.total_students} alumnos</span>
          </div>
          <div className="inline-flex items-center text-sm md:text-base">
            <FontAwesomeIcon icon={faClock} className="bg-white mr-2" />
            <span className="text-sm">3h 25 m</span>
          </div>
        </div>
        <div className="flex md:flex-col flex-col-reverse md:mt-2">
          <div className="flex flex-col">
            <h2 className="font-sans text-2xl font-semibold truncate">
              {course.name}
            </h2>
            <span className="text-sm leading-tight">
              {` ${course.teacher.names} ${course.teacher.surnames}`}
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div className="md:text-xl">
              <StarRatings
                rating={course.average_score}
                starSpacing="15px"
                starRatedColor="rgb(245, 202, 35)"
                starDimension="20px"
                starSpacing="0px"
              />
              <span className="ml-4 text-lg font-light">
                ( {course.total_scores} reviews )
              </span>
            </div>
            <div className="flex flex-row items-center md:hidden md:text-base">
              <FontAwesomeIcon icon={faClock} className="bg-white mr-2" />
              <span>3h 25 m</span>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between items-end">
          <div>
            <button
              className="btn btn-primary btn--rounded btn--tiny text-xl font-semibold"
              onClick={e => {
                e.stopPropagation()
                addCourseToCart()
              }}
            >
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="text-white mr-2"
              />
              Añadir
            </button>
          </div>
          {course.discount ? (
            <div className="flex flex-col text-right -mb-2">
              <span className="text-base line-through -mb-1 h-6">
                ${course.price}
              </span>
              <span className="text-lg md:text-2xl font-bold">
                ${course.discount}
              </span>
            </div>
          ) : (
            <div className="flex flex-col text-right">
              <span className="text-base line-through -mb-2 h-6" />
              <span className="text-lg md:text-2xl font-bold -mb-1">
                ${course.price}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Card
