import React, { useState, useEffect } from "react"
import CardTutor from "./CardTutor"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faUser,
  faStar,
  faPlayCircle,
  faExclamationCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons"
import { faCreditCard } from "@fortawesome/free-regular-svg-icons"
import { GET_HOME_TUTOR, GET_OWN_COURSE } from "@queries"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import Loading from "@share/Loading"
import Http from "@modules/Http"
import Select from "react-select"

const cardStyle =
  "border md:h-48 h-32 md:w-40 rounded-lg lg:w-full bg-white flex flex-col items-center justify-center shadow-mini px-2 lg:px-6"

const Tutor = props => {
  const options = [
    { value: "popular", label: "Popular" },
    { value: "discount", label: "Descuento" },
  ]

  const initialUser = {
    courses: [],
  }
  const { id } = useSelector(state => state.user)
  const [data, isFetching] = useAsyncGraphQL(GET_HOME_TUTOR(id), id)
  const { user = initialUser } = data

  const [query, setQuery] = useState("")
  const [sortBy, setSortBy] = useState("popular")
  const [ownCourse, setCourses] = useState([])
  const [fetching, setIsFetching] = useState(true)
  useEffect(() => {
    if (id) {
      getOwnCourse()
    }
  }, [id, sortBy, query])

  async function getOwnCourse() {
    try {
      setIsFetching(true)
      const { data } = await Http.graphql(
        GET_OWN_COURSE(id, sortBy, query)
      ).finally(() => setIsFetching(false))

      if (data) {
        setCourses(data.courses)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault()
      setQuery(e.target.value)
    }
  }

  if (isFetching) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loading />
      </div>
    )
  }
  console.log("ownCourse", ownCourse)

  return (
    <div className="text-chocolate">
      <section className="bg-pink-10">
        <div className="w-full flex flex-col justify-between mx-auto py-8 md:py-16 px-4 xs:px-0 xl:px-12 container">
          <span className="text-xl md:text-4xl font-semibold">
            {user.full_name}, estas son las estadísticas de tus cursos
          </span>
          <div className="flex flex-row md:flex-col items-center lg:items-start mt-6">
            <div className="grid grid-columns-2 md:grid-columns-4 lg:grid-columns-5 md:grid-gap-8 grid-gap-4 lg:mx-auto w-full">
              <div className={cardStyle}>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  className="text-primary text-xl md:text-4xl lg:mb-4"
                />
                <span className="text-xl md:text-2xl font-medium">
                  {user.total_revenue || "$0.00"}
                </span>
                <span className="text-base md:text-lg text-center leading-tight md:h-10 lg:w-full w-1/2">
                  Ingresos <br /> totales
                </span>
              </div>
              <div className={cardStyle}>
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-primary text-xl md:text-4xl lg:mb-4"
                />
                <span className="text-xl md:text-2xl font-medium">
                  {user.total_students || "0"}
                </span>
                <span className="text-base md:text-lg text-center leading-tight">
                  Estudiantes <br /> totales
                </span>
              </div>
              <div className={cardStyle}>
                <FontAwesomeIcon
                  icon={faStar}
                  className="text-primary text-xl md:text-4xl lg:mb-4"
                />
                <span className="text-xl md:text-2xl font-medium">
                  {user.average_score || "0"}
                </span>
                <span className="text-base md:text-lg text-center leading-tight">
                  Valoraciones promedio
                </span>
              </div>
              <div className={cardStyle}>
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  className="text-primary text-xl md:text-4xl lg:mb-4"
                />
                <span className="text-xl md:text-2xl font-medium">
                  {user.available_courses || "0"}
                </span>
                <span className="text-base md:text-lg text-center leading-tight">
                  Cursos disponibles en Comuni
                </span>
              </div>
              <div className={cardStyle}>
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  className="text-primary text-xl md:text-4xl lg:mb-4"
                />
                <span className="text-xl md:text-2xl font-medium">
                  {user.unanswered_questions || "0"}
                </span>
                <span className="text-base md:text-lg text-center leading-tight">
                  Preguntas sin responder
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container px-4 xs:px-0 xl:px-12 w-full mx-auto mt-12">
        <div className="flex flex-col-reverse md:flex-row justify-between mx-auto">
          <div className="flex flex-row ">
            <span className="text-xl font-medium hidden md:flex ">
              Ordenar por
            </span>
            <div className="rounded-full border-primary h-8 w-full md:w-42 px-2 border md:ml-2">
              <select
                className="w-full h-full bg-transparent outline-none text-primary px-2"
                onChange={e => {
                  setSortBy(e.target.value)
                }}
                value={sortBy}
              >
                <option value="popular">Populares</option>
                <option value="discount">Ofertas</option>
              </select>
            </div>
          </div>
          <div className="flex flex-row justify-between border rounded-full h-10 w-full md:w-64 items-center px-4 mb-4 md:mb-0 bg-white">
            <input
              type="text"
              className="bg-transparent outline-none"
              placeholder="Buscar curso"
              onKeyPress={handleKeyDown}
            />
            <FontAwesomeIcon icon={faSearch} className="text-primary" />
          </div>
        </div>
        <div className="flex flex-col justify-between my-6">
          {ownCourse &&
            ownCourse.map((course, key) => {
              return <CardTutor course={course} key={key} />
            })}
        </div>
        {/* <div className="w-full flex flex-row items-center justify-center mt-10">
 <div className="flex items-center bg-white rounded-full px-8 py-2 text-primary text-xl shadow-second">
 <FontAwesomeIcon icon={faChevronCircleDown} className=" mr-4" />
 <span> Ver más resultados</span>
 </div>
 </div> */}
      </section>
    </div>
  )
}
export default Tutor
