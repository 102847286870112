import React from "react"
import { useAsyncGraphQL } from "@hooks/useGraphQL"
import { RECOMMENDED_COURSES } from "@queries"
import { useSelector, useDispatch } from "react-redux"
import isEmpty from "lodash/isEmpty"
import sum from "lodash/sum"
import Http from "@modules/Http"
import { setCart } from "@actions/cart"
import Loading from "@share/Loading"
import RecommendedCourses from "./RecommendedCourses"
import PayCard from "./PayCard"
import CourseItem from "./CourseItem"
const ShoppingCart = props => {
  const { id } = useSelector(state => state.user)
  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()
  const [data, isFetching] = useAsyncGraphQL(RECOMMENDED_COURSES(id), id)
  if (isFetching) {
    return <Loading />
  }
  const courses = data.courses
  const subtotal = sum(
    cart.map(course => {
      const price = course.price
      return parseFloat(price)
    })
  )
  const discount = sum(
    cart.map(course => {
      const price = course.discount > 0 ? course.price - course.discount : 0
      return parseFloat(price)
    })
  )
  const tax = ((subtotal - discount) * 12) / 100
  const total = subtotal - discount + tax
  async function deleteCart() {
    await Http.delete("/cart")
    dispatch(setCart([]))
  }
  return (
    <div>
      <section className="pt-6 container px-4 xs:px-0 xl:px-12 flex flex-col md:flex-row">
        {isEmpty(cart) ? (
          <div className="flex flex-col w-full">
            <span className="text-2xl md:text-4xl font-semibold mb-8">
              Tu carrito está vacío :( ¿Qué tal si exploramos algunos cursos?
            </span>
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <span className="text-2xl md:text-4xl font-semibold mb-4">
              Tu Carrito de Compras
            </span>
            <div className="md:hidden mb-6">
              <PayCard
                total={total.toFixed(2)}
                subtotal={subtotal.toFixed(2)}
                discount={discount.toFixed(2)}
                tax={tax.toFixed(2)}
              />
            </div>
            <div className="flex flex-col md:flex-row justify-between w-full mb-16">
              <div className="md:w-1/2 mb-6">
                <div className="flex flex-row justify-between items-center leading-loose mb-2">
                  <span className="text-xl md:text-2xl font-medium">
                    {cart.length} cursos en el carrito
                  </span>
                  <button
                    onClick={deleteCart}
                    className="text-primary text-xl hidden md:block"
                  >
                    Vaciar carrito
                  </button>
                </div>
                {!isEmpty(cart) &&
                  cart.map(course => <CourseItem course={course} />)}
              </div>
              <div className="hidden md:block w-full max-w-md">
                <PayCard
                  total={total.toFixed(2)}
                  subtotal={subtotal.toFixed(2)}
                  discount={discount.toFixed(2)}
                  tax={tax.toFixed(2)}
                />
              </div>
            </div>
          </div>
        )}
      </section>
      <RecommendedCourses courses={courses} />
    </div>
  )
}
export default ShoppingCart
