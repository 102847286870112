import PropTypes from "prop-types"
import React from "react"
const Button = ({ title, click, icon }) => (
  <button className="text-white w-full bg-primary rounded-xl text-sm lg:text-xl font-semibold py-2 lg:px-4">
    {icon && icon}
    <span>{title}</span>
  </button>
)

Button.propTypes = {
  siteTitle: PropTypes.string,
}

Button.defaultProps = {
  siteTitle: ``,
}

export default Button
