import { SET_USER, UPDATE_USER } from "../constants"
import initialState from "./initialState"

function user(state = initialState.user, action) {
  switch (action.type) {
    case SET_USER:
      return { ...action.payload }
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default user
